import { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { IDnaKitService } from "src/services/DnaKitService";
import { useInjection } from "src/services/ServiceProvider";
import { DnaKitOrderData } from "src/services/types/DnaKit";
import { InnerWorkflowStepProps } from "src/services/types/WorkflowTypes";
import {
  selectDnaKitCreatedOrder,
  selectDnaKitOrderRequesting,
} from "src/store/DnaKit/DnaKitSelectors";
import { Loading } from "../Loading";
import OrderDnaKitReceipt from "./OrderDnaKitReceipt";

type OrderDnaKitCheckStateProps = {
  dnaKitOrderRequesting: boolean;
  dnaKitCreatedOrder?: DnaKitOrderData;
};

// These are the props directly used when returning the component: <OrderDnaKitCheck prop1={} prop2={} />
export type OrderDnaKitCheckComponentProps = {
  onComponentComplete?: () => Promise<void>;
};

export type OrderDnaKitCheckProps = OrderDnaKitCheckComponentProps &
  InnerWorkflowStepProps &
  OrderDnaKitCheckStateProps;

function OrderDnaKitCheck({
  dnaKitOrderRequesting,
  dnaKitCreatedOrder,
  onComponentComplete = () => Promise.resolve(),
  ...innerWorkflowStepProps
}: OrderDnaKitCheckProps) {
  const dnaKitService = useInjection<IDnaKitService>("dnaKitService");

  // Check if user has active orders.
  useEffect(() => {
    if (!dnaKitOrderRequesting && dnaKitCreatedOrder === undefined) {
      dnaKitService.loadActiveDnaKitOrder();
    }
  }, [dnaKitService, dnaKitOrderRequesting, dnaKitCreatedOrder]);

  // User has no active orders.
  useEffect(() => {
    if (
      dnaKitCreatedOrder !== undefined &&
      dnaKitCreatedOrder.order_id.length === 0
    ) {
      innerWorkflowStepProps.onStepComplete();
    }
  }, [dnaKitCreatedOrder]);

  const userHasActiveOrder =
    dnaKitCreatedOrder !== undefined && dnaKitCreatedOrder.order_id.length > 0;

  return (
    <>
      {dnaKitCreatedOrder === undefined && <Loading />}
      {userHasActiveOrder && (
        // TODO: Fix types
        <OrderDnaKitReceipt
          {...innerWorkflowStepProps}
          // @ts-ignore
          onStepComplete={onComponentComplete}
          // @ts-ignore
          title="You Have An Existing Order"
        />
      )}
    </>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = createStructuredSelector<
  any,
  OrderDnaKitCheckStateProps
>({
  dnaKitOrderRequesting: selectDnaKitOrderRequesting,
  dnaKitCreatedOrder: selectDnaKitCreatedOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDnaKitCheck);
