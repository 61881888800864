import React, { useEffect } from "react";
import classNames from "classnames";
import { range } from "lodash";
import {
  useTranslation,
  withTranslation,
  WithTranslation,
} from "react-i18next";
import {
  GenericWorkflow,
  GenericWorkflowStep,
} from "src/services/types/WorkflowTypes";
import { WORKFLOW_VALUE } from "src/constants/workflow";
import utilsGA from "src/utils/utils_ga";

// <ComponentStepProgress />:

type ComponentStepProgressProps = {
  title: string;
  totalSteps: number;
  doneSteps: number;
  activeStepIndex?: number;
  compact?: boolean;
};

export function ComponentStepProgress({
  title,
  totalSteps,
  doneSteps,
  activeStepIndex,
  compact,
}: ComponentStepProgressProps) {
  const { t } = useTranslation();
  const subSteps = range(0, totalSteps).map((item) => ({
    active: activeStepIndex !== undefined && item === activeStepIndex,
    complete: item < doneSteps,
    "only-one": totalSteps === 1,
  }));

  return (
    <div
      className={classNames("signup-progress-bar-wrapper p-0", {
        "w-100": !compact,
        "w-25 flex-fill flex-md-grow-0": compact,
      })}
    >
      <div className="signup-progress-bar">
        <div className="signup-progress-mini-wrapper">
          {subSteps.map((item, key) => (
            <span key={key} className={classNames(item)} />
          ))}
        </div>
      </div>
      <div className="signup-progress-label">{t(title)}</div>
    </div>
  );
}

// Progress calculation strategies:

type CalculationStrategyParams = {
  workflow: GenericWorkflow;
  groupedWorkflow: Record<string, GenericWorkflowStep[]>;
};

type ProgressCalculationStrategy = (
  params: CalculationStrategyParams,
) => ComponentStepProgressProps[];

/**
 * This strategy assumes the given currentStep is the active one and that all previous steps have been completed.
 *
 * @param currentStep The current step in the workflow.
 * @returns A function to calculate all the "progress capsule" props for a given workflow.
 */
function sequentialProgressStrategy(
  currentStep?: GenericWorkflowStep,
): ProgressCalculationStrategy {
  return ({
    workflow,
    groupedWorkflow,
  }: CalculationStrategyParams): ComponentStepProgressProps[] => {
    if (!currentStep) {
      console.warn(
        "The currentStep param is require for sequential progress calculation.",
      );
      return Object.entries(groupedWorkflow).map(([step, workflowSteps]) => ({
        totalSteps: workflowSteps.length,
        title: step,
        doneSteps: 0,
      }));
    }

    const activeIndex = workflow.findIndex(({ id }) => id === currentStep.id);
    let topRangeForStep = 0;

    return Object.entries(groupedWorkflow).map(([title, workflowSteps]) => {
      const totalSteps = workflowSteps.length;
      const bottomRangeForStep = topRangeForStep;
      topRangeForStep += totalSteps;

      const isActiveIndexWithinCurrentItem =
        activeIndex >= bottomRangeForStep && activeIndex < topRangeForStep;
      const isActiveIndexAfterCurrentItem = activeIndex >= topRangeForStep;
      const indexOutOfStepRangeValue = isActiveIndexAfterCurrentItem
        ? totalSteps
        : 0;

      const doneSteps = isActiveIndexWithinCurrentItem
        ? activeIndex - bottomRangeForStep
        : indexOutOfStepRangeValue;
      const activeStepIndex = isActiveIndexWithinCurrentItem
        ? doneSteps
        : undefined;

      return {
        title,
        totalSteps,
        doneSteps,
        activeStepIndex,
      };
    });
  };
}

/**
 * @deprecated This strategy is considered deprecated and will be replaced by another once we have a better mechanism to determine completeness
 * of non-sequential workflows, or stop being deprecated if the current mechanism results being the right one.
 *
 * This strategy assumes that the given step is active and that any other is completed if they have a "value" attribute and its value is "complete".
 *
 * @param currentStep The current step in the workflow.
 * @returns A function to calculate all the "progress capsule" props for a given workflow.
 */
function nonSequentialLegacyStrategy(
  currentStep?: GenericWorkflowStep,
): ProgressCalculationStrategy {
  return ({ groupedWorkflow }: CalculationStrategyParams) =>
    Object.entries(groupedWorkflow).map(([title, workflowSteps]) => {
      const doneSteps = workflowSteps.reduce(
        (accumulate, { value }) =>
          accumulate + (value === WORKFLOW_VALUE.COMPLETE ? 1 : 0),
        0,
      );
      const totalSteps = workflowSteps.length;
      const activeIndex = currentStep
        ? workflowSteps.findIndex((step) => step.id === currentStep.id)
        : undefined;
      const activeStepIndex =
        activeIndex !== undefined && activeIndex >= 0 ? activeIndex : undefined;

      return {
        title,
        doneSteps,
        totalSteps,
        activeStepIndex,
      };
    });
}

function calculateComponentProgressItems(
  workflow: GenericWorkflow,
  calculateProgress: ProgressCalculationStrategy,
): ComponentStepProgressProps[] {
  const groupedWorkflow = workflow.reduce(
    (accumulate: Record<string, GenericWorkflowStep[]>, workflowStep) => {
      const { step, subworkflow_step } = workflowStep;
      return {
        ...accumulate,
        [step || subworkflow_step || ""]: [
          ...(accumulate[step] || []),
          workflowStep,
        ],
      };
    },
    {},
  );

  return calculateProgress({ workflow, groupedWorkflow });
}

// <Progress />

export type ProgressProps = {
  flow: GenericWorkflow;
  currentStep?: GenericWorkflowStep;
  progressCalculationStrategy?: "sequential" | "legacy-non-sequential";
  workflowName?: string;
};

export const Progress = (props: ProgressProps & WithTranslation) => {
  const {
    flow = [],
    currentStep,
    progressCalculationStrategy = "sequential",
    workflowName = "",
  } = props;

  if (!flow) {
    return null;
  }

  utilsGA.sendWorkflowStepToGA(workflowName, currentStep);

  const calculateProgress =
    progressCalculationStrategy === "legacy-non-sequential"
      ? nonSequentialLegacyStrategy(currentStep)
      : sequentialProgressStrategy(currentStep);

  const progressData = calculateComponentProgressItems(flow, calculateProgress);

  return (
    <section className="text-center signup-progress pb-4">
      <div className="mt-3 p-0 signup-progress-wrapper">
        {progressData.map((props, key) => (
          <ComponentStepProgress key={key} {...props} />
        ))}
      </div>
    </section>
  );
};

export default withTranslation()(Progress);
