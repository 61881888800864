import { useTranslation } from "react-i18next";
import PAGES from "src/constants/pages";
export default function WebPageNav({ webPageType }) {
  const { t } = useTranslation();
  return (
    <div className="web-page-nav">
      <div className="container">
        <nav className="webpage-breadcrumbs-container">
          <ul typeof="BreadcrumList" vocab="https://schema.org/">
            <li property="itemListElement" typeof="ListItem">
              <a
                className="webpage-breadcrumb"
                property="item"
                typeof="WebPage"
                href={PAGES.HOME_PAGE}
              >
                {t("Home")}
              </a>
            </li>
            <li property="itemListElement" typeof="ListItem">
              <a
                className="webpage-breadcrumb"
                property="item"
                typeof="WebPage"
              >
                {t(`${webPageType}_Title`)}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
