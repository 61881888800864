import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import PAGES from "src/constants/pages";
import WorkflowLayout from "./workflow_layout";
import { update_workflow, workflow_get } from "../../actions/workflowAction";
import { utils_registration_flow } from "../../utils/utils_registration_flow";
import { utils_workflow } from "../../utils/utils_workflow";
import { get_user_attributes_get } from "../../actions/userAction";
import { utils } from "../../utils/utils_general";
import Spinner from "../global/spinner";
import { WORKFLOW_TYPE, WORKFLOW_VALUE } from "../../constants/workflow";
import EnrollmentProgressCards from "./enrollment_progress_cards";

class WorkflowThankyou extends React.Component {
  constructor() {
    super();
    this.state = {
      step: null,
      workflow: null,
      id: null,
      dbAttr: null,
    };
  }

  componentDidMount() {
    const id = utils_workflow.getIdThroughUrl(this.props.location.pathname);
    if (!id) {
      this.props.history.push(PAGES.ENROLLMENT);
    }
    if (this.props.workflow && !utils.is_obj_empty(this.props.workflow)) {
      this.setBody(this.props.workflow);
    } else {
      const workflow = utils_workflow.getWorkflowFromStorage(
        this.props.update_workflow,
      );
      if (!workflow) {
        this.props.workflow_get();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.workflow &&
      !utils.check_objects_identical(this.props.workflow, prevProps.workflow)
    ) {
      this.setBody(this.props.workflow);
    }
  }

  setBody(flow) {
    if (!flow || flow.length === 0) {
      return null;
    }
    const id = utils_workflow.getIdThroughUrl(this.props.location.pathname);
    const stepIndex = flow ? flow.findIndex((c) => c.id === id) : null;
    const step = flow[stepIndex];
    this.setState({ step, id });

    this.props.get_user_attributes_get().then((res) => {
      const attr_errors = utils_registration_flow.checkAttrCompleted(
        this.state.step.exit_attribute,
        res,
      );
      if (attr_errors.length > 0) {
        console.log("attr_errors", attr_errors);
      } else {
        const newFlow = flow.map((c) => {
          if (c.id === step.id) {
            c.value = WORKFLOW_VALUE.COMPLETE;
          }

          // unlock next step progress card
          if (c.entry_attribute[0] !== "*") {
            const attr_errors = utils_registration_flow.checkAttrCompleted(
              c.entry_attribute,
              res,
            );
            if (attr_errors.length > 0) {
              c.locked = true;
            } else {
              c.locked = false;
            }
          }
          return c;
        });
        this.props.update_workflow(newFlow);
        this.setState({ workflow: newFlow, dbAttr: res });
      }
    });
  }

  goToNext() {
    utils_workflow.goToNextWorkflowStep(
      this.props.workflow,
      this.state.step,
      this.props.update_workflow,
      this.props.history.push,
      WORKFLOW_TYPE.ENROLLMENT,
    );
  }

  renderThankYouCard(step, nextStep) {
    const isEnrollmentComplete =
      nextStep === WORKFLOW_VALUE.COMPLETE || !nextStep;

    return (
      <div className="workflow-section workflow-banner light p-4 rounded mb-5">
        <div className="workflow-banner-flex-wrapper">
          <div className="workflow-banner-right">
            <div className="workflow-banner-icon">
              <div className="checkmark" />
            </div>
          </div>
          <div className="workflow-banner-left">
            <div className="workflow-banner-content mb-5 ">
              <p className="mb-3">{this.props.t("Thank you!")}</p>
              <h2>
                {this.props.t("You Just Completed {{step}}", {
                  step: step.step,
                })}
                ,{" "}
                {!isEnrollmentComplete ? (
                  <p>{this.props.t("Let's Move on to the Next Step...")}</p>
                ) : (
                  <p>{this.props.t("ready to complete enrollment?")}</p>
                )}
              </h2>
            </div>
            <div className="workflow-banner-button">
              {isEnrollmentComplete ? (
                <Button
                  onClick={() => this.props.history.push(PAGES.ENROLLMENT)}
                >
                  {this.props.t("Complete Enrollment")}
                </Button>
              ) : (
                <Button onClick={() => this.goToNext()}>
                  {this.props.t(`workflow:${nextStep.step}`)}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    if (!this.state.step) {
      return <Spinner error="workflow thank you loading" />;
    }
    const stepIndex = this.props.workflow
      ? this.props.workflow.findIndex((c) => c.id === this.state.step.id)
      : null;
    const nextStep = utils
      .sortForward(this.props.workflow, stepIndex)
      .find((c) => !c.value);
    return (
      <div>
        {this.renderThankYouCard(this.state.step, nextStep)}
        {this.state.step.details &&
        this.state.step.details.show_progresscards_on_thankyou ? (
          <EnrollmentProgressCards />
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <WorkflowLayout
        workflow={this.props.workflow}
        aside={this.state.step}
        mainSection={this.renderContent()}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
  workflow: state.workflow.workflow,
  workflowIsLoading: state.workflow.workflowIsLoading,
  ...ownProps,
});

export default withRouter(
  connect(mapStateToProps, {
    update_workflow,
    get_user_attributes_get,
    workflow_get,
  })(withTranslation("workflow")(WorkflowThankyou)),
);
