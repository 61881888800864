import { DnaKitOrderData } from "src/services/types/DnaKit";
import { OrderDnaKitInterface } from "src/services/types/validations/OrderDnaKit";
import types, { DnaKitAction, DnaKitDataTypes } from "./Types";

export type DnaKitReducerState = {
  dnaKitOrderPayload: OrderDnaKitInterface;
  dnaKitOrderRequesting: boolean;
  dnaKitOrderErrors: Error[];
  dnaKitCreatedOrder?: DnaKitOrderData;
};

const DEFAULT_STATE: DnaKitReducerState = {
  dnaKitOrderPayload: {
    phone: "",
    addressLine1: "",
    addressLine1Validated: false,
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
  },
  dnaKitOrderRequesting: false,
  dnaKitOrderErrors: [],
};

function getData<T extends DnaKitDataTypes>(action: DnaKitAction) {
  return action.data as T;
}

export default function dnaKitReducer(
  state: DnaKitReducerState = DEFAULT_STATE,
  action: DnaKitAction,
): DnaKitReducerState {
  const { type } = action;

  switch (type) {
    case types.SET_DNA_KIT_ORDER_PAYLOAD: {
      return {
        ...state,
        dnaKitOrderPayload: getData<OrderDnaKitInterface>(action),
      };
    }
    case types.SET_DNA_KIT_ORDER_REQUESTING: {
      return {
        ...state,
        dnaKitOrderRequesting: true,
      };
    }
    case types.SET_DNA_KIT_CREATED_ORDER: {
      return {
        ...state,
        dnaKitOrderRequesting: false,
        dnaKitCreatedOrder: getData<DnaKitOrderData>(action),
      };
    }
    case types.SET_DNA_KIT_ORDER_ERRORS: {
      return {
        ...state,
        dnaKitOrderRequesting: false,
        dnaKitOrderErrors: getData<Error[]>(action),
      };
    }
  }

  return state;
}
