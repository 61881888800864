import React from "react";
import { withTranslation, Trans } from "react-i18next";
import PAGES from "src/constants/pages";
import AncestryMessage from "./ancestry_message";

class AuthHeaderCustom extends React.Component {
  renderProfileHeader() {
    const name = this.props.user.first_name
      ? this.props.user.last_name
        ? `${this.props.user.first_name} ${this.props.user.last_name}`
        : this.props.user.first_name
      : "";
    return (
      <div className="container header-custom header-custom-profile">
        <div className="row">
          <div className="col-12 col-xl-2 left-section" />
          <div className="col-12 col-lg-9 col-xl-8 center-section name color-light">
            <h1 className="overflow-ellipsis">{name}</h1>
          </div>
          <div className="col-12 col-lg-3 col-xl-2 right-section" />
        </div>
      </div>
    );
  }

  renderDashboardHeader() {
    const { t } = this.props;
    return (
      <div className="container header-custom">
        <div className="header-hello">
          <div className="row">
            <div className="col-12 col-xl-2 left-section" />
            <h1 className="col-12 col-lg-9 col-xl-8 center-section name color-light h1 overflow-ellipsis">
              {" "}
              {t("Hello")}{" "}
              {this.props.user.first_name ? this.props.user.first_name : ""}{" "}
            </h1>
            <div className="col-12 col-lg-3 col-xl-2 right-section" />
          </div>
        </div>
      </div>
    );
  }

  renderSurveyHeader() {
    const { t } = this.props;
    return (
      <div className="container header-custom">
        <div className="surveys-page-message xl-tablet-mobile-only">
          <AncestryMessage />
        </div>
        <div className="row">
          <div className="col-12 col-xl-2 left-section" />
          <div className="col-12 col-lg-9 col-xl-8 center-section color-light">
            <h1>{t("Surveys")}</h1>

            <div className="custom-header-mobile">
              <Trans i18nKey="content:copy_page_description_surveys">
                <p>
                  It may seem like a small ask, but your honest responses to
                  these surveys can play a big part in advancing precision
                  medicine.{" "}
                </p>
                <br />
                <p>
                  You can take surveys multiple times and track your progress as
                  you go.
                </p>
              </Trans>
            </div>
          </div>
          <div className="col-12 col-lg-3 col-xl-2 right-section" />
        </div>
      </div>
    );
  }

  renderCustomHeader() {
    switch (this.props.location.pathname) {
      case PAGES.DASHBOARD:
        return this.renderDashboardHeader();
      case PAGES.SURVEYS:
        return this.renderSurveyHeader();
      case PAGES.PROFILE:
        return this.renderProfileHeader();
      default:
        return null;
    }
  }

  render() {
    return <>{this.renderCustomHeader()}</>;
  }
}

export default withTranslation("content")(AuthHeaderCustom);
