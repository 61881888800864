export const WEB_PAGE = {
  PRIVACY_POLICY: "Privacy_Policy",
  CONTACT: "Contact",
  ABOUT_US: "About_Us",
  FAQ: "FAQ",
  BLOG: "Blog",
  REFERRAL: "Referral",
  STUDY_TEAM: "Study_Team",
  STUDY_PARTNERSHIPS: "Study_Partnerships",
  SUPPORT: "Support",
  RECRUITMENT: "Recruitment",
  DIVERSITY: "Diversity",
  GENETICS_101: "Genetics-101",
  DATA_PROTECTION: "Data_Protection",
  TERMS_OF_SERVICE: "Terms_of_Service",
  RESEARCH_CONSENT: "Research_Consent",
  COOKIE_POLICY: "Cookie_Policy",
  FUNDING: "Funding",
  CORE_VALUES: "Core_Values",
  NEWS: "News",
  VOLUNTEER: "Volunteer",
  ANCESTRY_CONTENT: "Ancestry_Content",
  WITHDRAWAL: "Withdrawal",
  TERMS: "Terms",
};
