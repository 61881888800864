import Joi from "joi";
import "reflect-metadata";
import { injectable } from "inversify";
import {
  LoginValidationJoi,
  LOGIN_VALIDATION,
} from "../types/validations/Login";
import {
  SelfRegisterJoi,
  SELF_REGISTER_VALIDATION,
} from "../types/validations/SelfRegister";
import {
  VerificationValidationJoi,
  VERIFICATION_VALIDATION,
} from "../types/validations/Verification";
import {
  OrderDnaKitValidationJoi,
  ORDER_DNA_KIT_VALIDATION,
} from "../types/validations/OrderDnaKit";

export type ValidationRules = {
  LOGIN_VALIDATION: Joi.ObjectSchema<LoginValidationJoi>;
  SELF_REGISTER_VALIDATION: Joi.ObjectSchema<SelfRegisterJoi>;
  VERIFICATION_VALIDATION: Joi.ObjectSchema<VerificationValidationJoi>;
  ORDER_DNA_KIT_VALIDATION: Joi.ObjectSchema<OrderDnaKitValidationJoi>;
};

export const VALIDATION_RULES: ValidationRules = {
  LOGIN_VALIDATION,
  SELF_REGISTER_VALIDATION,
  VERIFICATION_VALIDATION,
  ORDER_DNA_KIT_VALIDATION,
};

@injectable()
class ValidationService {
  validateData<TInterfaceToValidate>(
    rulesName: keyof ValidationRules,
    data: TInterfaceToValidate,
  ) {
    const rules = VALIDATION_RULES[rulesName];
    if (rules) {
      const validationResult = rules.validate(data, { abortEarly: false });

      return validationResult;
    }
    return null;
  }
}

export default ValidationService;
