import { useState } from "react";
import { useTranslation, Trans, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import PAGES from "src/constants/pages";

import {
  login_magic_link_request_post,
  login_magic_link_validation_post,
} from "src/actions/loginAction";

import { LOGIN_EMAIL_VALIDATION } from "src/services/types/validations/Login";
import { utils } from "src/utils/utils_general";
// import OTPInput from 'src/components/core/otp-input';
import SimpleOTPInput from "src/components/core/simple-otp-input";
import { workflow_get } from "src/actions/workflowAction";
import { TOKEN_TYPE } from "src/constants/tokenType";
import { OTP_LOGIN_FLOWS } from "src/constants/otp";
import { LOGIN_ERROR_DISPLAY, UNEXPECTED_ERROR } from "src/constants/errors";
import { goToDestination } from "./utils";

const LoginMagicLink = () => {
  const location = useLocation();
  const defaultEmail = location.state?.email || "";
  const { t } = useTranslation();

  const isAppLoading = useSelector((state) => state.spinner);
  const [step, setStep] = useState("email"); // email, sign-in, error
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState(defaultEmail);

  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    if (!utils.is_obj_empty(errors)) {
      setErrors({});
    }
  };

  const dispatch = useDispatch();

  const submitMagicLinkRequest = async (email) => {
    // TODO: deeplink?
    const resultPromise = dispatch(login_magic_link_request_post(email));
    resultPromise
      .then((message) => {
        if (message === "magic link sent") {
          setStep("sign-in");
        } else {
          setErrors({ system: UNEXPECTED_ERROR });
        }
      })
      .catch(() => {
        setErrors({ system: UNEXPECTED_ERROR });
      });
  };

  const history = useHistory();
  const userAttribute = useSelector((state) => state.userAttribute);

  const submitMagicLinkOtp = async (email, otp) => {
    const resultPromise = dispatch(
      login_magic_link_validation_post(null, email, otp),
    );
    const goToApp = (user, flow) => {
      goToDestination(history, user, userAttribute, flow);
    };
    resultPromise
      .then((user) => {
        if (user.token_type === TOKEN_TYPE.MFA_OTP) {
          // User has 2FA enabled so we get a token with type
          // 'magiclink' that can only be used to do the 2FA flow
          // TODO: rename token to 2FA
          history.push({
            pathname: PAGES.MFA_OTP,
            state: { from: OTP_LOGIN_FLOWS.MAGIC_LINK },
          });
          return;
        }
        const valid_login_session = [
          TOKEN_TYPE.SESSION,
          TOKEN_TYPE.REGISTRATION_SELF,
          TOKEN_TYPE.REGISTRATION_SUBACCOUNT,
          TOKEN_TYPE.REGISTRATION_ADMIN,
          TOKEN_TYPE.ENROLLMENT,
        ];
        if (!valid_login_session.includes(user.token_type)) {
          setErrors({ system: LOGIN_ERROR_DISPLAY.SESSION_INVALID });
        } else if (new Date(user.expiry) < new Date()) {
          setErrors({ system: LOGIN_ERROR_DISPLAY.SESSION_EXPIRED });
        } else {
          goToApp(user);
        }
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.msg;
        if (errorMessage === "magiclink token has expired") {
          history.push(PAGES.MAGIC_LINK_ERROR, { error: errorMessage });
          return;
        }
        if (errorMessage === "magiclink already claimed or activated") {
          history.push(PAGES.MAGIC_LINK_ERROR, { error: errorMessage });
          return;
        }
        if (errorMessage === "invalid magiclink token") {
          setErrors({
            system: "The code you entered is incorrect. Please try again.",
          });
          return;
        }
        setErrors({ system: UNEXPECTED_ERROR });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});

    const { error } = LOGIN_EMAIL_VALIDATION.validate({ email });

    if (!error) {
      submitMagicLinkRequest(email);
      return;
    }

    const { details } = error;
    if (!details) {
      return;
    }
    const errorState = Object.create({});
    details.forEach((detail) => (errorState[detail.path[0]] = detail.message));
    setErrors(errorState);
  };

  const handleSubmitOtp = (e) => {
    e.preventDefault();
    setErrors({});

    const otp = otpDigits.join("");
    if (otp.length === 6) {
      submitMagicLinkOtp(email, otp);
    }
  };

  const handleOtpPaste = (value) => {
    setOtpDigits(String(value).split(""));
    setErrors({});
  };

  const handleOtpChange = (value) => {
    setOtpDigits(value);
    setErrors({});
  };

  const isSubmitEnabled = () => {
    if (step === "email") {
      return !isAppLoading && email !== "";
    }
    if (step === "sign-in") {
      const isOtpFilled = otpDigits.join("").length === 6;
      return !isAppLoading && isOtpFilled;
    }
  };

  return (
    <section>
      <div className="login unauth-body-wrapper">
        {step === "email" ? (
          <>
            <h1 className="mb-4">{t("texts:Sign In")}</h1>
            <div>
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <div className="pb-3">
                    <Form.Label>
                      {t("Email Address", { ns: "fields" })}
                    </Form.Label>
                  </div>
                  <Form.Control
                    // TODO: focus
                    onChange={handleChangeEmail}
                    name="email"
                    type="email"
                    aria-required="true"
                    defaultValue={defaultEmail}
                  />
                  <Form.Text
                    className="text-danger form-error"
                    aria-live="polite"
                  >
                    {errors.email ? t("Please provide a valid email.") : <br />}
                  </Form.Text>
                </Form.Group>

                <div className="text-right">
                  <Button
                    variant={isSubmitEnabled() ? "primary" : "disabled"}
                    type="submit"
                    block
                  >
                    {t("Sign In with a Magic Link")}
                  </Button>
                </div>

                <div className="text-center">
                  <Form.Text
                    className="text-danger form-error"
                    aria-live="polite"
                  >
                    {t(errors.system) || <br />}
                  </Form.Text>
                </div>

                <div className="text-right pb-4 pt-2 border-bottom">
                  <Link
                    to={{ pathname: PAGES.LOGIN_PASSWORD, state: { email } }}
                  >
                    {t("Sign in with a password instead")}
                  </Link>
                </div>

                <div className="text-right pt-4 pb-1">
                  <span className="pr-1">{t("Don’t have an account?")}</span>{" "}
                  <Link to={PAGES.SIGNUP}>{t("Sign Up")}</Link>
                </div>
              </Form>
            </div>
          </>
        ) : null}

        {step === "sign-in" ? (
          <>
            <div>
              <Trans i18nKey="magicLinkEmailSent">
                <h1>Check your email to sign in</h1>
                <p>
                  A message has been sent to <b>{{ email }}</b>. If this address
                  is registered with the application, you shall receive an email
                  with the link shortly
                </p>
                <p>
                  If you do not want to use the link, please enter the code to
                  continue.
                </p>
              </Trans>
            </div>

            <div>
              <Form noValidate onSubmit={handleSubmitOtp}>
                <div className="py-3">
                  <SimpleOTPInput
                    handlePaste={handleOtpPaste}
                    handleChange={handleOtpChange}
                    token={otpDigits}
                    inputType="number"
                  />
                </div>

                <div className="text-right py-2">
                  <Form.Text
                    className="text-danger form-error"
                    aria-live="polite"
                  >
                    {t(errors.system) || <br />}
                  </Form.Text>
                </div>

                <div className="text-right py-2">
                  <Button
                    variant={isSubmitEnabled() ? "primary" : "disabled"}
                    type="submit"
                  >
                    {t("Continue")}
                  </Button>
                </div>
              </Form>
              <div className="text-right py-4">
                <Link to={{ pathname: PAGES.LOGIN_PASSWORD, state: { email } }}>
                  {t("Sign in with a password instead")}
                </Link>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </section>
  );
};

export default withTranslation()(LoginMagicLink);
