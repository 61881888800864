import { useTranslation } from "react-i18next";

import { CONFIG } from "src/constants/config";
import { ReactComponent as TwitterLogo } from "./svg/twitter.svg";
import { ReactComponent as FacebookLogo } from "./svg/facebook.svg";
import { ReactComponent as InstagramLogo } from "./svg/instagram.svg";
import { ReactComponent as LinkedInLogo } from "./svg/linkedin.svg";
import { ReactComponent as EmailLogo } from "./svg/email.svg";
import { ReactComponent as SmsLogo } from "./svg/sms.svg";
import { utils } from "src/utils/utils_device";

const { REFERRAL_SOCIAL_MEDIA, REFERRAL_EMAIL_SMS } = CONFIG;

const ENCODED_REFERRAL_URL = encodeURIComponent(
  `${CONFIG.APP_BASE_URL}/referral`,
);

function LinkButton({ href, children }) {
  return (
    <a
      className="d-inline-block mr-2 mb-2"
      href={href}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </a>
  );
}

function FacebookButton() {
  // https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=https%3A%2F%2Frgcdtp-preprod-app.rgndtp.com
  const shareUrl = `https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=${ENCODED_REFERRAL_URL}`;
  return (
    <LinkButton href={shareUrl}>
      <FacebookLogo />
    </LinkButton>
  );
}

function InstagramButton() {
  // We don't have an easy way to share links in Instagram, just send
  // the user to the app.
  const shareUrl = "https://www.instagram.com";
  return (
    <LinkButton href={shareUrl}>
      <InstagramLogo />
    </LinkButton>
  );
}

function TwitterButton() {
  // TODO: text?
  // const shareUrl = `http://www.twitter.com/share?url=${ENCODED_REFERRAL_URL}&text=helloworld`;
  const shareUrl = `http://www.twitter.com/share?url=${ENCODED_REFERRAL_URL}`;
  return (
    <LinkButton href={shareUrl}>
      <TwitterLogo />
    </LinkButton>
  );
}

function LinkedInButton() {
  // https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fwww.google.com
  const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${ENCODED_REFERRAL_URL}`;
  return (
    <LinkButton href={shareUrl}>
      <LinkedInLogo />
    </LinkButton>
  );
}

function WhatsAppButton() {
  // https://faq.whatsapp.com/5913398998672934
  // https://wa.me/?text=I'm%20inquiring%20about%20the%20apartment%20listing
  // TODO: add text?
  const shareUrl = `https://wa.me/?text=${ENCODED_REFERRAL_URL}`;
  return (
    // TODO: logo
    <LinkButton href={shareUrl}>
      <LinkedInLogo />
    </LinkButton>
  );
}

function SocialMediaButtons() {
  const buttonComponents = {
    REFERRAL_SOCIAL_MEDIA_FACEBOOK: FacebookButton,
    REFERRAL_SOCIAL_MEDIA_INSTAGRAM: InstagramButton,
    REFERRAL_SOCIAL_MEDIA_TWITTER: TwitterButton,
    REFERRAL_SOCIAL_MEDIA_LINKEDIN: LinkedInButton,
    REFERRAL_SOCIAL_MEDIA_WHATSAPP: WhatsAppButton,
  };

  const activeButtonFlags = Object.keys(buttonComponents).filter(
    (flag) => CONFIG[flag],
  );

  // Show only first 4 buttons
  const flags = activeButtonFlags.slice(0, 4);

  return (
    <>
      {flags.map((flag) => {
        const Component = buttonComponents[flag];
        return <Component key={flag} />;
      })}
    </>
  );
}

function EmailSmsButton() {
  const { t } = useTranslation();
  // TODO: make texts configurable
  const subject = encodeURIComponent(t(`You've been Invited!`));
  const message = `${CONFIG.APP_BASE_URL}/referral`;
  const body = encodeURIComponent(message);
  const smsHref = utils.is_mobile_ios ? `sms:` : `sms:&body=${body}`;
  return (
    <>
      <a
        className="d-inline-block d-md-none mb-2"
        href={smsHref}
        rel="noopener noreferrer"
        target="_blank"
      >
        <SmsLogo />
      </a>
      <a
        className="d-none d-md-inline-block mb-2"
        href={`mailto:?subject=${subject}&body=${body}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <EmailLogo />
      </a>
    </>
  );
}

export function ReferralButtons() {
  return (
    <div>
      {REFERRAL_SOCIAL_MEDIA ? <SocialMediaButtons /> : null}
      {REFERRAL_EMAIL_SMS ? <EmailSmsButton /> : null}
    </div>
  );
}
