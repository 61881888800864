import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import { WORKFLOW, WORKFLOW_VALUE } from "src/constants/workflow";
import { LOCAL_STORAGE } from "src/constants/localStorage";
import {
  RESPONSE_CODE,
  UPDATE_SELF_ERROR_RESPONSES,
  FIELD_ERROR_DISPLAY,
} from "src/constants/errors";

import { self_reg_content_get } from "src/actions/selfRegistrationContentAction";
import { check_signup_post } from "src/actions/userAction";

import { utils } from "src/utils/utils_general";
import utilsGA, { eventEnum, formNames } from "src/utils/utils_ga";

import WorkflowNextButton from "src/components/workflow/WorkflowNextButton";
import { withService } from "src/services/ServiceProvider";
import ValidationService from "src/services/validation/ValidationService";

class SignUpForm extends React.Component {
  constructor({ t }) {
    super();
    this.t = t;
    this.state = {
      start_form: false,
      submitted: false,
      email: "",
      first_name: "",
      last_name: "",
      zip: "",
      phone: "",
      errors: {},
      requiredFields: null,
    };
  }

  componentDidMount() {
    this.checkIfTheresPreviousValues();
  }

  checkIfTheresPreviousValues() {
    if (utils.is_obj_empty(this.state.errors) && this.props.signupErrors) {
      this.setState({ errors: this.props.signupErrors });
    }

    if (this.props.prevValues) {
      Object.keys(this.props.prevValues).forEach((value) => {
        if (this.props.prevValues[value]) {
          this.setState({ [value]: this.props.prevValues[value] });
        }
      });
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {};
  }

  handleFocus(event) {
    if (!this.state.start_form) {
      utilsGA.sendFormStartToGA(formNames.signUp);
      this.setState({ start_form: true });
    }
    utilsGA.formFieldEvent(event, eventEnum.formFieldEnter);
  }

  handleBlur(event) {
    utilsGA.formFieldEvent(event, eventEnum.formFieldLeave);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (!utils.is_obj_empty(this.state.errors)) {
      this.setState({ errors: {} });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    utilsGA.sendFormSubmitToGA(formNames.signUp);
    const data = {};
    this.props.signupFields.forEach((item) => {
      if (item.name && this.state[item.name]) {
        data[item.name] = this.state[item.name];
      }
    });

    const errors = this.validateForm(data);
    if (!utils.is_obj_empty(errors)) {
      utilsGA.sendFormFieldsErrorsEventToGA(formNames.signUp, errors);
    }
    this.setState({ errors });

    if (!errors.email && errors.email !== "") {
      return this.props
        .check_signup_post(data.email)
        .then(() => {
          if (utils.is_obj_empty(errors)) {
            this.onFormComplete(data);
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.status === RESPONSE_CODE["400_bad_request"]
          ) {
            errors.email =
              error.response.message || UPDATE_SELF_ERROR_RESPONSES.BAD_REQUEST;
            this.setState({ errors });
          } else if (
            error &&
            error.response &&
            error.response.status === RESPONSE_CODE["405_data_invalid"]
          ) {
            errors.email = UPDATE_SELF_ERROR_RESPONSES.EMAIL_INVALID;
            this.setState({ errors });
          }
        });
    }
  }

  /**
   *
   * @param {*} data
   * @returns {Record<string, string>}
   */
  validateForm(data) {
    /**
     * @type {{validationService: ValidationService, signupFields: {name:string}[]}}
     */
    const { validationService, signupFields } = this.props;

    const validationErrors =
      validationService.validateData("SELF_REGISTER_VALIDATION", {
        ...data,
        StepFields: signupFields.map(({ name }) => name),
      })?.error?.details || [];

    const errorMessages = validationErrors.reduce(
      (accumulate, { path, message }) => ({
        ...accumulate,
        [path[0]]: message,
      }),
      {},
    );

    return errorMessages;
  }

  onFormComplete(data) {
    const flow =
      this.props.registrationFlow ||
      utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);
    if (flow) {
      flow.map((c) => {
        if (c.component === WORKFLOW.SET_EMAIL) {
          c.value = WORKFLOW_VALUE.COMPLETE;
        }
        return c;
      });
      this.props.onComplete(flow, data);
    }
  }

  renderField(field, key) {
    const { errors } = this.state;
    const { t } = this.props;

    return (
      <Form.Group key={key} controlId={`formBasic${field.name}`}>
        <Form.Label>{t(field.label)}</Form.Label>
        <Form.Control
          value={this.state ? this.state[field.name] : ""}
          onChange={(e) => this.handleChange(e)}
          name={field.name}
          type="text"
          aria-required="true"
          ref={this[field.name + field]}
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.handleBlur}
          autoFocus={key === 0}
        />
        <Form.Text className="text-danger form-error" aria-live="polite">
          {errors[field.name] ? (
            t(errors[field.name] || FIELD_ERROR_DISPLAY.err_field_required)
          ) : (
            <br />
          )}
        </Form.Text>
      </Form.Group>
    );
  }

  renderSignupForm(fields) {
    return (
      <Form
        className="ga4-track"
        data-name={formNames.signUp}
        noValidate
        onSubmit={(e) => this.handleSubmit(e)}
      >
        {fields.map((f, key) => this.renderField(f, key))}
        <br />
        <div className="unauth-body-footer">
          <WorkflowNextButton type="submit">
            {this.t("Continue")}
          </WorkflowNextButton>

          <div className="text-center">
            <Form.Text className="text-danger form-error" aria-live="polite">
              {this.t(this.state.errors.system) || <br />}
            </Form.Text>
          </div>
        </div>
      </Form>
    );
  }

  render() {
    const fields = this.props.signupFields;
    if (!fields) {
      return null;
    }
    return (
      <>
        {this.props.t(
          "Please enter your name and email address to get started",
        )}
        <br />
        <br />
        {this.renderSignupForm(fields)}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});
export default connect(mapStateToProps, {
  self_reg_content_get,
  check_signup_post,
})(withTranslation("fields")(withService(["validationService"])(SignUpForm)));
