import React from "react";

export const ProgressPlaceholder = () => (
  <section className="text-center signup-progress pb-4">
    <div className="mt-3 p-0 signup-progress-wrapper">
      {[1, 2, 3, 4, 5].map((c, key) => (
        <div key={key} className="signup-progress-bar-wrapper p-0">
          <div className="signup-progress-bar">
            <div className="signup-progress-mini-wrapper" />
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default ProgressPlaceholder;
