/* eslint-disable camelcase */
// expects a onComplete prop as this component will be used within workflow

import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EMRConnections from "src/components/workflow/emr_results";
import { get_emr_connection } from "src/actions/emrAction";
import EMRModal from "src/components/workflow/emr_modal";
import HtmlContentComponent from "../core/HtmlContent/HtmlContentComponent";

function EMRResultsProfile({
  hideConnect,
  emrConnections,
  loading,
  get_emr_connection: getEmrConnection,
  history,
}) {
  const [hidden, setHidden] = useState(true);
  const [modalShown, setModalShown] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    getEmrConnection();
  }, [getEmrConnection]);

  const openEhrModal = (item) => {
    setModalShown(true);
    setModalItem(item);
  };

  const handleConnectEHRClick = () => history.push("/emr/connect");

  return (
    <>
      <EMRModal
        show={modalShown}
        modalItem={modalItem}
        onCloseClick={() => setModalShown(false)}
      />
      <div className="section-component">
        <h2 className="mb-3 h3" id="profile-security">
          {t("Integrations")}
        </h2>
        <div className="accordion-wrapper">
          <Button
            className={`accordion-button ${!hidden ? "active" : ""}`}
            variant="primary-invert"
            block
            onClick={() => setHidden(!hidden)}
          >
            {t("EMR Connections")}
          </Button>
          <div
            className={`accordion-container pt-3 pb-3 pl-lg-3 pr-lg-3 ${!hidden ? "active" : ""}`}
          >
            <div className="mb-4">
              <HtmlContentComponent markup={t("emrProfileText")} />
              <div className="mt-2 text-right">
                {!hideConnect && (
                  <Button
                    variant="primary"
                    className="pr-5 pl-5"
                    onClick={handleConnectEHRClick}
                  >
                    {t("Connect an EHR")}
                  </Button>
                )}
              </div>
            </div>
            {emrConnections.length > 0 && (
              <EMRConnections
                hideConnect={hideConnect}
                onConnectClick={openEhrModal}
                loading={loading}
                emrConnections={emrConnections}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  emrConnections: state.emrConnections.data,
  loading: state.emrConnections.loading,
  featureFlag: state.featureFlag,
  ...ownProps,
});

export default connect(mapStateToProps, { get_emr_connection })(
  withRouter(EMRResultsProfile),
);
