import React from "react";
import { ReactComponent as Icon01 } from "../../assets/icons/dynamic/icon01.svg";
import { ReactComponent as Icon02 } from "../../assets/icons/dynamic/icon02.svg";
import { ReactComponent as Icon03 } from "../../assets/icons/dynamic/icon03.svg";
import { ReactComponent as Icon04 } from "../../assets/icons/dynamic/icon04.svg";
import { ReactComponent as Icon05 } from "../../assets/icons/dynamic/icon05.svg";
import { ReactComponent as Icon06 } from "../../assets/icons/dynamic/icon06.svg";
import { ReactComponent as Icon07 } from "../../assets/icons/dynamic/icon07.svg";
import { ReactComponent as Icon08 } from "../../assets/icons/dynamic/icon08.svg";
import { ReactComponent as Icon09 } from "../../assets/icons/dynamic/icon09.svg";
import { ReactComponent as Icon10 } from "../../assets/icons/dynamic/icon10.svg";
import { ReactComponent as Icon11 } from "../../assets/icons/dynamic/icon11.svg";
import { ReactComponent as Icon12 } from "../../assets/icons/dynamic/icon12.svg";
import { ReactComponent as Icon13 } from "../../assets/icons/dynamic/icon13.svg";
import { ReactComponent as Icon14 } from "../../assets/icons/dynamic/icon14.svg";

const iconMap = {
  Icon01,
  Icon02,
  Icon03,
  Icon04,
  Icon05,
  Icon06,
  Icon07,
  Icon08,
  Icon09,
  Icon10,
  Icon11,
  Icon12,
  Icon13,
  Icon14,
};

const iconsCount = Object.keys(iconMap).length;
const colorsCount = 9;

class IconGenerator extends React.Component {
  constructor() {
    super();
    this.state = {
      iconKey: null,
      icon: null,
      color: null,
      type: null,
    };
  }

  componentDidMount() {
    const { iconKey } = this.props;
    const generatedKey = this.generateIconAndColor(iconKey);
    if (generatedKey) {
      this.setState(generatedKey);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.iconKey !== prevProps.iconKey) {
      const { iconKey } = this.props;
      const generatedKey = this.generateIconAndColor(iconKey);
      if (generatedKey) {
        this.setState(generatedKey);
      }
    }
  }

  generateIconAndColor(id) {
    const generatedInt = this.cyrb53(id);
    const colorKey = (generatedInt % colorsCount) + 1;
    const iconKey = (generatedInt % iconsCount) + 1;
    return { color: colorKey, icon: iconKey };
  }

  lowerId(num) {
    if (num > 1000) {
      return this.lowerId(num / 100);
    }
    return Math.floor(num);
  }

  cyrb53(str, seed = 0) {
    // this function hashes a string into an integer
    // it has good hash distribution and low collision rate
    // taken from here: https://stackoverflow.com/a/52171480
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 =
      Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
      Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 =
      Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
      Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  }

  render() {
    const { icon } = this.state;
    const CLASSNAME = `icon-color-${this.state.color}`;
    if (!icon && icon !== 0) {
      return null;
    }
    const iconNumber = icon;
    const IconComponent = iconMap[`Icon${String(iconNumber).padStart(2, "0")}`];

    if (!IconComponent) {
      return <Icon14 className={CLASSNAME} />;
    }
    return <IconComponent className={CLASSNAME} />;
  }
}

export default IconGenerator;
