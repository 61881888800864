import { SURVEY_API_URL } from "../constants/api";
import { utils_api } from "../utils/utils_api";

export const api_survey = {
  get_surveys_list(success, fail) {
    return utils_api.auth_get(SURVEY_API_URL.SURVEYS_GET, success, fail);
  },
  get_survey_by_id(success, fail, surveyId) {
    const param = `/${surveyId}${SURVEY_API_URL.SURVEYS_GET_PARTICIPANT_URL}`;
    return utils_api.auth_get(
      SURVEY_API_URL.SURVEY_BASE + param,
      success,
      fail,
    );
  },
  get_one_survey_details(success, fail, surveyId) {
    // /surveys?survey_id=9f293243-e7ab-00e4-5335-8201771e91a9&meta_data_only=true
    const param = `s?survey_id=${surveyId}&meta_data_only=true`;
    return utils_api.auth_get(
      SURVEY_API_URL.SURVEY_BASE + param,
      success,
      fail,
    );
  },
  get_highlighted_survey(success, fail) {
    return utils_api.auth_get(SURVEY_API_URL.SURVEYS_GROUP_GET, success, fail);
  },
};
