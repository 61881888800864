const CARD_ANIMATION = 200;
const TRANSLATE = "100vw";
const ROTATE = 5;

export const utils_card_animation = {
  cardBackwardAnimation: (selector, action) => {
    document.querySelector(selector).style =
      `transform: translateX(${TRANSLATE}) rotate(${ROTATE}deg); transition: transform ${CARD_ANIMATION}ms ease;`;
    setTimeout(() => {
      document.querySelector(selector).style =
        `transform: translateX(-${TRANSLATE}) rotate(-${ROTATE}deg);`;
    }, CARD_ANIMATION);

    setTimeout(() => {
      action();
    }, CARD_ANIMATION);

    setTimeout(() => {
      document.querySelector(selector).style =
        `transform: translateX(0); transition: transform ${CARD_ANIMATION}ms ease;`;
    }, CARD_ANIMATION + 50);
  },
  cardForwardAnimation: (selector, action) => {
    document.querySelector(selector).style =
      `transform: translateX(-${TRANSLATE}) rotate(-${ROTATE}deg); transition: transform ${CARD_ANIMATION}ms ease;`;
    setTimeout(() => {
      document.querySelector(selector).style =
        `transform: translateX(${TRANSLATE}) rotate(${ROTATE}deg);`;
    }, CARD_ANIMATION);

    setTimeout(() => {
      action();
    }, CARD_ANIMATION);

    setTimeout(() => {
      document.querySelector(selector).style =
        `transform: translateX(0); transition: transform ${CARD_ANIMATION}ms ease;`;
    }, CARD_ANIMATION + 50);
  },
};
