import { API_URL } from "../constants/api";
import { utils_api } from "../utils/utils_api";

// DEPRECATED

export const api_consent = {
  get_consent(success, fail, code) {
    const url = `${API_URL.CONSENT_GET}?consent_code=${code}`;
    return utils_api.auth_get(url, success, fail);
  },
  submit_consent(success, fail, consent_v, user_response, consent_signature) {
    const data = {
      version: consent_v,
      resp: user_response,
      esign: [consent_signature],
    };
    return utils_api.auth_post(API_URL.CONSENT_POST, data, success, fail);
  },
  get_consent_file(success, fail) {
    return utils_api.auth_get(`${API_URL.CONSENT_FILE_GET}`, success, fail);
  },
  get_consent_knowledge_check(success, fail) {
    return utils_api.unauth_get(
      API_URL.CONSENT_KNOWLEDGE_CHECK_GET,
      success,
      fail,
    );
  },
};
