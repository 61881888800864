import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PAGES from "src/constants/pages";
import SurveyCards from "./survey_card";
import SurveyConsentModal from "./survey_consent_modal";
import { utils } from "../../utils/utils_general";

class SurveyList extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      showSurveys: null,
      showConsentModal: false,
      selectedSurvey: null,
    };
  }

  componentDidUpdate() {
    if (
      this.props.showSurveys &&
      this.props.showSurveys !== this.state.showSurveys
    ) {
      this.setState({
        showSurveys: this.props.showSurveys,
      });
    }
  }

  onSurveyClick(survey) {
    this.setState({ selectedSurvey: survey });
    if (utils.is_mobile()) {
      this.props.history.push(`${PAGES.SURVEY_CONSENT}/${survey.survey_id}`, {
        survey,
        fromSurvey: true,
      });
    } else {
      this.setState({ showConsentModal: true });
    }
  }

  render() {
    if (!this.state.showSurveys) {
      return null;
    }
    const surveys = this.state.showSurveys;

    return (
      <section>
        <div className="row small-gutters">
          {surveys.map((survey, key) => (
            <SurveyCards
              title={survey.title}
              detail={survey.description}
              key={key}
              iconKey={survey.limesurvey_id}
              action={() => this.onSurveyClick(survey)}
              showRetake={this.props.completePage && survey.completed}
            />
          ))}
        </div>
        <SurveyConsentModal
          fromSurvey
          show={this.state.showConsentModal}
          onHide={() => this.setState({ showConsentModal: false })}
          survey={this.state.selectedSurvey}
        />
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});

export default withRouter(connect(mapStateToProps, {})(SurveyList));
