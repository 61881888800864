export const COPY_PAGE_DESCRIPTION = {
  SURVEYS:
    "<p>It may seem like a small ask, but your honest responses to these surveys can play a big part in advancing precision medicine. </p><br /><p>You can take surveys multiple times and track your progress as you go.</p>",
  PROFILE:
    "Here you can update your personal info, preferences, security options and more.",
  DASHBOARD:
    "Every quick poll you answer and survey you complete gets us all closer to finding innovative ways to treat disease. <br /><br /> Take a Survey or Quick Poll now.",
  DASHBOARD_NO_SURVEY:
    "Every quick poll you answer gets us all closer to finding innovative ways to treat disease. <br /><br /> Take a Quick Poll now.",
  DASHBOARD_NO_QP:
    "Every survey you complete gets us all closer to finding innovative ways to treat disease. <br /><br /> Take a Survey now.",
};

export const COPY_WELCOME_MESSAGE =
  "Advances in medicine would not be possible without the help of people like you. Just by using this app, you’ll be contributing to research that could lead to the discovery of new treatments for people in need. Plus you’ll get to learn more about your own health in the process.";
