import BSSpinner from "react-bootstrap/Spinner";

const Spinner = (props) => {
  const { t, data } = props;
  const { classes = "", messageI18n } = data || {};
  return (
    <div
      className={`spinner text-center mt-3 mb-3 ${props.relative ? "spinner-relative" : ""} ${classes}`}
    >
      {messageI18n && <span className="message">{messageI18n}</span>}
      <BSSpinner animation="border" role="status" variant="primary"></BSSpinner>
    </div>
  );
};

export default Spinner;
