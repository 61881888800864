// expects a onComplete prop as this component will be used within workflow

import React from "react";
import { Button, Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { utils } from "../../utils/utils_general";
import { LOCAL_STORAGE } from "../../constants/localStorage";
import {
  post_emr_connection,
  get_emr_connection,
} from "../../actions/emrAction";

const DEFAULT_REDIRECT_DELAY = 5;

class EMRModal extends React.Component {
  constructor() {
    super();
    this.searchInput = React.createRef();
    this.isCheckingTab = false;
    this.state = {
      modalError: null,
      modalItem: null,
      pickedItem: null,
      modalTimeout: null,
      modalTimeLeft: DEFAULT_REDIRECT_DELAY,
      userConfirmed: false,
      userConnected: false,
      emrTab: null,
      changedMyMind: false,
    };

    this.completeEmrProcess = async (storageEvent) => {
      if (storageEvent.key === LOCAL_STORAGE.EMR_CONNECT_PREFIX) {
        const { emrTab, pickedItem } = this.state;
        if (!pickedItem || this.isCheckingTab) return;
        this.isCheckingTab = true;
        const emrLocalStorageData = localStorage.getItem(
          `${LOCAL_STORAGE.EMR_CONNECT_PREFIX}`,
        );
        if (!emrLocalStorageData) return;
        const emrStoredItem = JSON.parse(emrLocalStorageData);
        if (emrStoredItem.complete) {
          if (!emrStoredItem.errored) {
            try {
              this.onCloseClick(true);
              if (this.props.onConnectionComplete) {
                this.props.onConnectionComplete();
              }
              await this.props.post_emr_connection(
                emrStoredItem.id,
                emrStoredItem.resource_url,
              );
              this.props.get_emr_connection();
            } catch (err) {
              this.setState({
                modalError: true,
              });
            } finally {
              localStorage.removeItem(`${LOCAL_STORAGE.EMR_CONNECT_PREFIX}`);
              emrTab.close();
              this.isCheckingTab = false;
            }
          } else {
            // user has denied on their page
            this.setState({
              modalError: true,
              changedMyMind: true,
            });
            emrTab.close();
            this.isCheckingTab = false;
            this.props.onConnectionError(
              new Error("EMR connnection unsuccessful"),
            );
          }
        }
      }
    };
  }

  componentDidMount() {
    window.addEventListener("storage", this.completeEmrProcess);
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.completeEmrProcess);
  }

  componentDidUpdate(prevProps) {
    if (this.props.show === true && prevProps.show === false) {
      this.setState({
        modalItem: null,
        modalError: null,
        modalTimeout: null,
        userConnected: false,
        modalTimeLeft: DEFAULT_REDIRECT_DELAY,
      });
      this.tickModalTimer();
    }
  }

  async followEhrLink() {
    window.clearTimeout(this.state.modalTimeout);
    const data = { ...this.props.modalItem, connected: false, started: true };
    localStorage.setItem(
      `${LOCAL_STORAGE.EMR_CONNECT_PREFIX}`,
      JSON.stringify(data),
    );
    this.setState({
      emrTab: window.open(this.props.modalItem?.url, "_blank"),
      pickedItem: this.props.modalItem,
    });
    if (!this.state.userConnected) {
      this.setState({
        userConnected: true,
      });
    }
  }

  tickModalTimer() {
    window.clearTimeout(this.state.modalTimeout);
    const updatedTimeLeft = this.state.modalTimeLeft - 1;
    this.setState({
      modalTimeout: window.setTimeout(() => {
        if (updatedTimeLeft === 0) {
          return this.followEhrLink();
        }
        this.setState({
          modalTimeLeft: updatedTimeLeft,
        });
        this.tickModalTimer();
      }, 1000),
    });
  }

  closeEhrModal(showThankYou) {
    window.clearTimeout(this.state.modalTimeout);
    this.setState({
      modalItem: null,
      modalError: null,
      modalTimeout: null,
      userConnected: false,
      modalTimeLeft: DEFAULT_REDIRECT_DELAY,
      showThankYou,
    });
  }

  onCloseClick(showThankYou) {
    this.closeEhrModal();

    if (!this.props.onCloseClick) return false;
    return this.props.onCloseClick(showThankYou);
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={() => this.onCloseClick()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`consent-modal ${utils.get_browser()}`}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {!this.state.userConnected ? (
              <h1 className="h4">
                {this.props.t(
                  "Connect your {{target}} Electronic Health Record",
                  { target: this.props.modalItem?.name },
                )}
              </h1>
            ) : (
              <h1 className="h4">
                {this.props.t(
                  "You are currently connecting {{target}} EMR record to this study in a different tab",
                  { target: this.props.modalItem?.name },
                )}
              </h1>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!this.state.userConnected && !this.state.modalError && (
            <>
              <p className="mb-3 font-weight-bold">
                {this.props.t(
                  "In {{seconds}} seconds you will be navigated to a new tab.",
                  { seconds: this.state.modalTimeLeft },
                )}
              </p>
              <div className="text-center mt-4">
                <Button
                  variant="primary"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.followEhrLink()}
                >
                  {this.props.t("Go now")}
                </Button>
              </div>
              <div className="text-center mt-2">
                <Button
                  variant="secondary"
                  className="btn-sm"
                  onClick={() => this.onCloseClick(!!this.state.showThankYou)}
                >
                  {this.props.t("Cancel")}
                </Button>
              </div>
            </>
          )}
          {this.state.userConnected && !this.state.modalError && (
            <>
              <p className="mb-3 font-weight-bold">
                {this.props.t(
                  "If you accidentally closed that tab or wish to cancel, please click one of the links below.",
                  { seconds: this.state.modalTimeLeft },
                )}
              </p>
              <div className="text-center mt-4">
                <Button
                  variant="secondary"
                  className="btn btn-sm"
                  onClick={() => this.followEhrLink()}
                >
                  {this.props.t("Re-Open")}
                </Button>
              </div>
              <div className="text-center mt-2">
                <Button
                  variant="secondary"
                  className="btn-sm"
                  onClick={() => this.onCloseClick()}
                >
                  {this.props.t("Cancel")}
                </Button>
              </div>
            </>
          )}
          {this.state.modalError && (
            <>
              <p className="mb-3 font-weight-bold text-danger">
                {this.props.t(
                  "Something went wrong, please contact support or try again later.",
                )}
              </p>
              <div className="text-center mt-2">
                <Button
                  variant="secondary"
                  className="btn-sm"
                  onClick={() => this.onCloseClick(true)}
                >
                  {this.props.t("Close")}
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(null, { post_emr_connection, get_emr_connection })(
  withTranslation()(EMRModal),
);
