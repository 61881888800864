const PAGES = {
  LOGIN: "/login",
  LOGIN_PASSWORD: "/login/password",
  LOGIN_MAGIC_LINK: "/login/magic-link",
  MAGIC_LINK: "/magic-link",
  MAGIC_LINK_ERROR: "/magic-link/error",
  MFA_OTP: "/mfa-otp",
  CONFIRM_EMAIL: "/confirm-email",
  VERIFY_EMAIL: "/verify_email",
  CONSENT: "/consent",
  APP_POLICIES: "/app-policies",
  PROFILE: "/profile",
  LOGOUT: "/logout",

  RESET_PASSWORD_SEND_EMAIL: "/password-reset",
  RESET_PASSWORD_SEND_EMAIL_COMPLETE: "/password-reset/email-sent",
  RESET_PASSWORD_EXPIRED: "/password-reset/expired",
  RESET_PASSWORD_SET_NEW: "/password-reset/set-new",
  RESET_PASSWORD_COMPLETE: "/password-reset/confirmed",

  SIGNUP: "/signup",

  SIGNUP_LINK_EXPIRED: "/signup/expired",
  SIGNUP_LINK_EMAIL_SENT: "/signup/email-sent",
  SIGNUP_CONFIRM_EMAIL: "/signup/confirm-email",
  SIGNUP_PASSWORD: "/signup/password",
  SIGNUP_SUBWORKFLOW: "/signup/subworkflow",
  SIGNUP_COMPLETE: "/signup/complete",
  SIGNUP_ALREADY_ACTIVE: "/signup/already-active",
  SIGNUP_APP_POLICIES: "/signup/app-policies",
  SIGNUP_CONSENT: "/signup/consent",
  SIGNUP_VERIFICATION: "/signup/verification",
  SIGNUP_QUESTIONNAIRE: "/signup/questionnaire",
  SIGNUP_TEXT: "/signup/info",
  SIGNUP_STOP: "/signup/stop",

  ACCOUNT_DELETED: "/deleted",

  DASHBOARD: "/dashboard",
  DASHBOARD_WORKFLOW: "/gateway/workflow",
  DASHBOARD_WORKFLOW_EMRv2: "/gateway/workflow/emr_workflow_v2",
  DASHBOARD_WORKFLOW_EMR: "/gateway/workflow/workflow_emr",
  DASHBOARD_WORKFLOW_SPITKIT: "/gateway/workflow/workflow_spitkit",

  ENROLLMENT: "/enrollment",
  ENROLLMENT_THANKYOU: "/enrollment/thankyou",
  ENROLLMENT_PROFILE: "/enrollmentprofile",
  ENROLLMENT_EXIT: "/enrollmentexit",

  EMR_COMPLETE: "/emr/complete",
  EMR_CONNECT_WORKFLOW: "/emr/connect",

  SURVEYS: "/surveys",
  SURVEY_CONSENT: "/surveys/consent",
  SURVEY_START: "/surveys/start",
  SURVEY_COMPLETE: "/surveys/complete",

  ANCESTRY: "/ancestry",

  HEALTHCHECK: "/healthcheck",

  HOME_PAGE: "/",

  REFERRAL_LANDING_PAGE: "/referral",

  ABOUT_US: "/about-us",
  CONTACT_US: "/contact-us",

  CONTACT: "/contact",

  NOT_FOUND: "/oops",

  FAKE_STORY_BOOK: "/fake-story-book",

  PRIVACY_POLICY: "/privacy-policy",
  FAQ: "/faq",
  BLOG: "/blog",
  STUDY_TEAM: "/study-team",
  STUDY_PARTNERSHIPS: "/study-partnerships",
  SUPPORT: "/support",
  RECRUITMENT: "/recruitment",
  DIVERSITY: "/diversity",
  GENETICS_101: "/genetics-101",
  DATA_PROTECTION: "/data-protection",
  TERMS_OF_SERVICE: "/terms-of-service",
  RESEARCH_CONSENT: "/research-consent",
  COOKIE_POLICY: "/cookie-policy",
  FUNDING: "/funding",
  CORE_VALUES: "/core-values",
  NEWS: "/news",
  VOLUNTEER: "/volunteer",
  ANCESTRY_CONTENT: "/ancestry-content",
  WITHDRAWAL: "/withdrawal",
};

export default PAGES;
