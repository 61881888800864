import React from "react";
import Logo from "../../assets/images/logo.png";

export const PageLoader = (props) => (
  <div
    className="dashboard-loader"
    style={!props.loader ? { opacity: 0 } : { opacity: 1 }}
  >
    {props.blankPage ? null : (
      <div className="dashboard-loader-wrapper">
        <img className="rgc-spinner" src={Logo} alt="" />
        {props.loaderMessage ? (
          <div className="mt-2 text-center font-callout">
            {props.loaderMessage}
          </div>
        ) : null}
      </div>
    )}
  </div>
);

export default PageLoader;
