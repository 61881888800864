import { useTranslation } from "react-i18next";

export default function WebPageTitle({ webPageType }) {
  const { t } = useTranslation();
  return (
    <div className="web-page-title-container">
      <div className="container">
        <div className="web-page-title">{t(`${webPageType}_Title`)}</div>
        <div className="web-page-subtitle">{t(`${webPageType}_Subtitle`)}</div>
        <div className="web-page-author">{t(`${webPageType}_author`)}</div>
      </div>
    </div>
  );
}
