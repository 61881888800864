import {
  GA4_ENABLED,
  GA4_LOGS_ENABLED,
  APP_BASE_URL,
} from "../constants/api_config";
import { utils } from "src/utils/utils_general";
import { LOCAL_STORAGE } from "src/constants/localStorage";

const utilsGA = {
  ga4Enabled: () => {
    return GA4_ENABLED && window.gtag;
  },
  isTraceable: (target) => {
    if (target?.classList?.length > 0) {
      const classList = Array.from(target.classList);
      return classList.find((clas) => clas.includes("ga4-track"));
    }
    return false;
  },
  listenAppClicksForGA4: () => {
    if (utilsGA.ga4Enabled()) {
      document.addEventListener("click", (event) => {
        const target = event.target || {};
        const validTag = ["A", "BUTTON"].includes(target.tagName);
        if (validTag || utilsGA.isTraceable(target)) {
          if (utilsGA.hrefToOurDomain(target.href)) {
            utilsGA.ga4EventClick(target);
          }
        }
      });
    }
  },
  listenFormSubmitEventsForGA4: () => {
    if (utilsGA.ga4Enabled()) {
      document.addEventListener("submit", (event) => {
        const target = event.target || {};
        if (utilsGA.isTraceable(target)) {
          const formName = target.getAttribute("data-name");
          const submit_text = event.submitter.innerText;
          utilsGA.sendFormSubmitToGA(formName, submit_text);
        }
      });
    }
  },
  ga4EventClick: (target) => {
    // only click metrics to our domain, GA4 tracks for the other domains automatically
    const params = {
      link_classes: target.classList,
      link_domain: utilsGA.getHostName(APP_BASE_URL),
      link_id: target.id,
      link_url: target.href,
      outbound: false,
      link_text: target.innerText,
    };
    utilsGA.ga4Event(eventEnum.clickInside, params);
  },
  ga4Event: (eventName, eventParameters) => {
    if (utilsGA.ga4Enabled()) {
      if (GA4_LOGS_ENABLED) {
        console.log("-----> GA4 Event: ", eventName, eventParameters);
      }
      window.gtag("event", eventName, eventParameters);
    }
  },
  hrefToOurDomain: (href = "") => {
    return (
      (href.includes("http") &&
        new URL(href).hostname.includes(utilsGA.getHostName(APP_BASE_URL))) ||
      !href.includes("http")
    );
  },
  getHostName: (href = "") => {
    return new URL(href).hostname;
  },
  sendWorkflowStepToGA: (workflowName = "", currentStep) => {
    const currentStepName = currentStep?.step || "";
    if (currentStepName) {
      const workflowSteps =
        utils.get_local_storage(LOCAL_STORAGE.WORKFLOW_STEPS_GA4) || [];
      for (let index = 0; index < workflowSteps.length; index++) {
        if (workflowSteps[index].step == currentStepName) {
          if (!workflowSteps[index].trackOnGA4) {
            utilsGA.ga4Event(eventEnum.workflowStep, {
              workflow: workflowName.toLowerCase(),
              current_step: currentStepName.toLowerCase(),
            });
            workflowSteps[index].trackOnGA4 = true;
            utils.set_local_storage(
              LOCAL_STORAGE.WORKFLOW_STEPS_GA4,
              workflowSteps,
            );
          }
          break;
        }
      }
    }
  },
  sendWorkflowStartToGA: (workflowName = "", workflowSteps) => {
    const workflowStart = utils.get_local_storage(LOCAL_STORAGE.WORKFLOW_START);
    if (!workflowStart && workflowSteps) {
      utilsGA.ga4Event(eventEnum.workflowStart, {
        workflow: workflowName.toLowerCase(),
      });
      utils.set_local_storage(LOCAL_STORAGE.WORKFLOW_STEPS_GA4, workflowSteps);
      utils.set_local_storage(LOCAL_STORAGE.WORKFLOW_START, true);
    }
  },
  sendWorkflowEndToGA: (workflowName = "") => {
    utilsGA.ga4Event(eventEnum.workflowEnd, {
      workflow: workflowName.toLowerCase(),
    });
    utils.remove_local_storage(LOCAL_STORAGE.WORKFLOW_STEPS_GA4);
    utils.remove_local_storage(LOCAL_STORAGE.WORKFLOW_START);
  },
  formFieldEvent: (element, event) => {
    const target = element.target || {};
    const form = element.target.form;
    const validTag = ["INPUT", "TEXTAREA", "SELECT"].includes(target.tagName);
    if (validTag && utilsGA.isTraceable(form)) {
      const formName = form.getAttribute("data-name");
      const fieldName = element.target.labels?.[0]?.textContent;
      utilsGA.sendFormFieldEventToGA(event, formName, fieldName);
    }
  },
  sendFormStartToGA: (formName = "") => {
    utilsGA.ga4Event(eventEnum.formStart, {
      form_name: formName.toLowerCase(),
    });
  },
  sendFormSubmitToGA: (formName = "", submit_text) => {
    utilsGA.ga4Event(eventEnum.formSubmit, {
      form_name: formName.toLowerCase(),
      ...(submit_text && { form_submit_text: submit_text.toLowerCase() }),
    });
  },
  sendFormFieldEventToGA: (
    formFieldEvent,
    formName = "",
    fieldName = "",
    error,
  ) => {
    utilsGA.ga4Event(formFieldEvent, {
      form_name: formName.toLowerCase(),
      field_name: fieldName.toLowerCase(),
      ...(error && { error }),
    });
  },
  sendFormFieldsErrorsEventToGA: (formName = "", errors) => {
    utilsGA.ga4Event(eventEnum.formFieldsError, {
      form_name: formName.toLowerCase(),
      errors,
    });
  },
};

export const eventEnum = {
  clickInside: "click_inside",
  quickPollSubmit: "qpoll_submit",
  quickPollSkip: "qpoll_skip",
  quickPollNext: "qpoll_next",
  quickPollPrevious: "qpoll_previous",
  quickPollTakeSurvey: "qpoll_take_survey",
  quickPollContinuePolls: "qpoll_continue_qpolls",
  workflowStart: "workflow_start",
  workflowEnd: "workflow_end",
  workflowStep: "workflow_step",
  formStart: "form_start",
  formSubmit: "form_submit",
  formFieldEnter: "form_field_enter",
  formFieldLeave: "form_field_leave",
  formFieldsError: "form_fields_error",
};

export const workflowNames = {
  signUp: "sign up",
  enrollment: "enrollment",
};

export const formNames = {
  signUp: "sign up",
  orderDNAKit: "order dna kit",
  profilePhone: "profile phone",
  profilePassword: "profile password",
  signupPassword: "sign up set password",
  resetPassword: "reset password",
};

export default utilsGA;
