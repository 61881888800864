import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from "../utils/utils_general";
import { api_emr } from "../api/api_emr";
import { utils_api } from "../utils/utils_api.js";

export function get_emr_data(search = "", hideSpinner = false) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, "get_emr_data");
    }

    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data;
    };

    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_emr.get_emr_clinics(search, success, fail);
  };
}

export function post_emr_connection(id, resource_url) {
  return (dispatch) => {
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data;
    };

    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_emr.post_emr_connection(id, resource_url, success, fail);
  };
}

export function get_emr_workflow() {
  return async (dispatch) => {
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);

    const { data } = await api_emr.get_emr_workflow(success, fail);

    dispatch({
      type: REDUX_ACTION.EMR_WORKFLOW,
      data,
    });
  };
}

export function get_emr_connection() {
  return async (dispatch) => {
    const success = (response) => response.data;

    dispatch({
      type: REDUX_ACTION.EMR_EXISTING_CONNECTIONS_LOADING,
      data: true,
    });

    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    const res = await api_emr.get_emr_connection(success, fail);

    dispatch({
      type: REDUX_ACTION.EMR_EXISTING_CONNECTIONS,
      data: res.data,
    });
    dispatch({
      type: REDUX_ACTION.EMR_EXISTING_CONNECTIONS_LOADING,
      data: false,
    });

    return res.data;
  };
}
