/**
 * @file components/signup_link/signup_link.js
 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { utils } from "../../../utils/utils_general";
import { login_post } from "../../../actions/loginAction";
import { post_user_attributes_post } from "../../../actions/userAction";
import HtmlContentComponent from "src/components/core/HtmlContent/HtmlContentComponent";

class ConfirmEmailURL extends React.Component {
  _isMounted = false;

  constructor({ t }) {
    super();
    this.t = t;
    this.state = {
      email: null,
      user: null,
      errors: {},
      reg_flow: null,
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.onComplete(this.props.email);
  }

  render() {
    const noErrors = utils.is_obj_empty(this.state.errors);
    const { t, email, loginUser } = this.props;
    return (
      <>
        <HtmlContentComponent
          tag={"div"}
          markup={t("texts:Let's Start", {
            email: email,
            first_name: loginUser.first_name,
            last_name: loginUser.last_name,
          })}
        />

        <div
          className="h2 mt-5 mb-3"
          style={{
            fontSize: "18px",
            fontWeight: "normal",
            lineHeight: "24px",
            overflowWrap: "break-word",
          }}
        >
          <HtmlContentComponent
            tag={"div"}
            markup={t("texts:You will use your email address", {
              email: email,
              first_name: loginUser.first_name,
              last_name: loginUser.last_name,
            })}
          />
          <HtmlContentComponent
            tag={"div"}
            markup={t("texts:to access the application.", {
              email: email,
              first_name: loginUser.first_name,
              last_name: loginUser.last_name,
            })}
          />
        </div>

        <div className="unauth-body-footer">
          <Button
            variant={noErrors ? "primary" : "disable"}
            type="submit"
            block
            onClick={(e) =>
              noErrors ? this.handleSubmit(e) : e.preventDefault()
            }
          >
            {this.t("Continue")}
          </Button>
          <Form.Text className="text-center text-danger form-error">
            {this.t(this.state.errors.system) || <br />}
          </Form.Text>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, { login_post, post_user_attributes_post })(
    withTranslation()(ConfirmEmailURL),
  ),
);
