import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SIGNUP_OTP_ERROR_DISPLAY } from "../../../constants/errors";
import { LINKS } from "../../../constants/links";
import HtmlContentComponent from "src/components/core/HtmlContent/HtmlContentComponent";

const ConfirmEmailError = (props) => {
  const { t } = useTranslation();
  let error = t(SIGNUP_OTP_ERROR_DISPLAY.GENERIC);
  if (props.error) {
    switch (props.error) {
      case "Too late to respond":
        error = t(SIGNUP_OTP_ERROR_DISPLAY.VERIFICATION_EXPIRED);
        break;
      case "Reached maximum limit":
        error = t(SIGNUP_OTP_ERROR_DISPLAY.MAX_REACHED);
        break;
      case "max request":
        error = t(SIGNUP_OTP_ERROR_DISPLAY.MAX_REQUEST_REACHED);
        break;
      default:
        error = t(SIGNUP_OTP_ERROR_DISPLAY.GENERIC);
        break;
    }
  }

  const supportLink = (
    <span>
      <HtmlContentComponent
        tag={"div"}
        markup={t("pleaseContactSupport", { link: LINKS.SUPPORT })}
      />
    </span>
  );

  return (
    <>
      <h1>{t("Error")}</h1>
      <div
        className="h2 mt-5 mb-5"
        style={{ fontSize: "18px", fontWeight: "normal", lineHeight: "24px" }}
      >
        {error}
        {error === SIGNUP_OTP_ERROR_DISPLAY.MAX_REQUEST_REACHED
          ? supportLink
          : null}
      </div>

      <Link className="btn btn-primary" to="/">
        {t("Go back to home")}
      </Link>
    </>
  );
};

export default ConfirmEmailError;
