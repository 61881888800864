// props:
// videoUrl - string
// forceFullscreen - true
// onVidComplete - function
// reqWatchSec - number

import React from "react";
import { findDOMNode } from "react-dom";
import ReactPlayer from "react-player/file";
import screenfull from "screenfull";
import { Link } from "react-router-dom";
import { ReactComponent as PlayIcon } from "src/assets/icons/play.svg";
import { ReactComponent as PauseIcon } from "src/assets/icons/pause.svg";
import { ReactComponent as RestartIcon } from "src/assets/icons/restart.svg";
import { ReactComponent as Volume3Icon } from "src/assets/icons/volume-3.svg";
import { ReactComponent as Volume2Icon } from "src/assets/icons/volume-2.svg";
import { ReactComponent as Volume1Icon } from "src/assets/icons/volume-1.svg";
import { utils } from "src/utils/utils_general";
import { LINKS } from "src/constants/links";
import { ENV_STRING } from "src/constants/api";
import { withTranslation } from "react-i18next";

const VideoError = (props) => {
  return (
    <div>
      <p className="text-danger mb-2">
        {props.t(`We've hit an error in playing your video, please`)}{" "}
        <a href={LINKS.SUPPORT} target="_blank" rel="noopener noreferrer">
          {props.t("contact support")}
        </a>
        .
      </p>
      <p>
        {props.t("Go back")}{" "}
        <Link to="/" state={{ loader: false }}>
          {props.t("Home")}
        </Link>
        .
      </p>
    </div>
  );
};

class VideoPlayer extends React.Component {
  constructor() {
    super();
    this.state = {
      currentTime: 0,
      playing: false,
      volume: 0.5,
      isRestarting: false,
      duration: null,
      videoError: null,
    };
  }

  ref = (player) => {
    this.player = player;
  };

  componentDidMount() {
    const duration = this.player.getDuration();
    if (duration) {
      this.setState({ duration });
    }
  }

  disableVideoSeek(sec) {
    if (!this.state.isRestarting) {
      this.setState({ playing: false });
      const { currentTime } = this.state;
      const delta = sec - currentTime;
      if (Math.abs(delta) > 0.01) {
        this.player.seekTo(currentTime);
        this.setState({ currentTime, triedToSeek: true });
        setTimeout(() => {
          this.setState({ triedToSeek: false });
        }, 3000);
      }
    } else {
      this.setState({ isRestarting: false });
    }
  }

  onVideoProgress(current) {
    // if more than 3s, dont set because it means we are seeking and we dont allow seeking
    if (
      current.playedSeconds - this.state.currentTime < 3 &&
      current.playedSeconds !== this.state.currentTime
    ) {
      this.setState({ currentTime: current.playedSeconds });
    }

    const duration = this.player.getDuration() || this.state.duration;

    if (this.props.reqWatchSec) {
      const local = ENV_STRING === "LOCAL" || ENV_STRING === "DEV";
      const mustWatchSec =
        duration && duration < this.props.reqWatchSec
          ? Math.floor(duration)
          : this.props.reqWatchSec;
      if (
        this.props.onVidComplete &&
        current.playedSeconds > (local ? 0 : mustWatchSec)
      ) {
        if (this.props.videoIndex || this.props.videoIndex === 0) {
          this.props.onVidComplete(this.props.videoIndex);
        } else {
          this.props.onVidComplete();
        }
      }
    }

    // when done
    if (this.props.forceFullscreen) {
      if (
        this.props.onVidComplete &&
        duration &&
        Math.abs(duration - current.playedSeconds) < 0.5
      ) {
        this.exitFullScreen();
      }
    }
  }

  defaultClickPlay(e) {
    if (!this.state.duration) {
      const duration = this.player.getDuration();
      this.setState({ duration });
    }

    if (this.props.forceFullscreen) {
      if (!this.state.playing) {
        this.enterFullScreen();
      }
    }

    this.setState({ playing: !this.state.playing });
  }

  handlePause() {
    this.setState({ playing: false });
  }

  defaultClickRestart() {
    this.setState({ playing: this.state.playing, isRestarting: true });
    this.player.seekTo(0);
  }

  defaultClickVolume(e) {
    const volume = parseFloat(e.target.value) / 10;
    this.setState({ volume });
  }

  enterFullScreen() {
    if (screenfull.isEnabled) screenfull.request(findDOMNode(this.player));
  }

  exitFullScreen() {
    if (screenfull.isEnabled) screenfull.exit(findDOMNode(this.player));
  }

  renderPlayer(file) {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url={file}
          width="100%"
          height="100%"
          controls={false}
          ref={this.ref}
          playing={this.state.playing}
          volume={this.state.volume}
          onClick={() => this.defaultClickPlay()}
          onSeek={(sec) => this.disableVideoSeek(sec)}
          onProgress={(current) => this.onVideoProgress(current)}
          onPlay={() => this.setState({ playing: true })}
          onPause={() => this.handlePause()}
          onError={(e) => this.setState({ videoError: e })}
        />
        {this.renderPlayerControls()}
      </div>
    );
  }

  renderPlayerControls() {
    const durationWidth = `${(this.state.currentTime / this.state.duration) * 100}%`;
    const currentTime = this.state.currentTime
      ? utils.secondsToFancyTimeFormat(this.state.currentTime)
      : "00:00";
    const durationTime = this.state.duration
      ? ` / ${utils.secondsToFancyTimeFormat(this.state.duration)}`
      : " / 00:00";
    return (
      <div className="player-controls-wrapper">
        <div className="player-controls-duration clearfix">
          <div className="player-controls-duration-line-wrapper">
            <div className="player-controls-duration-line">
              {this.state.duration ? (
                <div
                  className="player-controls-duration-line-marker"
                  style={{ width: durationWidth }}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="player-controls clearfix">
          <div className="player-controls-left">
            <button
              className="player-controls-restart"
              onClick={(e) => this.defaultClickRestart(e)}
            >
              <RestartIcon />
            </button>
            <button
              className="player-controls-play"
              onClick={(e) => this.defaultClickPlay(e)}
            >
              {this.state.playing ? <PauseIcon /> : <PlayIcon />}
            </button>
          </div>
          <div className="player-controls-center">
            {/* this only accopodates videos under 1h, change width if otherwise */}
            <div className="player-controls-duration-time">
              <span
                style={{
                  textAlign: "right",
                  width: "36px",
                  display: "inline-block",
                }}
              >
                {currentTime}
              </span>
              <span>{durationTime}</span>
            </div>
          </div>
          <div className="player-controls-right">
            <div className="player-controls-volume clearfix">
              <div className="player-controls-volume-icon">
                {this.renderVolumeIcon()}
              </div>
              <div className="player-controls-volume-input">
                <input
                  type="range"
                  value={this.state.volume * 10}
                  onChange={(e) => this.defaultClickVolume(e)}
                  min={1}
                  max={10}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderVolumeIcon() {
    if (this.state.volume <= 0.3) {
      return <Volume1Icon />;
    }
    if (this.state.volume > 0.3 && this.state.volume <= 0.7) {
      return <Volume2Icon />;
    }
    return <Volume3Icon />;
  }

  render() {
    const { videoUrl: videoFile } = this.props;
    const { videoError } = this.state;

    if (videoError) {
      return <VideoError {...this.props} />;
    }
    return this.renderPlayer(videoFile);
  }
}

export default withTranslation()(VideoPlayer);
