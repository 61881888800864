import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from "../utils/utils_general";
import { utils_api } from "../utils/utils_api.js";
import { api_reg_flow } from "../api/api_registration_flow.js";

export function self_reg_content_get(hideSpinner = true) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(
        dispatch,
        REDUX_ACTION.SPINNER,
        "self_reg_content_get",
      );
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      const res = response.data.data;
      const value = JSON.parse(res.value);
      dispatch({
        type: REDUX_ACTION.SELF_REG_CONTENT_GET,
        data: value,
      });
      return value;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);

    return api_reg_flow.get_self_reg_content(success, fail);
  };
}
