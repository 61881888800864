import Joi, { ValidationErrorItem } from "joi";
import { useInjection } from "src/services/ServiceProvider";
import { useState } from "react";
import ValidationService, { ValidationRules } from "./ValidationService";

export interface UseValidateResponse<TInterfaceToValidate> {
  errors: Joi.ValidationErrorItem[];
  hasErrors: boolean;
  validate: (data: TInterfaceToValidate) => boolean;
  getError: (field: keyof TInterfaceToValidate) => string | undefined;
  clearErrors: () => void;
  clearError: (field: keyof TInterfaceToValidate) => void;
}

export function useValidate<TInterfaceToValidate>(
  rulesName: keyof ValidationRules,
): UseValidateResponse<TInterfaceToValidate> {
  const [errors, setErrors] = useState<ValidationErrorItem[]>([]);
  const [errorsMap, setErrorsMap] = useState<Record<string, string>>({});

  const validationService =
    useInjection<ValidationService>("validationService");

  const validate = (data: TInterfaceToValidate): boolean => {
    const result = validationService.validateData(rulesName, data);
    const resultErrors = result?.error?.details || [];
    const resultErrorsMap = resultErrors.reduce(
      (accumulate, error) => ({
        ...accumulate,
        [error.path[0]]: error.message,
      }),
      {},
    );

    setErrors(resultErrors);
    setErrorsMap(resultErrorsMap);

    return resultErrors.length === 0;
  };

  const getError = (field: keyof TInterfaceToValidate): string | undefined =>
    errorsMap[field as string];

  const clearErrors = () => {
    setErrors([]);
    setErrorsMap({});
  };

  const clearError = (field: keyof TInterfaceToValidate) => {
    setErrors(errors.filter(({ path }) => path[0] !== field));
    setErrorsMap(
      Object.entries(errorsMap)
        .filter(([k]) => k !== field)
        .reduce((accumulate, [k, v]) => ({ ...accumulate, [k]: v }), {}),
    );
  };

  const hasErrors = errors.length > 0;

  return {
    errors,
    hasErrors,
    validate,
    getError,
    clearErrors,
    clearError,
  };
}
