import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from "../utils/utils_general";
import { api_actioncards } from "../api/api_actioncards";
import { utils_api } from "../utils/utils_api.js";

const MAX_DISPLAY_ACTIONCARDS = 4;

export function actioncards_get(hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, "actioncards_get");
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      let action_cards = response.data.data.slice(0, MAX_DISPLAY_ACTIONCARDS);

      dispatch({
        type: REDUX_ACTION.ACTIONCARDS_GET,
        data: action_cards,
      });
      return action_cards;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_actioncards.get_actioncards(success, fail);
  };
}

export function actioncards_firstcall_get(hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(
        dispatch,
        REDUX_ACTION.SPINNER,
        "actioncards_firstcall_get",
      );
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      dispatch({
        type: REDUX_ACTION.ACTIONCARDS_FIRSTCALL_GET,
        data: response.data.data,
      });
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_actioncards.get_firstcall_actioncards(success, fail);
  };
}

export function actioncards_delete(hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(
        dispatch,
        REDUX_ACTION.SPINNER,
        "actioncards_delete",
      );
    }
    const success = (response) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_actioncards.delete_actioncards(success, fail);
  };
}
