import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

/* eslint-disable camelcase */
import { login_get } from "src/actions/loginAction";
import { post_user_attributes_post } from "src/actions/userAction";
import { utils_registration_flow } from "src/utils/utils_registration_flow";
import { utils_signup } from "src/utils/utils_signup";
import { utils } from "src/utils/utils_general";
import { LOCAL_STORAGE } from "src/constants/localStorage";
import { subworkflow_get } from "src/actions/subworkflowAction";
import {
  ConsentFlowStep,
  GenericRegistrationFlowStep,
  GenericWorkflow,
  UserAttribute,
} from "src/components/consent/types";
import SignupConsent2 from "src/components/consent/SignupConsent/SignupConsent2";

import SignupProgress from "src/components/signup/signup_progress";
import BlankComponent from "src/components/core/Blank/BlankComponent";
import { useTranslation } from "react-i18next";
/* eslint-enable camelcase */

const exitAttributeValue = (attributeString: any) => {
  if (attributeString === "true") {
    return true;
  }
  if (attributeString === "false") {
    return false;
  }
  if (attributeString === "*") {
    return true;
  }

  return attributeString;
};

type RegistrationWorkflow = GenericWorkflow;

export interface SignupWorkflowWrapperProps extends PropsFromRedux {
  regFlow: RegistrationWorkflow;
  stepComponentName?: string;
  /* eslint-disable camelcase, no-unused-vars */
  get_user_attributes_get: () => Promise<{ data?: any; error?: any }>;
  update_registration_flow: () => void;
  subworkflow_get: (attr1: string | null) => Promise<ConsentFlowStep[]>;
  post_workflow_complete_post: () => void;
  post_user_attributes_post: (
    key: string,
    valueToSend: string | boolean,
  ) => Promise<any>;
  /* eslint-enable camelcase, no-unused-vars */
  history: any;
  redirectBack: () => void;
}

const SignupWorkflowWrapper = ({
  regFlow,
  stepComponentName = "subworkflow",
  /* eslint-disable camelcase, no-shadow */
  get_user_attributes_get,
  update_registration_flow,
  post_workflow_complete_post,
  post_user_attributes_post,
  subworkflow_get,
  /* eslint-enable camelcase, no-shadow */
  history,
  redirectBack,
}: SignupWorkflowWrapperProps) => {
  const [currentOuterStep, setCurrentOuterStep] =
    useState<GenericRegistrationFlowStep | null>(null);
  const [currentWorkflow, setCurrentWorkflow] = useState<
    ConsentFlowStep[] | null
  >(null);
  const [errors, setErrors] = useState<UserAttribute[] | null>(null);

  const { t } = useTranslation();

  const updateCurrentWorkFlow = (workflow: ConsentFlowStep[] | null) => {
    setCurrentWorkflow(workflow);
  };

  useEffect(() => {
    let isCanceled = false;
    const fetchWorkflow = async () => {
      if (!currentOuterStep || !currentOuterStep.subworkflow_code) {
        return null;
      }
      const workflowCode = currentOuterStep.subworkflow_code;
      let newWorkflow = utils.get_local_storage(
        `${LOCAL_STORAGE.WORKFLOW}-${workflowCode}`,
      );
      try {
        if (!newWorkflow) {
          newWorkflow = await subworkflow_get(workflowCode);
        }
      } catch (e) {
        redirectBack();
        return null;
        // throw new Error(e);
      }

      const sanitizedSubworkflow = newWorkflow.map(
        (workflowComponent: GenericRegistrationFlowStep) => ({
          ...workflowComponent,
          value: null,
        }),
      );
      if (!isCanceled) {
        setCurrentWorkflow(sanitizedSubworkflow);
      }
      return sanitizedSubworkflow;
    };

    fetchWorkflow();
    return () => {
      isCanceled = true;
    };
  }, [currentOuterStep]);

  useEffect(() => {
    const newCurrentOuterStep = regFlow?.find(
      (item, index) => item.component === stepComponentName && index > 0,
    );
    setCurrentOuterStep(newCurrentOuterStep || null);
  }, [regFlow]);

  useEffect(() => {
    if (currentWorkflow) {
      const step = currentWorkflow?.find((c) => !c.value);
      if (!step) {
        onFormSubmit();
      }
    }
  }, [currentWorkflow]);

  function goToNextStep(): void {
    const currentStepExitAttributes = currentOuterStep?.exit_attribute || [];
    get_user_attributes_get()
      .then((res) => {
        const attributeErrors = utils_registration_flow.checkAttrCompleted(
          currentStepExitAttributes,
          res,
        );
        if (attributeErrors.length > 0) {
          setErrors(attributeErrors);
          console.error(attributeErrors);
        } else {
          utils_signup.goToNextRegistrationStep(
            regFlow,
            currentOuterStep,
            res,
            update_registration_flow,
            history?.push,
            post_workflow_complete_post,
          );
        }
      })
      .catch((catchedErrors) => setErrors(catchedErrors));
  }

  async function onFormSubmit() {
    const exitAttributes = currentOuterStep?.exit_attribute || [];
    if (!exitAttributes) {
      goToNextStep();
    }

    const promisesTosend = exitAttributes.map((item) => {
      const [key, value] = item.split("|");
      const valueToSend = exitAttributeValue(value);
      return post_user_attributes_post(key, valueToSend);
    });

    try {
      await Promise.all(promisesTosend);
      goToNextStep();
    } catch (e: any) {
      setErrors(e);
    }
  }

  function renderSignup() {
    if (errors?.length) {
      return (
        <div className="signup-body-wrapper">
          <BlankComponent>
            {t("Unexpected error, please contact support!")}
          </BlankComponent>
        </div>
      );
    }

    if (!currentWorkflow || !currentOuterStep || !regFlow) {
      return (
        <div className="signup-body-wrapper">
          <BlankComponent />
        </div>
      );
    }

    return (
      <SignupConsent2
        mandatoryConsentFlow={currentWorkflow}
        defaultFlow={regFlow}
        updateFlow={updateCurrentWorkFlow}
        onConsentCompleted={onFormSubmit}
        exitSignupSubworkflow={goToNextStep}
      />
    );
  }

  return (
    <section>
      <SignupProgress
        currentStep={currentOuterStep}
        regFlow={regFlow}
        history={history}
      />
      {renderSignup()}
    </section>
  );
};

const mapDispatchToProps = {
  subworkflow_get,
  login_get,
  post_user_attributes_post,
};
const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(SignupWorkflowWrapper);
