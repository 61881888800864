import { REDUX_ACTION } from "../constants/reduxAction.js";
import { api_user } from "../api/api_user";
import { utils_registration_flow } from "../utils/utils_registration_flow";
import { api_workflow } from "../api/api_workflow.js";
import { utils } from "../utils/utils_general.js";
import { utils_api } from "../utils/utils_api.js";
import { LOCAL_STORAGE } from "../constants/localStorage.js";
import { WORKFLOW_VALUE } from "../constants/workflow.js";

export function workflow_get(workflow_code = null) {
  return (dispatch) => {
    dispatch(worflow_loading(true));

    const success = (workflow) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return api_user.get_user_attribute(
        (response) => {
          const attrs = response.data.data;
          const processedWorkflow = workflow.data.data.map((c) => {
            if (c.exit_attribute[0] !== "*") {
              const attr_errors = utils_registration_flow.checkAttrCompleted(
                c.exit_attribute,
                attrs,
              );
              if (attr_errors.length === 0) {
                c.value = WORKFLOW_VALUE.COMPLETE;
              }
            }
            if (c.entry_attribute[0] !== "*") {
              const attr_errors = utils_registration_flow.checkAttrCompleted(
                c.entry_attribute,
                attrs,
              );
              if (attr_errors.length > 0) {
                c.locked = true;
              }
            }
            return c;
          });

          dispatch(worflow_update_data(processedWorkflow));
          dispatch(worflow_loading(false));

          return processedWorkflow;
        },
        (error) => utils_api.on_fail_default(dispatch, error),
      );
    };

    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return workflow_code
      ? api_workflow.get_workflow_by_code(workflow_code, success, fail)
      : api_workflow.get_workflow_by_token(success, fail);
  };
}

export function update_workflow(data) {
  return (dispatch) => dispatch(worflow_update_data(data));
}

function worflow_loading(data = true) {
  return {
    type: REDUX_ACTION.WORKFLOW_LOADING,
    data,
  };
}

function worflow_update_data(data = null) {
  utils.set_local_storage(LOCAL_STORAGE.WORKFLOW, data);
  return {
    type: REDUX_ACTION.WORKFLOW,
    data,
  };
}
