import axios from "axios";
import { API_URL } from "../constants/api";
import { utils_api } from "../utils/utils_api";

export const api_mapquest = {
  get_latlong(success, fail, location) {
    return axios
      .get(
        `https://www.mapquestapi.com/geocoding/v1/address?key=Vcv2AN5eQ09v45fdJOTXijoYbEdnJGEM&location=${location}`,
        { headers: { "Content-Type": "application/json" } },
      )
      .then((response) => success(response))
      .catch((error) => {
        fail(error);
        throw error;
      });
  },
  getGeometry(success, fail, region) {
    return utils_api
      .auth_get(
        `${API_URL.ANCESTRY_GEOMETRY}?level_region_code=${region}`,
        success,
        fail,
      )
      .then((response) => success(response))
      .catch((error) => {
        fail(error);
        throw error;
      });
  },
};
