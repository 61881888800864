import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import { orderDnaKitDescription } from "src/constants/api_config";
import styles from "./DnaKitForm.module.scss";

const cx = classNames.bind(styles);

export default function DnaKitDescription() {
  const { img } = orderDnaKitDescription || {};

  const { t }: { t: any } = useTranslation();

  const imgComponet = img ? (
    <img
      src={img}
      alt={t("DNA Kit image")}
      className={cx(styles["w-md-250p"], styles["mw-sm-100"], "d-block")}
    />
  ) : null;

  return (
    <>
      {imgComponet}
      <strong>{t("Description")}:</strong>
      <p>{t("Some text about the Zygene RGC SAliva Collection Device")}</p>
      <strong>{t("Contents")}:</strong>
      <ul className="pl-3">
        <li>{t("1 Saliva Collection Device")}</li>
        <li>{t("Instructions")}</li>
        <li>{t("Return Label")}</li>
      </ul>
    </>
  );
}
