import React from "react";
import Spinner from "./spinner";

export const ActionCardsPlaceholder = () => (
  <>
    {[1, 2, 3, 4].map((item, i) => (
      <div key={i} className="action-card-wrapper col-12 col-md-3 mb-2">
        <div className="action-card action-card-placeholder bg-secondary p-3 rounded clearfix">
          <div className="action-card-img">
            <div className="action-card-img-wrapper" />
          </div>
          <div className="action-card-description pb-0 pb-md-3">
            <p className="color-brand">
              <br />
            </p>
            <p className="h4 color-dark">
              <br />
            </p>
          </div>

          <div className="action-card-mobile-chevron">
            <div className="symbol-chevron" />
          </div>
        </div>
      </div>
    ))}
    <Spinner error="ActionCardsPlaceholder..." />
  </>
);

export default ActionCardsPlaceholder;
