import { ChangeEvent, useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import classnames from "classnames/bind";
import DropdownMenu, { DropdownMenuProps } from "./DropdownMenu";
import styles from "./Dropdown.module.scss";
import { DropdownItem } from "./DropdownItem";

const cx = classnames.bind(styles);

export type DropdownProps = {
  name?: string;
  label?: string;
  selectedValue?: string;
  placeholder?: string;
  items?: DropdownItem[];
  className?: string;
  onInputChange?: (arg0: string) => void;
  onItemSelect?: (arg0: DropdownItem, arg1: string) => void;
} & Pick<
  DropdownMenuProps,
  | "matchMode"
  | "filterInputLimit"
  | "filterInputDisplay"
  | "optionsMaxHeightCalculator"
>;

function DropdownComponent({
  name = "",
  label,
  selectedValue,
  placeholder,
  items = [],
  matchMode,
  filterInputLimit,
  filterInputDisplay,
  onItemSelect,
  className,
  onInputChange,
  optionsMaxHeightCalculator,
}: DropdownProps): JSX.Element {
  const dropdownName = name || `dropdown-${uuid()}`;
  const [selectedItem, setSelectedItem] = useState<DropdownItem | null>();
  const [dropDownMenuItems, setDropDownMenuItems] = useState(items);
  const [isVisible, setIsVisible] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setDropDownMenuItems(items);
  }, [items]);

  useEffect(() => {
    if (!selectedValue || !dropDownMenuItems || !dropDownMenuItems.length) {
      setSelectedItem(null);
      return;
    }
    const newItem = dropDownMenuItems.find(
      (item) => item.name === selectedValue,
    );
    if (newItem) {
      setSelectedItem(newItem);
    }
  }, [selectedValue, dropDownMenuItems]);

  const changeSelectedItem = (item?: DropdownItem | null) => {
    setSelectedItem(item);
    if (typeof onItemSelect === "function") {
      onItemSelect(item || { key: "", name: "" }, dropdownName);
    }
  };

  const handleItemClick = (item: DropdownItem) => {
    changeSelectedItem(item);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    onInputChange && onInputChange(e.target.value);

  const translatedPlaceHolder = t("Select Any Value Or Start Typing") as
    | string
    | undefined;
  const placeholderValue = placeholder || translatedPlaceHolder || placeholder;

  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <Dropdown
        className={cx(styles.dropdownBase, className)}
        drop="down"
        onToggle={setIsVisible}
      >
        <Dropdown.Toggle className={cx(styles.dropdownToggle)}>
          <Form.Control
            type="text"
            autoComplete="off"
            readOnly
            placeholder={placeholderValue}
            value={selectedItem?.name}
            name={dropdownName}
            tabIndex={-1}
          />
        </Dropdown.Toggle>
        <DropdownMenu
          matchMode={matchMode}
          filterInputLimit={filterInputLimit}
          filterInputDisplay={filterInputDisplay}
          onFilterInputChange={handleInputChange}
          optionsMaxHeightCalculator={optionsMaxHeightCalculator}
          items={dropDownMenuItems}
          selectedItems={selectedItem ? [selectedItem] : []}
          onItemClicked={handleItemClick}
          isVisible={isVisible}
        />
      </Dropdown>
    </Form.Group>
  );
}

export default DropdownComponent;
