import React from "react";

import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as IconHome } from "../assets/icons/nav-home.svg";
import { ReactComponent as IconProfile } from "../assets/icons/nav-profile.svg";
import { ReactComponent as IconSurveys } from "../assets/icons/nav-surveys.svg";
import { ReactComponent as IconEmail } from "../assets/icons/send-email.svg";
import LandingPageCharacter from "../assets/images/landing-page-character-1.png";
import LandingTick from "../assets/images/landing-tick.png";
import PAGES from "src/constants/pages";
import { LandingPageReferralBanner } from "./referral/landing-page-referral-banner";

import { CONFIG } from "src/constants/config";

export function LandingPage() {
  const { t } = useTranslation();

  return (
    <section className="landing-page">
      <div className="landing-header-wrapper">
        <div className="landing-content">
          <Trans i18nKey="Landing_page_title">
            <h1>Join the Population Program to help science.</h1>
          </Trans>
          <Trans i18nKey="Landing_page_description">
            <p>
              orem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex{" "}
            </p>
          </Trans>
          <div className="landing-header-links">
            <a href={PAGES.SIGNUP} className="landing-header-button">
              {t("Landing_page_sign_up")} <i className="icon-arrow-right" />
            </a>
            <a href="#ler" className="landing-header-link">
              {t("Landing_page_learn_more")}
            </a>
          </div>
        </div>
      </div>
      <div className="landing-body-wrapper">
        <div className="landing-content row">
          <div className="col-sm-6 col-md-3 col-xl-3">
            <div className="icon-box">
              <div className="nav-img">
                <IconProfile />
              </div>
            </div>
            <h3>{t("Landing_Learn_title")}</h3>
            <p>{t("Landing_Learn_description")}</p>
          </div>
          <div className="col-sm-6 col-md-3 col-xl-3">
            <div className="icon-box">
              <div className="nav-img">
                <IconHome />
              </div>
            </div>
            <h3>{t("Landing_Take_Survey_Title")}</h3>
            <p>{t("Landing_Take_Survey_description")}</p>
          </div>
          <div className="col-sm-6 col-md-3 col-xl-3">
            <div className="icon-box">
              <div className="nav-img">
                <IconSurveys />
              </div>
            </div>
            <h3>{t("Landing_Donate_Sample_Title")}</h3>
            <p>{t("Landing_Donate_Sample_body")}</p>
          </div>
          <div className="col-sm-6 col-md-3 col-xl-3">
            <div className="icon-box">
              <div className="nav-img">
                <IconSurveys />
              </div>
            </div>
            <h3>{t("Landing_Affect_Change_Title")}</h3>
            <p>{t("Landing_Affect_Change_Body")}</p>
          </div>
        </div>
      </div>
      <div className="landing-hero-wrapper">
        <div className="row">
          <div className="col-sm text-right">
            <img
              alt=""
              className="landing-page-character"
              src={LandingPageCharacter}
            />
          </div>
          <div className="col-sm">
            <Trans i18nKey="Landing_Topic_2_Title">
              <h3>
                <small>About</small> Population
              </h3>
              <h2>Learn about Population &amp; Genetics</h2>
            </Trans>
            <ul>
              <li>
                <img alt="" className="landing-tick" src={LandingTick} />{" "}
                {t("Landing_Topic_2_bullet_1")}
              </li>
              <li>
                <img alt="" className="landing-tick" src={LandingTick} />{" "}
                {t("Landing_Topic_2_bullet_2")}
              </li>
              <li>
                <img alt="" className="landing-tick" src={LandingTick} />{" "}
                {t("Landing_Topic_2_bullet_3")}
              </li>
              <li>
                <img alt="" className="landing-tick" src={LandingTick} />{" "}
                {t("Landing_Topic_2_bullet_4")}
              </li>
              <li>
                <img alt="" className="landing-tick" src={LandingTick} />{" "}
                {t("Landing_Topic_2_bullet_5")}
              </li>
              <li>
                <img alt="" className="landing-tick" src={LandingTick} />{" "}
                {t("Landing_Topic_2_bullet_6")}
              </li>
            </ul>
            <div className="landing-hero-links">
              <a
                href="#reg"
                className="landing-hero-button landing-button-more"
              >
                {t("Landing_Topic_2_button_1")}
              </a>
              <a href="#log" className="landing-hero-link">
                {t("Landing_Topic_2_button_2")}
                <i className="landing-video-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="landing-about-wrapper">
        <div className="landing-content text-center">
          <Trans i18nKey="Landing_About_Study_Title">
            <h2> About The Study</h2>
            <h4>Lorem ipsum dolor sit amet</h4>
          </Trans>
          <div className="row">
            <div className="col-12 col-sm text-left col-about">
              <Trans i18nKey="Landing_About_Study_Subtitle_1">
                <h3>Who are we?</h3>
                <h4>
                  orem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                  enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex
                </h4>
              </Trans>
            </div>
            <div className="col-12 offset-xl-2 col-sm text-left col-about">
              <Trans i18nKey="Landing_About_Study_Subtitle_2">
                <h3>Partnerships + G </h3>
                <h4>
                  orem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                  enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex
                </h4>
              </Trans>
            </div>
          </div>
        </div>
      </div>

      {CONFIG.REFERRAL && CONFIG.REFERRAL_LANDING_PAGE_BANNER ? (
        <div className="landing-referral-banner-wrapper">
          <div className="landing-content-banner text-left">
            <LandingPageReferralBanner />
          </div>
        </div>
      ) : null}
      <div className="landing-footer-wrapper">
        <div className="landing-content d-flex text-center">
          <div className="col-sm d-flex justify-content-center align-items-center">
            <div className="landing-footer-text text-left mb-sm-0">
              <Trans i18nKey="signupNewsletterAction">
                {t("Landing_Population_Newsletter_text")}
              </Trans>
            </div>
          </div>
          <div className="col-sm text-left d-flex align-items-center">
            <div className="landing-footer-input-wrapper">
              <input type="text" placeholder={t("Enter your email")} />
              <button className="landing-footer-input-btn">
                {t("Landing_Population_Newsletter_Send")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LandingPage;
