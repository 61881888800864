import React, { SyntheticEvent, useRef, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import classnames from "classnames/bind";
import { DropdownItem } from "src/components/core/Dropdown/DropdownItem";
import SelectableFieldComponent from "src/components/core/SelectableField/SelectableFieldComponent";
import DropdownMenu, {
  DropdownMenuProps,
} from "src/components/core/Dropdown/DropdownMenu";
import styles from "./MultiSelectDropdown.module.scss";

const cx = classnames.bind(styles);

export type MultiSelectDropdownProps = {
  name?: string;
  label?: string;
  selectedValues?: string[];
  placeholder?: string;
  items?: DropdownItem[];
  className?: string;
  onItemsChanged?: (arg0: DropdownItem[], arg1: string) => void;
  onItemClicked?: (
    e: React.MouseEvent,
    arg0: DropdownItem,
    arg1: string,
  ) => void;
} & Pick<
  DropdownMenuProps,
  | "matchMode"
  | "filterInputDisplay"
  | "filterInputLimit"
  | "optionsMaxHeightCalculator"
>;

function MultiSelectDropdownComponent({
  name,
  label = "",
  selectedValues = [],
  placeholder,
  items = [],
  matchMode,
  filterInputLimit,
  filterInputDisplay,
  onItemsChanged,
  onItemClicked,
  optionsMaxHeightCalculator,
  className,
}: MultiSelectDropdownProps): React.ReactElement {
  const dropdownName = name || `dropdown-${uuid()}`;
  const [show, setShow] = useState(false);

  const { t } = useTranslation();

  const selectedItems =
    items?.filter(({ key }) => selectedValues?.includes(key)) || [];

  const dropdownToggleEl = useRef<HTMLButtonElement>(null);

  const handleItemClick = (item: DropdownItem, e: React.MouseEvent) => {
    const { key } = item;
    const isActive = selectedItems.some(
      ({ key: selectedKey }) => selectedKey === key,
    );

    const newItems = isActive
      ? selectedItems.filter(({ key: selectedKey }) => selectedKey !== key)
      : [...selectedItems, item];

    if (typeof onItemClicked === "function") {
      onItemClicked(e, item, dropdownName);
    }

    if (typeof onItemsChanged === "function") {
      onItemsChanged(newItems, dropdownName);
    }
  };

  const handleToggle = (
    isOpen: boolean,
    event: SyntheticEvent<Dropdown, Event>,
    metadata: {
      source: "select" | "click" | "rootClose" | "keydown";
    },
  ) => {
    if (metadata.source !== "select") {
      setShow(isOpen);
    }
  };

  const translatedPlaceHolder = t("Select Any Value Or Start Typing");
  const placeholderValue =
    typeof translatedPlaceHolder === "string"
      ? translatedPlaceHolder
      : placeholder;

  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <Dropdown
        className={cx(styles.dropdownBase, className)}
        onToggle={handleToggle}
        show={show}
        drop="down"
      >
        <Dropdown.Toggle
          className={cx(styles.dropdownToggle)}
          ref={dropdownToggleEl}
        >
          <Form.Control
            type="text"
            placeholder={placeholderValue}
            readOnly
            name={dropdownName}
            tabIndex={-1}
          />
        </Dropdown.Toggle>
        <DropdownMenu
          filterInputDisplay={filterInputDisplay}
          filterInputLimit={filterInputLimit}
          isVisible={show}
          items={items}
          selectedItems={selectedItems}
          matchMode={matchMode}
          onItemClicked={handleItemClick}
          optionsMaxHeightCalculator={optionsMaxHeightCalculator}
        />
      </Dropdown>

      <div className={styles.selectedItems}>
        {selectedItems.map((item) => {
          const { name: itemName, key } = item;
          return (
            <SelectableFieldComponent
              key={key}
              label={itemName}
              value={item}
              onOptionRemoveClick={(e, selectedItem) =>
                handleItemClick(selectedItem, e)
              }
            />
          );
        })}
      </div>
    </Form.Group>
  );
}

export default MultiSelectDropdownComponent;
