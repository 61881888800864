import { Dropdown } from "react-bootstrap";
import classnames from "classnames/bind";
import React from "react";
import { DropdownItem } from "./DropdownItem";
import styles from "./Dropdown.module.scss";

const cx = classnames.bind(styles);

export type MatchedPart = {
  text: string;
  highlighted: boolean;
  internalKey: string;
};

export type MatchedDropdownItem = {
  key: string;
  name: string;
  matchedParts: MatchedPart[];
};

export type DropdownMenuItemProps = {
  item: MatchedDropdownItem;
  highlighted?: boolean;
  selected?: boolean;
  onItemClicked?: (item: DropdownItem, e: React.MouseEvent) => void;
};

export default function DropdownMenuITem({
  item,
  highlighted: isItemHighlighted,
  selected,
  onItemClicked,
}: DropdownMenuItemProps): React.ReactElement {
  const { key, name, matchedParts } = item;

  return (
    <Dropdown.Item
      // TODO: fix types
      // @ts-ignore
      onClick={(e: React.MouseEvent) =>
        onItemClicked && onItemClicked({ key, name }, e)
      }
      active={selected}
      className={cx(styles.dropDownItem, {
        [styles.highlighted]: isItemHighlighted,
        [styles.dropDownItemActive]: selected,
      })}
    >
      {matchedParts.map(
        ({ internalKey, highlighted, text }): React.ReactChild =>
          highlighted ? <strong key={internalKey}>{text}</strong> : text,
      )}
    </Dropdown.Item>
  );
}
