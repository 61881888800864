import React from "react";
import { connect } from "react-redux";
import HtmlContentComponent from "src/components/core/HtmlContent/HtmlContentComponent";

export const ConsentSubcompText = (props) => (
  <div className="consent-component consent-component-text mb-3">
    {props.text.title ? (
      <h2 className="label">
        {props.text.title[props.lang] || props.text.title.en}
      </h2>
    ) : null}
    <HtmlContentComponent
      tag={"p"}
      markup={props.text.text[props.lang] || props.text.text.en}
    />
  </div>
);

const mapStateToProps = (state) => ({
  lang: state.app.language,
});

export default connect(mapStateToProps)(ConsentSubcompText);
