import { Form } from "react-bootstrap";

import styles from "./simple-otp-input.module.css";

export default function SimpleOTPInput({ token, handleChange, firstFieldRef }) {
  const ourToken = token.join("");
  const ourHandleChange = (e) => {
    handleChange(e.target.value.split(""));
  };
  return (
    <div className={styles.wrapper}>
      <Form.Control
        className={styles.input}
        type="text"
        maxLength={6}
        value={ourToken}
        onChange={ourHandleChange}
        aria-required="true"
        aria-label="6 digit code"
        autoFocus={true}
        autoComplete="one-time-code"
        ref={firstFieldRef}
      />
    </div>
  );
}
