import { API_URL } from "../constants/api";
import { utils_api } from "../utils/utils_api";

export const api_otp = {
  post_otp(success, fail, data) {
    return utils_api.auth_post(API_URL.OTP_POST, data, success, fail);
  },
  get_otp_phone(success, fail, method, phone) {
    const params = `?method=${method}&phone=${phone}`;
    return utils_api.auth_get(API_URL.OTP_GET + params, success, fail);
  },
  get_otp_email(success, fail) {
    const params = "?otp_type=emailverf";
    return utils_api.auth_get(API_URL.OTP_GET + params, success, fail);
  },
  get_otp_skip_email(success, fail) {
    const params = "?otp_type=skip_emailverf";
    return utils_api.auth_get(API_URL.OTP_GET + params, success, fail);
  },
};
