export const WORKFLOW = {
  SET_EMAIL: "set_email",
  CONFIRM_EMAIL: "confirm_email",
  VERIFICATION: "verification",
  VALIDATION: "validation",
  CATEGORIZATION: "categorization",
  SET_PASSWORD: "set_password",
  CONSENT: "consent",
  POLICIES: "policies",
  TEXT_MEDIA: "text_media",
  EMR: "emr",
  SURVEY: "survey",
  SUBWORKFLOW: "subworkflow",
  ORDER_DNA_KIT: "order_dna_kit",
};

export const WORKFLOW_TYPE = {
  DASHBOARD: "dashboard",
  ENROLLMENT: "enrollment",
};

export const WORKFLOW_VALUE = {
  COMPLETE: "complete",
  DONE_FOR_NOW: "done_for_now",
};
