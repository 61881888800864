import { useEffect } from "react";
import { utils } from "src/utils/utils_general";

const isSafari = utils.get_browser() === "safari";

function handleTab(e: KeyboardEvent) {
  if (!isSafari || e.key !== "Tab") {
    return;
  }
  const { activeElement } = document;

  // Get all visible, focusable elements.
  const allFocusableElements = Array.from(
    document.querySelectorAll<HTMLElement>(
      "input, button, select, textarea, [role=button], a",
    ),
  ).filter(({ offsetWidth, offsetHeight, tabIndex }) => {
    const isVisible = offsetWidth !== 0 && offsetHeight !== 0;
    const isInTheTabsList = tabIndex >= 0;

    return isVisible && isInTheTabsList;
  });

  if (allFocusableElements.length === 0) {
    return;
  }

  const currentIndex =
    activeElement === null
      ? -1
      : Array.from(allFocusableElements).indexOf(activeElement as HTMLElement);

  const nextIndex = e.shiftKey ? currentIndex - 1 : currentIndex + 1;

  // If we get past the controls list, let the browser do its job.
  if (nextIndex < 0 || nextIndex >= allFocusableElements.length) {
    return;
  }

  // Focus the next available element.
  e.preventDefault();
  (activeElement as HTMLElement)?.blur();
  allFocusableElements[nextIndex].focus();
}

export default function useSafariTabsFix(): void {
  useEffect(() => {
    document.addEventListener("keydown", handleTab);

    return () => document.removeEventListener("keydown", handleTab);
  }, []);
}
