export const OTP_METHODS = {
  SMS: "sms",
  CALL: "call",
  EMAIL: "email",
};

export const OTP_LOGIN_FLOWS = {
  PASSWORD: "password",
  MAGIC_LINK: "magic_link",
};
