import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
/* eslint-disable camelcase */
import { get_emr_workflow } from "src/actions/emrAction";
import { utils_workflow } from "src/utils/utils_workflow";
import {
  get_user_attributes_get,
  post_user_attributes_post,
} from "src/actions/userAction";
import { utils_registration_flow } from "src/utils/utils_registration_flow";
import PAGES from "src/constants/pages";
import WorkflowLayout from "./workflow_layout";
import EMR from "./emr";
/* eslint-enable camelcase */

class EMRConnectWorkflow extends React.Component {
  constructor() {
    super();
    this.state = {
      body: null,
      id: null,
      step: null,
      errors: null,
      updated: false,
    };
  }

  async componentDidMount() {
    // get emr workflow and check entry attributes
    await Promise.all([
      this.props.get_emr_workflow(),
      this.props.get_user_attributes_get(),
    ]);
    const emrComponent =
      this.props.workflow.find((c) => c.component === "emr") || {};
    const { entry_attribute = [] } = emrComponent;
    const attributeErrors = utils_registration_flow.checkAttrCompleted(
      entry_attribute,
      this.props.userAttribute,
    );
    if (attributeErrors && attributeErrors.length) {
      // redirect back to dashboard if any entry attributes dont match
      this.props.history.push(PAGES.DASHBOARD);
    }
  }

  async onFormComplete() {
    // update user attributes with workflow exit attributes
    const emrComponent =
      this.props.workflow.find((c) => c.component === "emr") || {};
    const { exit_attribute = [] } = emrComponent;
    const exitAttributes = utils_workflow.getPostAttributes(
      exit_attribute.filter((attr) => attr !== "*"),
    );

    try {
      await Promise.all(
        Object.entries(exitAttributes).map(([attr, value]) =>
          this.props.post_user_attributes_post(attr, value),
        ),
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.props.history.push(PAGES.DASHBOARD);
    }
  }

  renderContent() {
    return (
      <div className="workflow-component-wrapper bg-emr p-4 rounded">
        <EMR onComplete={() => this.onFormComplete()} />
      </div>
    );
  }

  render() {
    if (!this.props.workflow) return null;
    const aside = {
      name: this.props.t("Connect EMR"),
      details: {
        subtitle: this.props.t("Add or re-connect to EMR providers"),
        allow_pause: true,
      },
    };
    return (
      <WorkflowLayout
        flowName="EMR"
        aside={aside}
        mainSection={this.renderContent()}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  workflow: state.emrConnections.workflow,
  userAttribute: state.userAttribute,
  ...ownProps,
});

export default withRouter(
  connect(mapStateToProps, {
    get_emr_workflow,
    get_user_attributes_get,
    post_user_attributes_post,
  })(withTranslation(["workflow", "texts"])(EMRConnectWorkflow)),
);
