import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Trans, useTranslation, withTranslation } from "react-i18next";
import { get_survey_list_get } from "../../actions/surveyAction";
import SurveyList from "./survey_list";
import { utils_survey } from "../../utils/utils_survey";
import PageLoader from "../global/page-loader";
import AncestryMessage from "../global/ancestry_message";
import { utils_workflow } from "../../utils/utils_workflow";
import { workflow_get } from "../../actions/workflowAction";
import PAGES from "src/constants/pages";
import classNames from "classnames";

const ALL = "all";
const COMPLETED = "completed";

function SurveySummary({ show, onTabClicked }) {
  const { t } = useTranslation(["texts", "content"]);
  return (
    <div className="surveys-tab text-center">
      <button
        className={classNames({ active: show === ALL })}
        tabIndex={show === ALL ? -1 : 0}
        onClick={() => onTabClicked(ALL)}
      >
        <span>{t("Total Surveys")}</span>
      </button>
      <button
        className={classNames({ active: show === COMPLETED })}
        tabIndex={show === COMPLETED ? -1 : 0}
        onClick={() => onTabClicked(COMPLETED)}
      >
        <span>
          <Trans i18nKey="completed_surveys">
            Completed <span className="desktop-only">Surveys</span>
          </Trans>
        </span>
      </button>
    </div>
  );
}

class Survey extends React.Component {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      errors: {},
      surveys: null,
      completed: null,
      uncompleted: null,
      show: ALL,
      showSurveys: null,
      loader: true,
      completePage: false,
    };
  }

  componentDidMount() {
    // we need this in order to lock the user out of surveys when there is an enforced survey...
    if (!this.props.workflow) {
      this.getDashboardWorkflow();
    } else {
      utils_workflow.checkDashboardWorkflow(
        this.props.workflow,
        this.props.userAttribute,
        this.props.history.push,
        PAGES.SURVEYS,
      );
    }

    this._isMounted = true;
    this.setState({ loader: true });
    this.getSurveys();
    if (this.state.loader) {
      setTimeout(() => {
        this.setState({ loader: false });
      }, 8000);
    }
  }

  getDashboardWorkflow() {
    this.props.workflow_get().then((resp) => {
      utils_workflow.checkDashboardWorkflow(
        resp,
        this.props.userAttribute,
        this.props.history.push,
        PAGES.SURVEYS,
      );
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {};
  }

  getSurveys() {
    return this.props
      .get_survey_list_get(true)
      .then((surveys) => {
        if (this._isMounted) {
          const completed = utils_survey.getCompletedSurveys(surveys);
          const uncompleted = utils_survey.getUncompletedSurveys(surveys);
          const all = [...uncompleted, ...completed];
          this.setState({
            surveys,
            completed,
            uncompleted,
            showSurveys: all,
            loader: false,
          });
        } else {
          return null;
        }
      })
      .catch((error) =>
        this._isMounted
          ? this.setState({ errors: { api: error }, loader: false })
          : null,
      );
  }

  toggleSurveys(show) {
    if (show === COMPLETED) {
      this.setState({
        showSurveys: this.state.completed,
        show,
        completePage: true,
      });
    } else if (show === ALL) {
      this.setState({
        showSurveys: [...this.state.uncompleted, ...this.state.completed],
        show,
        completePage: false,
      });
    }
  }

  render() {
    const { t } = this.props;
    const { surveys, show, showSurveys, completePage, loader, errors } =
      this.state;

    if (errors.api) {
      return (
        <section className="surveys container text-center">
          {t("Surveys unavailable")}
        </section>
      );
    }

    return (
      <section className="surveys container">
        <div className="row">
          <aside className="col-12 col-xl-2 left-section">
            <h2 className="mb-3 side-heading">{t("Take a survey!")}</h2>
            <div className="section-component">
              <Trans i18nKey="content:copy_page_description_surveys">
                <p>
                  It may seem like a small ask, but your honest responses to
                  these surveys can play a big part in advancing precision
                  medicine.{" "}
                </p>
                <br />
                <p>
                  You can take surveys multiple times and track your progress as
                  you go.
                </p>
              </Trans>
            </div>
          </aside>
          <div className="col-12 col-xl-8 center-section">
            <div className="surveys-page-message desktop-only">
              <AncestryMessage />
            </div>

            {surveys && (
              <SurveySummary
                show={show}
                onTabClicked={(selectedTab) => this.toggleSurveys(selectedTab)}
              />
            )}

            <SurveyList showSurveys={showSurveys} completePage={completePage} />
          </div>

          <aside className="col-12 col-xl-2 right-section" />
        </div>

        <PageLoader loader={loader} loaderMessage={t("loading surveys")} />
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
  workflow: state.workflow.workflow,
  workflowIsLoading: state.workflow.workflowIsLoading,
});

export default withRouter(
  connect(mapStateToProps, { get_survey_list_get, workflow_get })(
    withTranslation(["texts", "content"])(Survey),
  ),
);
