import { inject, injectable } from "inversify";
import apiFetch from "src/utils/apiWrapper";
import { IBaseService } from "./BaseService";
import { API_URL } from "../constants/api";
import type { IUserService } from "./UserService";
import "reflect-metadata";
import { UserData } from "./types/UserTypes";
import {
  Actions,
  FetchConsentActions,
  FileDownloadToken,
  FinalRequestData,
  Signature,
} from "./types/ConsentTypes";

type ResponseSignature = {
  signature_image: string;
  first_name?: string;
  last_name?: string;
  signature_type: string;
};

@injectable()
class ConsentService implements IBaseService {
  @inject("userService") private userService!: IUserService;

  private static prepSignaturesData(
    userData: UserData | null,
    signatures?: Signature[],
  ): ResponseSignature[] {
    if (!signatures?.length) {
      return [];
    }

    const sigIndexCount = ["primary", "secondary", "tertiary"];

    return signatures.map(
      (
        { sig, firstName, lastName, signatureType }: Signature,
        index: number,
      ) => ({
        signature_image: sig,
        first_name: firstName || userData?.first_name,
        last_name: lastName,
        signature_type: signatureType || sigIndexCount[index],
      }),
    );
  }

  private async doFinalRequest(
    endpoint: string,
    responseData: object,
    actions: any,
  ): Promise<any> {
    const { doSetConsentLoading } = actions;
    doSetConsentLoading(true);

    try {
      const result = await apiFetch({
        method: "POST",
        endpoint,
        body: responseData,
      });
      return result;
    } catch (e) {
      throw new Error(String(e));
    } finally {
      doSetConsentLoading(false);
    }
  }

  finalRequest(data: FinalRequestData, actions: Actions): Promise<any> {
    const {
      user_response: resp,
      signature: consentSignature,
      consentCode,
    } = data;

    const userData = this.userService.getCurrentUser();

    const signatures = ConsentService.prepSignaturesData(
      userData,
      consentSignature,
    );

    const responseData = {
      consent_code: consentCode,
      resp,
      signatures,
    };

    const endpoint = API_URL.CONSENT_POST;

    return this.doFinalRequest(endpoint, responseData, actions);
  }

  private async doFetchDataAndDispatch(
    endpoint: string,
    { doSetConsentLoading, doSetConsent }: FetchConsentActions,
  ) {
    doSetConsentLoading(true);
    const result = await apiFetch({ endpoint });
    doSetConsent(result?.data);
    doSetConsentLoading(false);
    return result?.data;
  }

  fetchData(code: string, fetchActions: FetchConsentActions): Promise<any> {
    const endpoint = `${API_URL.CONSENT_GET}?consent_code=${code}`;
    return this.doFetchDataAndDispatch(endpoint, fetchActions);
  }

  private async doGetFile(
    endpoint: string,
    actions: Actions,
  ): Promise<FileDownloadToken> {
    const { doSetConsentLoading } = actions;
    try {
      doSetConsentLoading(true);
      const result = await apiFetch({ method: "GET", endpoint });
      return result?.data;
    } catch (e) {
      throw new Error(String(e));
    } finally {
      doSetConsentLoading(false);
    }
  }

  getFile(code: string, actions: Actions): Promise<FileDownloadToken> {
    const endpoint = `${API_URL.CONSENT_FILE_GET}?consent_code=${code}`;
    return this.doGetFile(endpoint, actions);
  }

  getAllFilesAsZip(actions: Actions): Promise<FileDownloadToken> {
    const endpoint = `${API_URL.CONSENT_FILE_GET}`;
    return this.doGetFile(endpoint, actions);
  }
}

export default ConsentService;
