import { TOKEN_TYPE } from "src/constants/tokenType";
import { API_URL } from "../constants/api";
import { utils_api } from "../utils/utils_api";

export const api_login = {
  get_login(success, fail) {
    return utils_api.auth_get(API_URL.LOGIN_GET, success, fail);
  },
  login(success, fail, data) {
    return utils_api.unauth_post(API_URL.LOGIN_POST, data, success, fail);
  },
  login_skip_emailverif(success, fail, otp_id, token) {
    const data = { otp_id, token, skip_emailverf: true };
    return utils_api.unauth_post(API_URL.LOGIN_EMAILVERIF, data, success, fail);
  },
  login_emailverif(success, fail, otp_id, token) {
    const data = { otp_id, token, skip_emailverf: false };
    return utils_api.unauth_post(API_URL.LOGIN_EMAILVERIF, data, success, fail);
  },
  login_magic_link_request(success, fail, email) {
    const data = { email };
    return utils_api.unauth_post(
      API_URL.LOGIN_MAGIC_LINK_REQUEST,
      data,
      success,
      fail,
    );
  },
  login_magic_link_validation(success, fail, token, email, otp) {
    const data = token ? { token } : { verification_token: otp, email };
    return utils_api.unauth_post(
      API_URL.LOGIN_MAGIC_LINK_VALIDATION,
      data,
      success,
      fail,
    );
  },
  login_mfa_otp_request(success, payload, fail) {
    return utils_api.auth_post(
      API_URL.LOGIN_MFA_OTP_REQUEST,
      payload,
      success,
      fail,
    );
  },
  login_mfa_otp_validation(success, fail, otpId, otp) {
    const data = { otp_id: otpId, token: otp };
    return utils_api.auth_post(
      API_URL.LOGIN_MFA_OTP_VALIDATION,
      data,
      success,
      fail,
    );
  },
  login_magic_link(success, fail, token, verification_token) {
    const data = { token, verification_token };
    return utils_api.unauth_post(API_URL.LOGIN_MAGIC_LINK, data, success, fail);
  },
};
