import { inject, injectable, interfaces } from "inversify";
import { API_URL } from "src/constants/api";
import sampleWorkflows from "src/data/sampleWorkflows";
import {
  doSetComponent,
  doSetComponentIsLoading,
  doSetStepError,
  doSetWorkflow,
  doSetWorkflowsIsLoading,
} from "src/store/Workflow/workflowAction";
import { ApiFetchArguments, RequestError } from "src/utils/apiWrapper";
import * as IStore from "./types/IStore";
import {
  ComponentIdType,
  GenericWorkflow,
  GenericWorkflowStep,
  WorkflowCodeType,
} from "./types/WorkflowTypes";

export type ProgresResponse = {
  status: boolean;
  message?: string;
};
export interface IWorkflowService {
  getWorkflow: (
    workflowCode: WorkflowCodeType,
  ) => Promise<GenericWorkflow | null>;
  getNextStep: (
    workflowCode: WorkflowCodeType | null,
  ) => Promise<GenericWorkflowStep | null>;
  onComponentComplete: (
    workflowCode: WorkflowCodeType,
    componentId: ComponentIdType,
  ) => Promise<ProgresResponse | null>;

  // TODO: remove any
  finalRequest(data: any, workflowCode: WorkflowCodeType): Promise<any>;
  completeWorkflow(workflowCode: WorkflowCodeType): Promise<any>;

  skipWorkflow(workflowCode: WorkflowCodeType): Promise<any>;
}

@injectable()
abstract class WorkflowServiceBase implements IWorkflowService {
  @inject("store")
  public store!: IStore.IStore;

  abstract doFinalRequest(
    data: any,
    workflowCode: WorkflowCodeType,
  ): Promise<any>;

  async finalRequest(data: any, workflowCode: WorkflowCodeType) {
    const result = await this.doFinalRequest(data, workflowCode);
    return result;
  }

  completeWorkflow(workflowCode: WorkflowCodeType) {
    return this.finalRequest({}, workflowCode);
  }

  abstract doGetWorkflow(workflowCode: WorkflowCodeType): Promise<any>;

  async getWorkflow(workflowCode: WorkflowCodeType) {
    this.store.dispatch(doSetWorkflowsIsLoading());

    let result = null;

    try {
      result = await this.doGetWorkflow(workflowCode);
    } catch (e) {
      console.error(e);
    }

    this.store.dispatch(doSetWorkflow(workflowCode, result));

    return result;
  }

  abstract doGetNextStep(workflowCode: WorkflowCodeType | null): Promise<any>;

  async getNextStep(workflowCode: WorkflowCodeType | null) {
    if (!workflowCode) {
      return null;
    }

    this.store.dispatch(doSetComponentIsLoading());

    let result = null;

    try {
      result = await this.doGetNextStep(workflowCode);
    } catch (e) {
      console.error(e);
    }

    this.store.dispatch(doSetComponent(result));

    return result;
  }

  abstract doSkipWorkflow(workflowCode: WorkflowCodeType): Promise<any>;

  skipWorkflow = async (workflowCode: WorkflowCodeType) => {
    if (!workflowCode) {
      throw new Error("No workflow code provided");
    }

    let result = null;
    try {
      result = await this.doSkipWorkflow(workflowCode);
    } catch (e) {
      console.error(e);
    }
    return result;
  };

  abstract doOnComponentComplete(
    workflowCode: WorkflowCodeType,
    componentId: ComponentIdType,
  ): Promise<any>;

  async onComponentComplete(
    workflowCode: WorkflowCodeType,
    componentId: ComponentIdType,
  ) {
    if (!workflowCode || !componentId) {
      return null;
    }

    this.store.dispatch(doSetComponentIsLoading());

    try {
      return await this.doOnComponentComplete(workflowCode, componentId);
    } catch (e) {
      console.error(e);

      const message = (e as RequestError).response?.data?.msg || String(e);

      this.store.dispatch(doSetStepError(message));
    }

    return null;
  }
}

@injectable()
class WorkflowService extends WorkflowServiceBase {
  @inject("apiFetch")
  private apiFetch!: (fetchArguments: ApiFetchArguments) => any;

  doGetWorkflow(workflowCode: string): Promise<any> {
    return this.apiFetch({
      endpoint: `${API_URL.WORKFLOW_GET}`,
      uriParams: { code: workflowCode },
    });
  }

  doGetNextStep(workflowCode: string | null): Promise<any> {
    const endpoint = API_URL.WORKFLOW_GET_NEXT_COMPONENT;
    return this.apiFetch({
      endpoint,
      method: "POST",
      body: { workflow_code: workflowCode },
    });
  }

  doSkipWorkflow(workflowCode: string): Promise<any> {
    const endpoint = `${API_URL.WORKFLOW_SKIP}`;
    return this.apiFetch({
      method: "POST",
      endpoint,
      body: { workflow_code: workflowCode },
    });
  }

  doOnComponentComplete(
    workflowCode: string,
    componentId: string,
  ): Promise<any> {
    return this.apiFetch({
      endpoint: `${API_URL.WORKFLOW_PROGRESS_POST}`,
      method: "POST",
      body: { workflow_code: workflowCode, component_id: componentId },
    });
  }

  // eslint-disable-next-line no-unused-vars
  async doFinalRequest(data: any, workflowCode: WorkflowCodeType) {
    // this.store.dispatch(doSetWorkflowsIsCompleted());

    let result = null;
    try {
      result = await this.apiFetch({
        method: "POST",
        endpoint: `${API_URL.WORKFLOW_COMPLETE}`,
        body: { workflow_code: workflowCode },
      });
    } catch (e) {
      console.error(e);
    }
    return result;
  }
}

class WorkflowServiceWithFakes implements IWorkflowService {
  private service: IWorkflowService;
  private fakeWorkflows: Record<string, GenericWorkflow>;
  private store: IStore.IStore;

  private fakeWorkflowsIndexes: Record<string, number>;

  constructor(
    service: IWorkflowService,
    fakeWorkflows: Record<string, GenericWorkflow>,
    store: IStore.IStore,
  ) {
    this.service = service;
    this.fakeWorkflows = fakeWorkflows;
    this.store = store;
    this.fakeWorkflowsIndexes = Object.keys(this.fakeWorkflows).reduce(
      (current, key) => ({ ...current, [key]: 0 }),
      {},
    );
  }

  getWorkflow(workflowCode: WorkflowCodeType) {
    if (this.fakeWorkflows[workflowCode] !== undefined) {
      this.store.dispatch(doSetWorkflowsIsLoading());
      const data = this.fakeWorkflows[workflowCode];
      this.store.dispatch(doSetWorkflow(workflowCode, { success: true, data }));
      return Promise.resolve(data);
    }

    return this.service.getWorkflow(workflowCode);
  }

  getNextStep(
    workflowCode: string | null,
  ): Promise<GenericWorkflowStep | null> {
    if (
      workflowCode !== null &&
      this.fakeWorkflows[workflowCode] !== undefined
    ) {
      this.store.dispatch(doSetComponentIsLoading());

      const workflow = this.fakeWorkflows[workflowCode];
      const stepIndex = this.fakeWorkflowsIndexes[workflowCode];
      const next_component =
        stepIndex < workflow.length ? workflow[stepIndex] : null;

      this.store.dispatch(
        doSetComponent({ success: true, data: { next_component } }),
      );

      return Promise.resolve(next_component);
    }

    return this.service.getNextStep(workflowCode);
  }
  onComponentComplete(
    workflowCode: string,
    componentId: string,
  ): Promise<ProgresResponse | null> {
    if (this.fakeWorkflows[workflowCode] !== undefined) {
      this.fakeWorkflowsIndexes[workflowCode]++;
      return Promise.resolve({ status: true });
    }
    return this.service.onComponentComplete(workflowCode, componentId);
  }
  finalRequest(data: any, workflowCode: string): Promise<any> {
    return this.service.finalRequest(data, workflowCode);
  }
  completeWorkflow(workflowCode: string): Promise<any> {
    return this.service.completeWorkflow(workflowCode);
  }
  skipWorkflow(workflowCode: string): Promise<any> {
    return this.service.skipWorkflow(workflowCode);
  }
}

export function withFakeWorkflows(
  context: interfaces.Context,
  service: IWorkflowService,
): IWorkflowService {
  const actualStore = context.container.get<IStore.IStore>("store");

  return new WorkflowServiceWithFakes(service, sampleWorkflows, actualStore);
}

export default WorkflowService;
