/* eslint-disable camelcase */
/**
 * @file components/password/rest_password_email.js
 */

import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import PAGES from "src/constants/pages";
import { withTranslation } from "react-i18next";
import { utils } from "src/utils/utils_general";
import { utils_validation } from "src/utils/utils_validation";
import { rgc_request_password_reset_app } from "src/utils/validationrules_api_app";

import {
  RESET_PWD_ERROR_DISPLAY,
  UNEXPECTED_ERROR,
  RESPONSE_CODE,
} from "src/constants/errors";
import { login_post } from "src/actions/loginAction";
import { send_reset_password_email_post } from "src/actions/passwordAction";

class ResetPasswordSendEmail extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      errors: {},
      loader: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.emailField = React.createRef();
  }

  handleSubmit(e) {
    e.preventDefault();

    const { email } = this.state;

    this.setState({ errors: {} });
    const data = {
      email,
    };

    const errors = utils_validation.validate(
      rgc_request_password_reset_app,
      data,
    );
    if (!utils.is_obj_empty(errors)) {
      this.setState({ errors }, () => {
        this.focusField();
      });
    } else {
      this.sendEmail(data);
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });

    const { errors } = this.state;

    if (!utils.is_obj_empty(errors)) {
      this.setState({ errors: {} });
    }
  }

  handleFocus = (event) => event.target.select();

  focusField() {
    const { errors } = this.state;

    if (errors) {
      this.emailField.current.focus();
    }
  }

  sendEmail(data) {
    const {
      history,
      send_reset_password_email_post: sendResetPaswordEmailPost,
    } = this.props;

    this.setState({ loader: true });
    const success = () => {
      this.setState({ loader: false });
      const { email } = this.state;
      history.push(PAGES.RESET_PASSWORD_SEND_EMAIL_COMPLETE, { email });
    };
    const fail = ({ response }) => {
      const { status } = response;
      this.focusField();
      if (
        status === RESPONSE_CODE["401_unauthorized"] ||
        status === RESPONSE_CODE["410_user_not_found"]
      ) {
        this.setState({
          errors: { system: RESET_PWD_ERROR_DISPLAY.USER_NOT_FOUND },
        });
      } else if (status === RESPONSE_CODE["490_max_reached"]) {
        this.setState({
          errors: { system: RESET_PWD_ERROR_DISPLAY.MAX_REACHED },
        });
      } else {
        this.setState({ errors: { system: UNEXPECTED_ERROR } });
      }
      this.setState({ loader: false });
    };
    sendResetPaswordEmailPost(data)
      .then((response) => success(response))
      .catch((error) => fail(error));
  }

  renderRequestResetPasswordForm() {
    const { t } = this.props;
    const { errors, loader } = this.state;
    const enableSubmit = !loader && utils.is_obj_empty(errors);
    return (
      <Form
        noValidate
        onSubmit={(e) =>
          enableSubmit ? this.handleSubmit(e) : e.preventDefault()
        }
      >
        <Form.Group controlId="formForgetPasswordEmail">
          <Form.Label>{t("Email address")}</Form.Label>
          <Form.Control
            onChange={(e) => this.handleChange(e)}
            name="email"
            type="text"
            aria-required="true"
            ref={this.emailField}
            onFocus={this.handleFocus}
          />
          <Form.Text className="text-danger form-error" aria-live="polite">
            {errors.email ? t("Please provide a valid email.") : <br />}
          </Form.Text>
        </Form.Group>

        <Button
          variant={enableSubmit ? "primary" : "disable"}
          className={enableSubmit ? "" : "btn-disable"}
          type="submit"
          block
        >
          {t("Send reset email")}
        </Button>

        <div className="text-center">
          <Form.Text className="text-danger form-error" aria-live="polite">
            {t(errors.system) || <br />}
          </Form.Text>
        </div>
      </Form>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <section className="reset-password">
        <div className="unauth-body-wrapper">
          <h1>{t("Forgot Password")}</h1>
          <div className="mb-3">
            {/* eslint-disable-next-line max-len */}
            {t(
              "Enter the email address associated with your account and we'll send you an email with instructions and a link to create a new password.",
            )}
          </div>
          {this.renderRequestResetPasswordForm()}
        </div>
        <div className="mt-3 text-center">
          <Link to={PAGES.LOGIN}>
            <u>{t("Log in to your account")}</u>
          </Link>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withTranslation()(
  withRouter(
    connect(mapStateToProps, { login_post, send_reset_password_email_post })(
      ResetPasswordSendEmail,
    ),
  ),
);
