/*
 * src/store.js
 * No initialState
 */
import { createStore, applyMiddleware, compose as reduxCompose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";

const devtoolsCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const DEVTOOLS_OPTIONS = {
  trace: true,
};

const compose =
  typeof devtoolsCompose === "function"
    ? devtoolsCompose(DEVTOOLS_OPTIONS)
    : reduxCompose;
const enhancer = compose(applyMiddleware(thunk));

export default function configureStore() {
  return createStore(rootReducer, enhancer);
}
