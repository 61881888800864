import { injectable } from "inversify";
import { ComponentRef, IBaseService } from "./BaseService";

@injectable()
class AddressValidationService implements IBaseService {
  finalRequest(data: any, componentRef?: any) {
    console.log("Here will be mine! AddressValidationService");
    console.log(componentRef as ComponentRef);
    console.log(data);
    //  post_user_attribute(success, fail, 'ehr_in_SA', 'San-francisco');
  }
  fetchData?: ((arg1: any, arg2?: any) => any) | undefined;
}

export default AddressValidationService;
