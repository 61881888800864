import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from "../utils/utils_general";
import { api_logout } from "../api/api_logout";
import { utils_api } from "../utils/utils_api.js";

export function logout_get() {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, "logout_get");
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      utils.clear_local_storage();
      dispatch({
        type: REDUX_ACTION.LOGOUT_GET,
        data: null,
      });
      return null;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_logout.logout(success, fail);
  };
}
