export const CONSENT_COMPONENT = {
  FORM: "form",
  ESIGN: "esign",
  CONFIRM: "esign_confirm",
  FORM_QN_SOLO: "form_qn_solo",
  FORM_QN_KNOWLEDGECHECK: "form_qn_knowledgecheck",

  TEXT: "text",
  COLLAPSIBLE_SECTION: "collapsible_section",
  LINK: "link",
  QN_RADIO: "qn_radio",
  QN_LIST: "qn_list",
  PDF: "pdf",
  VIDEO: "video",
};
