export const TOKEN_TYPE = {
  REGISTRATION: "registration",
  RESET_PASSWORD: "password_reset",
  SESSION: "session",
  PASSWORD: "password",
  LOGOUT: "logout",
  OTP: "otp",
  REGISTRATION_SELF: "registration_self",
  REGISTRATION_SUBACCOUNT: "registration_subaccount",
  REGISTRATION_ADMIN: "registration_admin",
  REGISTRATION_REFERRAL: "registration_referral",
  REGISTRATION_SSO: "registration_sso",
  ENROLLMENT: "enrollment",
  MAGICLINK: "magiclink",
  MFA_OTP: "mfa_otp",
};
