import { WORKFLOW_REDUCER_NAMESPACE } from "./constants";

const types = {
  SET_WORKFLOW_LOADING: `${WORKFLOW_REDUCER_NAMESPACE}.doSetWorkflowLoading`,
  SET_WORKFLOW_COMPLETED: `${WORKFLOW_REDUCER_NAMESPACE}.doSetWorkflowCompleted`,
  SET_NEXT_COMPONENT_LOADING: `${WORKFLOW_REDUCER_NAMESPACE}.doSetNextComonentLoading`,
  SET_NEXT_COMPONENT: `${WORKFLOW_REDUCER_NAMESPACE}.doSetNextComponent`,
  SET_WORKFLOW: `${WORKFLOW_REDUCER_NAMESPACE}.doSetWorkflow`,
  CLEAR_WORKFLOW: `${WORKFLOW_REDUCER_NAMESPACE}.doClearWorkflow`,
  SET_STEP_ERROR: `${WORKFLOW_REDUCER_NAMESPACE}.doSetStepError`,
};

export default types;
