import axios from "axios";
import { isEmpty, toPairs } from "lodash/fp";
import { URL_ROOT_API } from "src/constants/api";
import { utils_user as utilsUser } from "./utils_user";

export type ApiAllowedMethods = "GET" | "POST" | "PUT" | "DELETE";

type FetchOptions =
  | {
      headers?: object;
    }
  | any;

export type ApiFetchArguments = {
  method?: ApiAllowedMethods;
  fullUrl?: string;
  endpoint?: string;
  body?: object;
  options?: FetchOptions;
  uriParams?: Record<string, string>;
};

function apiGET(url: string, config: object) {
  return axios.get(url, config);
}

function apiPOST(url: string, config: object, body: object) {
  const stringifiedBody = JSON.stringify(body);
  return axios.post(url, stringifiedBody, config);
}

function apiPUT(url: string, config: object, body: object) {
  const stringifiedBody = JSON.stringify(body);
  return axios.put(url, stringifiedBody, config);
}

function apiDELETE(url: string, config: object) {
  return axios.delete(url, config);
}

export const ApiMethods = {
  GET: apiGET,
  POST: apiPOST,
  PUT: apiPUT,
  DELETE: apiDELETE,
};

const stringifyURIParams = (uriParams: Record<string, string>) => {
  if (isEmpty(uriParams)) {
    return null;
  }
  const preUri = toPairs(uriParams)
    .map((entry) => {
      const [key, value] = entry;
      return `${key}=${encodeURI(value)}`;
    })
    .join("&");
  if (preUri) {
    return `/?${preUri}`;
  }
  return null;
};

export interface IResponseData {
  data: any;
  status: number;
}

export class RequestError extends Error {
  response: IResponseData | undefined;

  constructor(message?: string, response?: IResponseData) {
    super(message);
    if (response) {
      // Only assign the minimum data needed.
      const { data, status } = response;
      this.response = { data, status };
    }
  }
}

async function apiFetch({
  method,
  endpoint,
  fullUrl,
  body,
  options,
  uriParams,
}: ApiFetchArguments) {
  const realMethod =
    method && ApiMethods[method] ? ApiMethods[method] : ApiMethods.GET;
  const apiBase = "";

  if (typeof realMethod !== "function") {
    throw Error(`Method '${realMethod}' is not allowed by frontend`);
  }

  const uriString = uriParams ? stringifyURIParams(uriParams) : "";

  const urlToCall =
    fullUrl || `${apiBase}${URL_ROOT_API}${endpoint}${uriString}`;

  const optionsToPass = options;
  let headersTopass = {};

  if (optionsToPass && optionsToPass.headers) {
    headersTopass = optionsToPass.headers;
    delete optionsToPass.headers;
  }

  const config = {
    ...optionsToPass,
    headers: {
      "x-api-key": utilsUser.get_user_jwt(),
      "Content-Type": "text/plain",
      ...headersTopass,
    },
  };

  try {
    const result = await realMethod(urlToCall, config, body || {});

    return result?.data || result;
  } catch (e: any) {
    const response = e.hasOwnProperty("response") ? e.response : undefined;
    throw new RequestError(String(e), response);
  }
}

export default apiFetch;
