import { REDUX_ACTION } from "../constants/reduxAction";

const DEFAULT_STATE = {
  data: [],
  workflow: null,
  loading: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case REDUX_ACTION.EMR_EXISTING_CONNECTIONS:
      return {
        ...state,
        data: [...action.data],
      };
    case REDUX_ACTION.EMR_EXISTING_CONNECTIONS_LOADING:
      return {
        ...state,
        loading: action.data,
      };
    case REDUX_ACTION.EMR_WORKFLOW:
      return {
        ...state,
        workflow: action.data,
      };
    default:
      return state;
  }
}
