import React from "react";

import PAGES from "src/constants/pages";
import { LOCAL_STORAGE } from "src/constants/localStorage";
import { WORKFLOW } from "src/constants/workflow";

import Progress from "src/components/global/progress";
import ProgressPlaceholder from "src/components/global/progress-placeholder";

import { utils } from "src/utils/utils_general";
import { utils_workflow } from "src/utils/utils_workflow";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { workflow_get } from "src/actions/workflowAction";
import { get_user_attributes_get } from "src/actions/userAction";
import { withTranslation } from "react-i18next";

class WorkflowProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gettingAttribute: false,
    };
  }

  componentDidUpdate() {
    if (!this.props.userAttribute && !this.state.gettingAttribute) {
      this.setState({ gettingAttribute: true });
      this.props
        .get_user_attributes_get()
        .then(() => this.setState({ gettingAttribute: false }))
        .catch(() => this.setState({ gettingAttribute: false }));
    }
  }

  getWorkflowCodeFromURL = () => {
    return location.pathname.split("/").find((c) => c.includes("workflow_"));
  };

  render() {
    let progressFlow = []; // takes in an array of all the steps and renders it

    const {
      workflow,
      location,
      workflowIsLoading,
      subworkflows,
      workflowCode,
      workflowName,
    } = this.props;

    const id = utils_workflow.getIdThroughUrl(location.pathname);
    const stepBasedOnId =
      id && workflow ? workflow.find((c) => c.id === id) : null;

    const subworkflow_code = workflowCode || this.getWorkflowCodeFromURL();
    const stepBasedOnSubworkflowCode = subworkflow_code
      ? workflow.find((c) => c.subworkflow_code === subworkflow_code)
      : null;

    const naturalNextStep = workflow
      ? workflow.find(
          (c) =>
            !c.value &&
            (c.entry_attribute[0] === "*" ||
              utils_workflow.entryAttrFulfilled(
                c.entry_attribute,
                this.props.userAttribute,
              )),
        )
      : null;

    const currentStep = workflow
      ? stepBasedOnSubworkflowCode || stepBasedOnId || naturalNextStep
      : null;

    const isEnrollmentLanding =
      location.pathname === PAGES.ENROLLMENT ||
      location.pathname === PAGES.ENROLLMENT_PROFILE; // do not show "active" progress on landing
    const isEnrollment = location.pathname.includes(PAGES.ENROLLMENT);

    const isSubworkflow =
      currentStep && currentStep.component === WORKFLOW.SUBWORKFLOW;
    const subworkflow = isSubworkflow
      ? subworkflows[currentStep.subworkflow_code] ||
        utils.get_local_storage(
          `${LOCAL_STORAGE.WORKFLOW}-${currentStep.subworkflow_code}`,
        )
      : null;

    // if enrollment - show entire rollment workflow
    if (isEnrollment) {
      progressFlow = workflow;
    } else if (isSubworkflow) {
      // - if dashboard - subworkflow - show entire subworkflow
      progressFlow = subworkflow;
    } else {
      // - if dashboard - just one component - show that one component ( fake the array )
      if (currentStep) {
        progressFlow = [{ step: currentStep.step, id: currentStep.id }];
      }
    }

    const currentActiveStep =
      isSubworkflow && subworkflow
        ? subworkflow.find((c) => !c.value) || currentStep
        : currentStep;
    return workflowIsLoading ? (
      <ProgressPlaceholder />
    ) : (
      <Progress
        workflowName={workflowName}
        flow={progressFlow}
        t={this.t}
        currentStep={isEnrollmentLanding ? undefined : currentActiveStep}
        progressCalculationStrategy={"legacy-non-sequential"}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
  subworkflows: state.workflow.subworkflows,
  workflow: state.workflow.workflow,
  workflowIsLoading: state.workflow.workflowIsLoading,
  ...ownProps,
});

export default withRouter(
  connect(mapStateToProps, { workflow_get, get_user_attributes_get })(
    withTranslation()(WorkflowProgress),
  ),
);
