import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  config_privacy_get,
  config_terms_get,
} from "../../actions/configAction";
import { LINKS } from "../../constants/links";
import { CONFIG } from "src/constants/config";

import PAGES from "src/constants/pages";
import CopyrightText from "../global/copyright_text";
import CobrandingLogo from "../global/cobranding_logo";
import HtmlContentComponent from "../core/HtmlContent/HtmlContentComponent";

class SiteMapFooter extends React.Component {
  prefetchPrivacyPolicy() {
    if (!this.props.privacyContent) {
      this.props.config_privacy_get(true);
    }
  }

  prefetchTerms() {
    if (!this.props.termsContent) {
      this.props.config_terms_get(true);
    }
  }

  render() {
    const { t } = this.props;

    return (
      <footer className={`footer white pt-3 pb-3`}>
        <div className="container-fluid">
          <div className="row no-gutters">
            <div
              className={`col-12 col-xl-3 text-center text-xl-left ${CONFIG.COBRANDING_LOGO_ENABLED ? "pt-xl-2" : "pt-xl-3"}`}
            >
              <HtmlContentComponent
                tag={"div"}
                markup={t("texts:Landing_footer_description")}
              />
            </div>
            <div
              className={`col-12 col-xl-3 text-center text-xl-left ${CONFIG.COBRANDING_LOGO_ENABLED ? "pt-xl-2" : "pt-xl-3"}`}
            >
              <HtmlContentComponent
                tag={"div"}
                markup={t("texts:Column1_footer")}
              />
            </div>
            <div
              className={`col-12 col-xl-3 text-center text-xl-left ${CONFIG.COBRANDING_LOGO_ENABLED ? "pt-xl-2" : "pt-xl-3"}`}
            >
              <HtmlContentComponent
                tag={"div"}
                markup={t("texts:Column2_footer")}
              />
            </div>
            <div
              className={`col-12 col-xl-3 text-center text-xl-left ${CONFIG.COBRANDING_LOGO_ENABLED ? "pt-xl-2" : "pt-xl-3"}`}
            >
              <HtmlContentComponent tag={"div"} markup={t("Column3_footer")} />
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12 col-xl-2 text-left desktop-only pt-0 pt-xl-3">
              <CopyrightText />
            </div>
            <div
              className={`col-12 offset-xl-8 col-xl-2 text-center text-xl-center ${CONFIG.COBRANDING_LOGO_ENABLED ? "pt-xl-0" : "pt-xl-3"}`}
            >
              <CobrandingLogo />
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});

export default withRouter(
  connect(mapStateToProps, { config_privacy_get, config_terms_get })(
    withTranslation()(SiteMapFooter),
  ),
);
