import { RESPONSE_CODE } from "../constants/errors";
import { LOCAL_STORAGE } from "../constants/localStorage";
import PAGES from "src/constants/pages";
import { WORKFLOW, WORKFLOW_VALUE } from "../constants/workflow";
import { utils } from "./utils_general";
import { utils_registration_flow } from "./utils_registration_flow";
import utilsGA, { workflowNames } from "src/utils/utils_ga";

export const utils_signup = {
  // handle if reg flow is null
  goToNextRegistrationStep: (
    reg_flow,
    currentStep,
    db_attr,
    function_update_flow,
    function_changePage,
    function_changeStatusAndLogin,
    customStepValue = WORKFLOW_VALUE.COMPLETE,
  ) => {
    if (!reg_flow) {
      console.log("no registration flow found");
      return null;
    }
    if (!currentStep) {
      console.log("no current step found");
      return null;
    }
    const new_flow = reg_flow.map((c) => {
      if (c.id === currentStep.id) {
        c.value = customStepValue;
      }
      return c;
    });

    function_update_flow(new_flow);
    const nextComponent = getNextComponent(new_flow, currentStep);
    getPageChange(
      nextComponent,
      reg_flow,
      db_attr,
      function_changePage,
      function_changeStatusAndLogin,
      function_update_flow,
    );
  },
  goToRegistrationStep: (
    reg_flow,
    goToStep,
    db_attr,
    function_update_flow,
    function_changePage,
    function_changeStatusAndLogin,
  ) => {
    if (!reg_flow) {
      console.log("no registration flow found");
      return null;
    }
    if (!goToStep) {
      console.log("no goto step found");
      return null;
    }
    function_update_flow(reg_flow);
    getPageChange(
      goToStep.component,
      reg_flow,
      db_attr,
      function_changePage,
      function_changeStatusAndLogin,
      function_update_flow,
    );
  },
};

const getNextComponent = (new_flow, currentStep) => {
  const currentIndex = new_flow.findIndex((c) => c.id === currentStep.id);
  const nextStep = new_flow[currentIndex + 1];
  return nextStep ? nextStep.component : null;
};

const getPageChange = (
  nextStepComponent,
  reg_flow,
  db_attr,
  changePage,
  changeStatusAndLogin,
  updateWorkflow,
) => {
  switch (nextStepComponent) {
    case WORKFLOW.CONFIRM_EMAIL:
      changePage(PAGES.SIGNUP_CONFIRM_EMAIL);
      break;
    case WORKFLOW.SET_PASSWORD:
      changePage(PAGES.SIGNUP_PASSWORD);
      break;
    case WORKFLOW.CONSENT:
      changePage(PAGES.SIGNUP_CONSENT);
      break;
    case WORKFLOW.POLICIES:
      changePage(PAGES.SIGNUP_APP_POLICIES);
      break;
    case WORKFLOW.VERIFICATION:
      changePage(PAGES.SIGNUP_VERIFICATION);
      break;
    case WORKFLOW.CATEGORIZATION:
      changePage(PAGES.SIGNUP_QUESTIONNAIRE);
      break;
    case WORKFLOW.VALIDATION:
      changePage(PAGES.SIGNUP_QUESTIONNAIRE);
      break;
    case WORKFLOW.TEXT_MEDIA:
      changePage(PAGES.SIGNUP_TEXT);
      break;
    case WORKFLOW.SUBWORKFLOW:
      changePage(PAGES.SIGNUP_SUBWORKFLOW);
      break;
    default:
      updateWorkflow(null);
      utils.remove_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);
      utils.remove_local_storage(LOCAL_STORAGE.WORKFLOW);
      // check all attributes
      onRegistrationComplete(
        reg_flow,
        db_attr,
        changeStatusAndLogin,
        changePage,
        updateWorkflow,
      );
      break;
  }
};

const onRegistrationComplete = (
  reg_flow,
  db_attr,
  changeStatusAndLogin,
  changePage,
) => {
  const req_attr = reg_flow
    .filter((c) => c.exit_attribute && c.exit_attribute[0] !== "*")
    .map((c) => c.exit_attribute);
  const req_attr_flat = [].concat.apply([], req_attr);
  const attr_errors = utils_registration_flow.checkAttrCompleted(
    req_attr_flat,
    db_attr,
  );
  if (attr_errors.length > 0) {
    // console.log('attr errors', attr_errors);
  } else {
    return changeStatusAndLogin()
      .then((user) => {
        const intended_destination = utils.get_local_storage(
          LOCAL_STORAGE.INTENDED_DESTINATION,
        );
        utilsGA.sendWorkflowEndToGA(workflowNames.signUp);
        if (intended_destination) {
          utils.remove_local_storage(LOCAL_STORAGE.INTENDED_DESTINATION);
          changePage(intended_destination);
        } else if (user.status === "active") {
          changePage(PAGES.DASHBOARD);
        } else if (user.status === "enrollment") {
          // Enrollment page state is inconsistent if we navigate with
          // the router. Use window.location as a workaround so the app
          // reinitializes de state.
          // changePage(PAGES.ENROLLMENT);
          window.location = PAGES.ENROLLMENT;
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status === RESPONSE_CODE["409_data_conflict"]
        ) {
          utils.clear_local_storage();
          changePage(PAGES.LOGIN);
        }
      });
  }
};
