import types, { ConsentAction } from "./types";

const DEFAULT_STATE = null;

export default function consentReducer(state: any, action: ConsentAction) {
  if (!state) {
    return DEFAULT_STATE;
  }
  const currentState = state;
  const { type, consentConfig } = action;
  switch (type) {
    case types.SET_CONSENT: {
      return consentConfig;
    }
    case types.SET_CONSENT_LOADING: {
      const { isLoading } = action;
      currentState.isLoading = isLoading;
      return {
        ...state,
        ...currentState,
      };
    }

    default:
      return state;
  }
}
