import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  post_workflow_complete_post,
  get_user_attributes_get,
} from "src/actions/userAction";
import { login_get } from "src/actions/loginAction";
import { utils } from "src/utils/utils_general";
import { LOCAL_STORAGE } from "src/constants/localStorage";
import { WORKFLOW } from "src/constants/workflow";
import { update_registration_flow } from "src/actions/registrationFlowAction";
import { utils_signup } from "src/utils/utils_signup";
import AppPolicies from "src/components/global/app_policies";
import { utils_registration_flow } from "src/utils/utils_registration_flow";
import SignupProgress from "../signup_progress";
import { clearReloadState } from "./service_regflow";

const CURRENT_REG_STEP = WORKFLOW.POLICIES;

class SignupAppPolicies extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: null,
      firstTime: false,
      reg_flow: null,
    };
  }

  componentDidMount() {
    const isFirstTime = utils.get_local_storage(LOCAL_STORAGE.FIRST_TIME_REG);
    if (isFirstTime) {
      this.setState({ firstTime: true });
    }

    const reg_flow =
      this.props.registrationFlow ||
      utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);

    if (reg_flow) {
      this.setState({ reg_flow });

      const currentStep =
        utils_registration_flow.current_registration_component(
          reg_flow,
          CURRENT_REG_STEP,
        );
      this.props
        .get_user_attributes_get()
        .then((res) => {
          this.checkEntryAttributes(res, currentStep, reg_flow);
          this.checkExitAttributesAlreadyComplete(res, currentStep, reg_flow);
        })
        .catch((error) => this.setState({ errors: error }));
    } else {
      this.setState({ errors: "no reg flow" });
    }
  }

  checkEntryAttributes(dbAttr, currentStep, reg_flow) {
    const attr_errors = utils_registration_flow.checkAttrCompleted(
      currentStep.entry_attribute,
      dbAttr,
    );
    if (attr_errors.length > 0) {
      // entry attribute not met
      const redirectInfo = utils_registration_flow.redirectToAttribute(
        attr_errors,
        reg_flow,
      );
      utils_signup.goToRegistrationStep(
        redirectInfo.flow,
        redirectInfo.step,
        dbAttr,
        this.props.update_registration_flow,
        this.props.history.push,
        this.props.post_workflow_complete_post,
      );
    }
  }

  checkExitAttributesAlreadyComplete(dbAttr, currentStep, reg_flow) {
    const exit_attr_not_completed = utils_registration_flow.checkAttrCompleted(
      currentStep.exit_attribute,
      dbAttr,
    );
    if (exit_attr_not_completed.length === 0) {
      // completed already, go to next step
      utils_signup.goToNextRegistrationStep(
        reg_flow,
        currentStep,
        dbAttr,
        this.props.update_registration_flow,
        this.props.history.push,
        this.props.post_workflow_complete_post,
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.registrationFlow &&
      !utils.check_objects_identical(
        this.props.registrationFlow,
        prevProps.registrationFlow,
      )
    ) {
      this.setState({ reg_flow: this.props.registrationFlow });
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {};
  }

  onFormComplete() {
    clearReloadState();
    this.goToRegisteredNextStep(this.state.reg_flow);
  }

  goToRegisteredNextStep(reg_flow) {
    const currentStep = utils_registration_flow.current_registration_component(
      this.state.reg_flow,
      CURRENT_REG_STEP,
    );

    this.props
      .get_user_attributes_get()
      .then((res) => {
        const attr_errors = utils_registration_flow.checkAttrCompleted(
          currentStep.exit_attribute,
          res,
        );
        if (attr_errors.length > 0) {
          this.componentDidMount();
        } else {
          utils_signup.goToNextRegistrationStep(
            reg_flow,
            currentStep,
            res,
            this.props.update_registration_flow,
            this.props.history.push,
            this.props.post_workflow_complete_post,
          );
        }
      })
      .catch((errors) => this.setState({ errors }));
  }

  render() {
    const currentStep = utils_registration_flow.current_registration_component(
      this.state.reg_flow,
      CURRENT_REG_STEP,
    );
    return (
      <section className="signup-component policies">
        {currentStep ? (
          <SignupProgress
            currentStep={currentStep}
            regFlow={this.state.reg_flow}
          />
        ) : null}
        <div className="signup-body-wrapper">
          <AppPolicies
            onComplete={() => this.onFormComplete()}
            errors={this.state.errors}
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
});

export default withRouter(
  connect(mapStateToProps, {
    login_get,
    update_registration_flow,
    post_workflow_complete_post,
    get_user_attributes_get,
  })(SignupAppPolicies),
);
