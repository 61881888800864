import { get } from "lodash/fp";
import { DNA_KIT_REDUCER_NAMESPACE } from "./Constants";

export const selectDnaKitOrderPayload = get(
  `${DNA_KIT_REDUCER_NAMESPACE}.dnaKitOrderPayload`,
);

export const selectDnaKitOrderRequesting = get(
  `${DNA_KIT_REDUCER_NAMESPACE}.dnaKitOrderRequesting`,
);

export const selectDnaKitOrderErrors = get(
  `${DNA_KIT_REDUCER_NAMESPACE}.dnaKitOrderErrors`,
);

export const selectDnaKitCreatedOrder = get(
  `${DNA_KIT_REDUCER_NAMESPACE}.dnaKitCreatedOrder`,
);
