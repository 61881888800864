/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PAGES from "src/constants/pages";
import SuggestedSurvey from "src/components/dashboard/SuggestedSurvey";
import { get_survey_list_get } from "src/actions/surveyAction";
import { utils } from "src/utils/utils_general";
import { utils_user } from "src/utils/utils_user";
import { utils_survey } from "src/utils/utils_survey";
import Spinner from "src/components/global/spinner";
import SurveyCard from "src/components/survey/survey_card";
import SurveyConsentModal from "src/components/survey/survey_consent_modal";

const SURVEY_NUM_SHOW = 4;

class SuggestedSurveys extends React.Component {
  isComponentMounted = false;

  constructor() {
    super();
    this.state = {
      suggestedSurveys: null,
      total_survey_count: null,
      errors: {},
      api_called: false,
      selectedSurvey: null,
    };
  }

  componentDidMount() {
    const { surveys, loginUser } = this.props;
    const { errors } = this.state;

    this.isComponentMounted = true;
    if (surveys) {
      const suggestedSurveys = this.getSurveysInOrder(surveys);

      this.setState({ suggestedSurveys, total_survey_count: surveys.length });
    } else if (
      !utils.is_obj_empty(loginUser) &&
      utils_user.get_user_jwt() &&
      !errors.api
    ) {
      // for some reason componentdidupdate keeps triggering as the surveys are being called...
      this.setState({ api_called: true });
      this.getSurveys();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      loginUser,
      surveys: propsSurveys,
      excludedSurveyId: propsExcludedSurveyId,
    } = this.props;
    const { surveys: stateSurveys, errors, api_called } = this.state;

    if (!stateSurveys && !prevProps.surveys && !utils.is_obj_empty(loginUser)) {
      if (propsSurveys) {
        const suggestedSurveys = this.getSurveysInOrder(propsSurveys);
        this.setState({
          suggestedSurveys,
          total_survey_count: propsSurveys.length,
        });
      } else if (!errors.api && utils_user.get_user_jwt() && !api_called) {
        this.getSurveys();
      }
    }
    if (prevProps.excludedSurveyId !== propsExcludedSurveyId) {
      const suggestedSurveys = this.getSurveysInOrder(propsSurveys);
      this.setState({
        suggestedSurveys,
        total_survey_count: propsSurveys.length,
      });
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  onSurveyClick(survey) {
    const { history } = this.props;

    this.setState({ selectedSurvey: survey });
    if (utils.is_mobile()) {
      history.push(`${PAGES.SURVEY_CONSENT}/${survey.survey_id}`, { survey });
    } else {
      this.setState({ showConsentModal: true });
    }
  }

  getSurveys() {
    const { get_survey_list_get: getSurveyListGet } = this.props;

    return getSurveyListGet(true)
      .then((surveysData) => {
        if (this.isComponentMounted) {
          const suggestedSurveys = this.getSurveysInOrder(surveysData.surveys);
          this.setState({
            suggestedSurveys,
            api_called: false,
            total_survey_count: surveysData.length,
          });
        }
      })
      .catch((error) =>
        this.isComponentMounted
          ? this.setState({ api_called: false, errors: { api: error } })
          : null,
      );
  }

  getSurveysInOrder(surveys) {
    const { excludedSurveyId } = this.props;

    const allSurveys = excludedSurveyId
      ? surveys.filter((survey) => survey.survey_id !== excludedSurveyId)
      : surveys;
    const started = utils_survey.getStartedSurveys(allSurveys);
    const notStarted = utils_survey.getNotStartedSurveys(allSurveys);
    const completed = utils_survey.getCompletedSurveys(allSurveys);
    return [...started, ...notStarted, ...completed];
  }

  renderSurvey(survey, key) {
    const { surveyNumber } = this.props;

    if (survey && key < (surveyNumber || SURVEY_NUM_SHOW)) {
      return (
        <SuggestedSurvey
          key={key}
          survey={survey}
          onSurveyClick={(s) => this.onSurveyClick(s)}
        />
      );
    }

    return null;
  }

  renderDefault() {
    const { t } = this.props;
    const { total_survey_count, suggestedSurveys } = this.state;

    return (
      <>
        <Link to={PAGES.SURVEYS} className="suggested-surveys-mobile-count">
          {t("See all")} <span>{total_survey_count}</span>
        </Link>
        <div className="suggested-surveys-wrapper">
          {suggestedSurveys.map((survey, key) =>
            this.renderSurvey(survey, key),
          )}
        </div>
        <Link
          to={PAGES.SURVEYS}
          className="btn button primary-invert mt-3 block"
        >
          {t("See all")}
        </Link>
      </>
    );
  }

  renderCard() {
    const { t, surveyNumber } = this.props;
    const { suggestedSurveys } = this.state;

    return (
      <>
        <div className="suggested-surveys-wrapper">
          <div className="row small-gutters mb-3">
            {suggestedSurveys.map((survey, key) =>
              key < (surveyNumber || SURVEY_NUM_SHOW) ? (
                <SurveyCard
                  title={survey.title}
                  detail={survey.description}
                  key={key}
                  iconKey={survey.limesurvey_id}
                  action={() => this.onSurveyClick(survey)}
                />
              ) : null,
            )}
          </div>
        </div>
        <div className="text-center">
          <Link to={PAGES.SURVEYS} className="btn-link-callout">
            {t("View more surveys")}
          </Link>
        </div>
      </>
    );
  }

  render() {
    const { t, type } = this.props;
    const { suggestedSurveys, showConsentModal, selectedSurvey } = this.state;

    if (!suggestedSurveys) {
      return <Spinner error="loading suggested surveys" />;
    }
    return (
      <div className="suggested-surveys section-component">
        <h2 className="mb-3 h3">{t("Suggested Surveys")}</h2>
        {type === "card" ? this.renderCard() : this.renderDefault()}
        <SurveyConsentModal
          fromSurvey={false}
          show={showConsentModal}
          onHide={() => this.setState({ showConsentModal: false })}
          survey={selectedSurvey}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});

export default withRouter(
  connect(mapStateToProps, { get_survey_list_get })(
    withTranslation()(SuggestedSurveys),
  ),
);
