const SET_CONSENT = "doSetConsent";
const SET_CONSENT_LOADING = "doSetConsentLoading";
const SET_CONSENT_FILE_LOADING = "doSetConsentFileLoading";

export type ConsentAction = {
  type: string;
  consentConfig?: object;
  isLoading?: boolean;
};

export default {
  SET_CONSENT,
  SET_CONSENT_LOADING,
  SET_CONSENT_FILE_LOADING,
};
