import { REDUX_ACTION } from "../constants/reduxAction";

const DEFAULT_STATE = null;

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case REDUX_ACTION.OTP_GET:
      return action.data;
    default:
      return state;
  }
}
