import { REDUX_ACTION } from "../constants/reduxAction";
import { LOCAL_STORAGE } from "../constants/localStorage";

const cookieLang = document.cookie
  .split("; ")
  .find((row) => row.startsWith(LOCAL_STORAGE.SELECTED_LANGUAGE));
const language = cookieLang ? cookieLang.split("=")[1] : "en";

const DEFAULT_STATE = {
  language,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case REDUX_ACTION.LANGUAGE_GET:
      return state.language;
    case REDUX_ACTION.LANGUAGE_SET:
      return (state = { ...state, language: action.data });
    default:
      return state;
  }
}
