/* eslint-disable import/prefer-default-export */
import { LOCAL_STORAGE } from "../constants/localStorage.js";
import {
  REGISTRATION_USER_STATUS,
  REGISTRATION_DB_CODE,
} from "../constants/registration.js";
import { WORKFLOW } from "../constants/workflow.js";
import { TOKEN_TYPE } from "../constants/tokenType.js";
import { utils } from "./utils_general.js";

export const utils_registration_flow = {
  prep_regflow_data: (res) => {
    if (!res) {
      return null;
    }
    const reg_flow = res.map((c) => {
      c.value = null;
      return c;
    });
    // set status and reorder based on rules if necessary
    return modify_reg_flow_based_on_type(reg_flow);
  },
  current_registration_component: (reg_flow, current_step) =>
    reg_flow ? reg_flow.find((c) => c.component === current_step) : null,
  get_registration_flow_query: (type) => {
    switch (type) {
      case TOKEN_TYPE.REGISTRATION_SELF:
        return REGISTRATION_DB_CODE.SELF_REGISTRATION;
      case TOKEN_TYPE.REGISTRATION_REFERRAL:
        return REGISTRATION_DB_CODE.REFERRAL_CODE;
      case TOKEN_TYPE.REGISTRATION_ADMIN:
        return REGISTRATION_DB_CODE.ADMIN_INVITE;
      case TOKEN_TYPE.REGISTRATION_SSO:
        return REGISTRATION_DB_CODE.SSO;
      default:
        return REGISTRATION_DB_CODE.SELF_REGISTRATION;
    }
  },
  clearRelatedStorage: () => {
    utils.remove_local_storage(LOCAL_STORAGE.TOKEN_SENT);
    utils.remove_local_storage(LOCAL_STORAGE.OTP_ID);
    utils.remove_local_storage(LOCAL_STORAGE.MAX_REQUEST);
  },
  combineConsentFlowToRegFlow: (consent, reg_flow) => {
    const consentIndexInFlow = reg_flow
      ? reg_flow.findIndex((c) => c.component === WORKFLOW.CONSENT)
      : null;
    if (consentIndexInFlow || consentIndexInFlow === 0) {
      reg_flow[consentIndexInFlow].consent_index = 0;
      consent.components.forEach((c, i) => {
        if (i > 0) {
          // separate consent flow as component: consent-1, consent-2, consent-3 etc
          const consentStep = getConsentStep(i, consentIndexInFlow, reg_flow);
          reg_flow.splice(consentIndexInFlow + i, 0, consentStep);
        }
      });
    }
    return reg_flow;
  },
  checkAttrCompleted: (attr, dbAttr) => {
    if (attr.length === 1 && attr[0] === "*") {
      return [];
    }

    return attr.filter((a) => {
      const [key, value] = a.split("|");
      const requiredValue = value || "*";
      return (
        !Object.hasOwn(dbAttr, key) ||
        (requiredValue !== String(dbAttr[key]) && requiredValue !== "*")
      );
    });
  },
  redirectToAttribute: (attr_error, reg_flow) => {
    const errorSteps = [];
    reg_flow.forEach((c) => {
      if (c.exit_attribute) {
        c.exit_attribute.forEach((a) => {
          if (attr_error.includes(a)) {
            errorSteps.push(c);
          }
        });
      }
    });
    const shouldGoToStep = errorSteps[0];
    const newFlow = reg_flow.map((c) => {
      if (c.id === shouldGoToStep?.id) {
        c.value = null;
      }
      return c;
    });
    return { flow: newFlow, step: shouldGoToStep };
  },
};

const getConsentStep = (i, consentIndexInFlow, reg_flow) => ({
  component: `${WORKFLOW.CONSENT}-${i}`,
  name: reg_flow[consentIndexInFlow].name,
  step: reg_flow[consentIndexInFlow].step,
  value: reg_flow[consentIndexInFlow].value,
  status: reg_flow[consentIndexInFlow].status,
  consent_index: i,
  id: i,
});

/*

STATUS:
    non-register = user not in database
    register = user in database but not active
    active = on enrollment page / dashboard

ASSUMPTIONS ON COMPONENT STATUS
    non-register:
    - set_email
    - set_password

    register:
    - confirm_email
    - verification
    - consent
    - policies

    anywhere
    - categorization
    - validation
    - text/media
*/

// setting status for easier finding during signup.js
const modify_reg_flow_based_on_type = (reg_flow) => {
  const reg_flow_components = reg_flow.map((e) => e.component);
  // the step in which the user becomes status "register"
  const register_index = [];
  if (reg_flow.find((c) => c.component === WORKFLOW.SET_EMAIL)) {
    // find max of all non-registered components and set everything before that as non-register
    // everything after as register
    register_index.push(reg_flow_components.indexOf(WORKFLOW.SET_EMAIL));
    register_index.push(reg_flow_components.indexOf(WORKFLOW.SET_PASSWORD));
    return reg_flow.map((c, i) =>
      set_component_status(c, i, Math.max.apply(Math, register_index)),
    );
  }

  return reg_flow.map((c, i) =>
    set_component_status(c, i, Math.max.apply(Math, register_index)),
  );
};

const set_component_status = (c, i, lastNonRegisteredIndex) => {
  if (i <= lastNonRegisteredIndex) {
    c.status = REGISTRATION_USER_STATUS.NON_REGISTER;
  } else {
    c.status = REGISTRATION_USER_STATUS.REGISTER;
  }
  return c;
};
