import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import PAGES from "src/constants/pages";
import {
  SIGNUP_ERROR_DISPLAY,
  RESPONSE_CODE,
  UNEXPECTED_ERROR,
} from "src/constants/errors";
import { TOKEN_TYPE } from "src/constants/tokenType";

import { utils } from "src/utils/utils_general";

import { login_post } from "src/actions/loginAction";
import { request_invite_post } from "src/actions/requestInviteAction";
import { otp_get_email } from "src/actions/otpAction";
import { OTP_LIFETIME_SECS, REG_LINK_MIN } from "src/constants/api_config";
import LinkEmailSent from "src/components/signup/link_expired_email_sent";

class LinkExpired extends React.Component {
  constructor({ t }) {
    super();
    this.t = t;
    this.state = {
      submitted: false,
      user: null,
      errors: {},
      showModal: false,
    };
  }

  componentDidMount() {
    const token = utils.get_url_param(this.props.location.search, "token");

    if (token) {
      this.props
        .login_post({ token })
        .then((response) => {
          if (!response.token_type.includes("registration")) {
            this.setState({
              errors: { system: this.t(SIGNUP_ERROR_DISPLAY.TOKEN_INVALID) },
            });
          } else {
            this.setState({ user: response });
          }
        })
        .catch((error) => {
          if (error.response.status === RESPONSE_CODE["413_already_active"]) {
            this.props.history.push(PAGES.LOGIN);
            // token should be expired, so if expired, then do nothing to handle it
          } else if (
            error.response &&
            error.response.status &&
            error.response.status !== RESPONSE_CODE["412_token_expired"]
          ) {
            this.setState({
              errors: { system: this.t(SIGNUP_ERROR_DISPLAY.TOKEN_INVALID) },
            });
          }
        });
    } else {
      this.setState({
        errors: { system: this.t(SIGNUP_ERROR_DISPLAY.TOKEN_INVALID) },
      });
    }
  }

  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  async handleClick(e) {
    e.preventDefault();

    const { location, history, t } = this.props;
    const { user } = this.state;
    const token = utils.get_url_param(location.search, "token");

    if (!token) {
      return;
    }

    try {
      await this.requestNewToken({ token, type: user?.token_type });
      this.handleShowModal();
    } catch (error) {
      if (!error || !error.response) {
        this.setState({ errors: { system: UNEXPECTED_ERROR } });
        return;
      }

      const { status } = error.response;

      if (status === RESPONSE_CODE["413_already_active"]) {
        history.push(PAGES.LOGIN);
        return;
      }

      if (status === RESPONSE_CODE["490_max_reached"]) {
        this.setState({
          errors: { system: t(SIGNUP_ERROR_DISPLAY.MAX_REACHED) },
        });
        return;
      }

      this.setState({ errors: { system: UNEXPECTED_ERROR } });
    }
  }

  handleCloseModal = () => {
    const { history } = this.props;

    this.setState({ showModal: false });
    history.push(PAGES.SIGNUP_CONFIRM_EMAIL);
  };

  requestNewToken({ token, type }) {
    const { otp_get_email, request_invite_post } = this.props;

    if (type === TOKEN_TYPE.REGISTRATION_SELF) {
      return otp_get_email();
    }

    return request_invite_post({ token });
  }

  render() {
    const { t, location } = this.props;
    const isAdminInvite = utils.get_url_param(location.search, "invite");
    const title = isAdminInvite
      ? t("Your link has expired")
      : t("Oops! Link Expired");
    const message = isAdminInvite
      ? t(
          `Please request a new one and use the link provided to your email within ${REG_LINK_MIN} minutes to begin registration.`,
        )
      : t(
          `Please request a new one and use the verification code provided within ${utils.secondsToMinutes(OTP_LIFETIME_SECS)} minutes.`,
        );
    return (
      <section className="linkexpired">
        <section className="signup-link container unauth-body-wrapper">
          <h1 className="mb-3">{title}</h1>
          <div className="mb-3">{message}</div>
          <div className="text-center">
            <Button
              onClick={
                utils.is_obj_empty(this.state.errors)
                  ? (e) => this.handleClick(e)
                  : () =>
                      this.setState({
                        errors: {
                          system: this.t(SIGNUP_ERROR_DISPLAY.TOKEN_INVALID),
                        },
                      })
              }
              variant={
                utils.is_obj_empty(this.state.errors) ? "primary" : "disable"
              }
              type="submit"
              block
            >
              {t("Send me a new link")}
            </Button>
            <small className="text-danger form-error">
              {this.state.errors.system || <br />}
            </small>
          </div>
          <LinkEmailSent
            isOpen={this.state.showModal}
            t={t}
            onHide={this.handleCloseModal}
            isAdminInvite={isAdminInvite}
          />
        </section>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
});

export default withRouter(
  connect(mapStateToProps, { login_post, request_invite_post, otp_get_email })(
    withTranslation()(LinkExpired),
  ),
);
