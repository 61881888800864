export type DnaKitOrderData = {
  uuid: string;
  order_id: string;
  app_user_id: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  zip: string;
  order_phone: string;
  google_verified: boolean;
  serial_id: string | null;
  sku_id: string | null;
  status: string;
  created: string;
  updated: string;
};

// Until we define an actual Model system, let's just use a default value.
export const dnaKitOrderDataDefault = {
  uuid: "",
  order_id: "",
  app_user_id: "",
  address_line1: "",
  address_line2: "",
  city: "",
  state: "",
  zip: "",
  order_phone: "",
  google_verified: false,
  serial_id: "",
  sku_id: "",
  status: "",
  created: "",
  updated: "",
};

export type ListDnaKitOrderResult = {
  success: boolean;
  data: DnaKitOrderData[];
};
