import { API_URL } from "../constants/api";
import { utils_api } from "../utils/utils_api";

export const api_emr = {
  get_emr_clinics(search, success, fail) {
    return utils_api.auth_get(
      `${API_URL.EMR_GET}?q=${encodeURIComponent(search)}`,
      success,
      fail,
    );
  },
  get_emr_connection(success, fail) {
    return utils_api.auth_get(API_URL.EMR_CONNECTION, success, fail);
  },
  get_emr_workflow(success, fail) {
    return utils_api.auth_get(
      `${API_URL.WORKFLOW_GET}?code=workflow_emr_connect`,
      success,
      fail,
    );
  },
  post_emr_connection(id, resource_url, success, fail) {
    return utils_api.auth_post(
      API_URL.EMR_CONNECTION,
      { identifier: id, resource_url: resource_url },
      success,
      fail,
    );
  },
};
