import React from "react";
import classnames from "classnames/bind";
import { range } from "lodash";
import styles from "./Placeholder.module.scss";

const cx = classnames.bind(styles);

export type PlaceholderProps = {
  glow?: boolean;
  inline?: boolean;
  height?: number;
  width?: number;
  className?: string;
};

export default function PlaceholderComponent({
  glow = true,
  inline = false,
  height = 1,
  width = 1,
  className,
}: PlaceholderProps): React.ReactElement {
  return (
    <div
      className={cx(
        styles.placeholder,
        { [styles.glow]: glow, [styles.inline]: inline },
        className,
      )}
    >
      {range(0, width).map((n) => (
        <span key={n}>&nbsp;</span>
      ))}
      {range(0, height).map((n) => (
        <br key={n} />
      ))}
    </div>
  );
}
