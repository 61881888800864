import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from "../utils/utils_general";
import { api_consent } from "../api/api_consent";
import { utils_api } from "../utils/utils_api.js";

// DEPRECATED
export function consent_get(code) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, "consent_get");
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      dispatch({
        type: REDUX_ACTION.CONSENT,
        data: response.data.data,
      });
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_consent.get_consent(success, fail, code);
  };
}

// DEPRECATED
export function consent_post(version, resp, sig) {
  return (dispatch) => {
    // utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER)
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_consent.submit_consent(success, fail, version, resp, sig);
  };
}

// DEPRECATED
export function consent_file_get() {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, "consent_file_get");
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_consent.get_consent_file(success, fail);
  };
}
