import React from "react";
import classnames from "classnames/bind";
import styles from "./Blank.module.scss";

const cx = classnames.bind(styles);

export type BlankProps = {
  children?: React.ReactNode;
};

export default function BlankComponent({ children }: BlankProps) {
  return <div className={cx(styles.blank)}>{children}&nbsp;</div>;
}
