import Joi, { ObjectSchema } from "joi";
import { email, password } from "./Common";

export interface LoginInterface<TPassword = string, TEmail = string> {
  password: TPassword;
  email: TEmail;
}

export type LoginValidationJoi = LoginInterface<
  Joi.StringSchema,
  Joi.StringSchema
>;

export const LOGIN_VALIDATION: ObjectSchema<LoginValidationJoi> = Joi.object({
  email,
  password,
});

export const LOGIN_EMAIL_VALIDATION: ObjectSchema<LoginValidationJoi> =
  Joi.object({
    email,
  });

export const LOGIN_PASSWORD_VALIDATION: ObjectSchema<LoginValidationJoi> =
  Joi.object({
    password,
  });
