import React from "react";
import { withTranslation } from "react-i18next";
import IconGenerator from "../icons/icon_generator";
import { utils } from "../../utils/utils_general";
import { TRUNCATE } from "../../constants/truncateString";

const SurveyCards = (props) => {
  const { i } = props;
  const { action } = props;
  const title = props.title
    ? utils.truncate_string(props.title, TRUNCATE.SURVEYCARD_TITLE, false)
    : null;
  const { iconKey } = props;

  return (
    <div key={i} className="survey-card-wrapper col-6 col-md-3 mb-2">
      <button
        className="survey-card bg-secondary p-3 rounded clearfix"
        onClick={action}
      >
        <div className="survey-card-img">
          <div className="survey-card-img-wrapper">
            <IconGenerator iconKey={iconKey} iconType="actioncard" />
          </div>
        </div>
        <div className="survey-card-description pb-3">
          <p className="survey-title color-brand">{title}</p>
        </div>
        {props.showRetake ? (
          <div className="survey-card-complete-retake-survey">
            {props.t("Retake Survey")}
          </div>
        ) : null}
      </button>
    </div>
  );
};

export default withTranslation()(SurveyCards);
