// utils is temp, we should be fetching it from S3

import { PASSWORD_FORMAT_ERRORS } from "../constants/errors";
import i18n from "../i18n";

export const utils = {
  is_obj: (obj) => obj && typeof obj === "object" && !Array.isArray(obj),

  is_obj_empty: (obj) =>
    Object.keys(obj).length === 0 && typeof obj === "object",

  set_local_storage: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  get_local_storage: (key) =>
    localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null,
  remove_local_storage: (key) => {
    localStorage.removeItem(key);
  },
  clear_local_storage: ({ exclude = [] } = { exclude: [] }) => {
    let keys = [];
    for (let i = 0; i < localStorage.length; i++) {
      keys.push(localStorage.key(i));
    }
    const keysToRemove = keys.filter((key) => !exclude.includes(key));
    for (const key of keysToRemove) {
      localStorage.removeItem(key);
    }
  },
  get_url_param: (paramString, key) => {
    const name = key.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]"); // eslint-disable-line no-useless-escape
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(paramString.toLowerCase());
    return !results ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  },
  get_url_params: () => {
    let queryString = window.location.search.slice(1);
    const obj = {};

    if (queryString) {
      queryString = queryString.split("#")[0];
      const arr = queryString.split("&");

      for (let i = 0; i < arr.length; i++) {
        const a = arr[i].split("=");
        let paramName = a[0];
        let paramValue = typeof a[1] === "undefined" ? true : a[1];

        paramName = paramName.toLowerCase();
        if (typeof paramValue === "string")
          paramValue = paramValue.toLowerCase();

        if (paramName.match(/\[(\d+)?\]$/)) {
          const key = paramName.replace(/\[(\d+)?\]/, "");
          if (!obj[key]) obj[key] = [];

          if (paramName.match(/\[\d+\]$/)) {
            const index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            obj[key].push(paramValue);
          }
        } else if (!obj[paramName]) {
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === "string") {
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          obj[paramName].push(paramValue);
        }
      }
    }

    return obj;
  },
  check_password_format: (password) => {
    const passwordErrors = [];
    const r_password_case = RegExp("^(?=.*[a-z])(?=.*[A-Z])", "g");
    if (!r_password_case.test(password)) {
      passwordErrors.push(PASSWORD_FORMAT_ERRORS.CASE);
    }

    const r_password_number = RegExp("^(?=.*[0-9])", "g");
    if (!r_password_number.test(password)) {
      passwordErrors.push(PASSWORD_FORMAT_ERRORS.NUMBER);
    }

    const r_password_character = RegExp("^(?=.*[!%@#$^&[\\]{}+=\\-~_|])", "g");
    if (!r_password_character.test(password)) {
      passwordErrors.push(PASSWORD_FORMAT_ERRORS.SPECIAL_CHARACTER);
    }

    const r_password_length = RegExp("^(?=.{8,50}$)", "g");
    if (!r_password_length.test(password)) {
      passwordErrors.push(PASSWORD_FORMAT_ERRORS.LENGTH);
    }
    return passwordErrors;
  },

  loadingSpinner: (dispatch, action, source = null) => {
    dispatch({
      type: action,
      data: source,
    });
  },
  get_browser: () => {
    if (navigator.userAgent.indexOf("Chrome") !== -1) {
      return "chrome";
    }
    if (navigator.userAgent.indexOf("Safari") !== -1) {
      return "safari";
    }
    if (navigator.userAgent.indexOf("Firefox") !== -1) {
      return "firefox";
    }
    if (
      navigator.userAgent.indexOf("MSIE") !== -1 ||
      !!document.documentMode === true
    ) {
      // IF IE > 10
      return "internet-explorer";
    }

    return "";
  },
  truncate_string: (str, n, useWordBoundary) => {
    if (str.length < n) {
      return str;
    }
    const subString = str.substr(0, n); // the original check
    return `${
      useWordBoundary
        ? subString.substr(0, subString.lastIndexOf(" "))
        : subString
    }...`;
  },
  is_json_string: (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
  titlecase: (str) =>
    str
      .split(" ")
      .map((w) =>
        w && w[0] ? w[0].toUpperCase() + w.substr(1).toLowerCase() : w,
      )
      .join(" "),
  is_mobile: () => window.innerWidth < 768,
  get_random_int_interval: (min, max) =>
    Math.floor(Math.random() * (max - min + 1) + min),
  toUTF: (str) => {
    const UnicodeString = (str || "")
      .split("")
      .map((value, index, array) => {
        const temp = value.charCodeAt(0);
        return temp;
      })
      .join("");
    return UnicodeString.substring(UnicodeString.length - 6);
  },
  remove_html: (str) =>
    str.replace(/<\/?[^>]+(>|$)/g, "").replace(/&&nbsp;/, " "),
  scroll_to_deeplink_el: (el) => {
    setTimeout(() => {
      el.scrollIntoView();
    }, 1);
  },
  check_objects_identical: (obj1, obj2) =>
    JSON.stringify(obj1) === JSON.stringify(obj2),
  get_text_content: (prefix, code, params = {}) => {
    if (!prefix || !code) {
      return "";
    }
    return i18n.t(`${prefix}_${code}`, { ...params, defaultValue: code });
  },
  replaceAllInString: (str, find, replace) => str.split(find).join(replace),
  secondsToFancyTimeFormat: (durationInSeconds) => {
    // Hours, minutes and seconds
    const hrs = Math.floor(durationInSeconds / 3600);
    const mins = Math.floor((durationInSeconds % 3600) / 60);
    const secs = Math.floor(durationInSeconds % 60);

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";

    if (hrs > 0) {
      ret += `${hrs}:${mins < 10 ? "0" : ""}`;
    }
    ret += `${mins}:${secs < 10 ? "0" : ""}`;
    ret += `${secs}`;
    return ret;
  },
  sortForward(arr, stepIndex = 0) {
    const preceding = arr.slice(0, stepIndex);
    const subsequent = arr.slice(stepIndex);
    return [...subsequent, ...preceding.reverse()];
  },
  getSurveyLangUrl(initialUrl, language = "en") {
    let url;
    try {
      url = new URL(initialUrl);
      return url.search ? `${url}&lang-${language}` : `${url}?lang-${language}`;
    } catch (err) {
      return initialUrl;
    }
  },
  secondsToMinutes(seconds) {
    const minutes = Math.floor(seconds / 60);
    return minutes.toString().padStart(1, "0");
  },
  cleanSpecialCharacter(value) {
    // Valid characters include A-z, 0-9, and (),.-.
    const clean_value = value.replace(/[^a-zA-Z0-9\)(\-,. ]/g, "");
    return clean_value;
  },
  chunkArray(arr, size) {
    const chunks = [];
    for (let i = 0; i < arr.length; i += size) {
      chunks.push(arr.slice(i, i + size));
    }
    return chunks;
  },
  sortByArrayLength(arr) {
    return arr.sort((a, b) => b.length - a.length);
  },
  moveArrayElementToTop(array, index) {
    const newList = [...array];
    newList.unshift(newList[index]);
    newList.splice(index + 1, 1);
    return newList;
  },
};
