// expects a onComplete prop as this component will be used within workflow

import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Spinner from "../global/spinner";
import EMRListItem from "./emr_list_item";

class EMRConnections extends React.Component {
  openConnectLink(item) {
    return this.props.onConnectClick
      ? this.props.onConnectClick(item)
      : window.open(item.url, "_blank");
  }

  render() {
    return (
      <div className="emr-existing-connections mt-5">
        {this.props.emrConnections.length && (
          <div className="mb-1 font-weight-bold">
            {this.props.t(
              this.props.loading
                ? "Loading.."
                : "Log of authorisations for EHR data transfer",
            )}
          </div>
        )}
        {this.props.loading ? (
          <Spinner relative="true" />
        ) : (
          <div className="emr-existing-connections-list">
            {this.props.emrConnections.map((item, key) => (
              <EMRListItem
                key={key}
                hideConnect={this.props.hideConnect}
                connectCopy={this.props.t("Re-connect")}
                connectOnClick={() => this.openConnectLink(item)}
                item={item}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  emrConnections: state.emrConnections.data,
  loading: state.emrConnections.loading,
  ...ownProps,
});

export default connect(mapStateToProps)(withTranslation()(EMRConnections));
