import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ConfirmEmailError from "./confirm-email/confirm_email_error";
import {
  login_get,
  login_skip_emailverif_post,
} from "../../actions/loginAction";
import { get_user_attributes_get } from "src/actions/userAction";
import {
  RESPONSE_CODE,
  SIGNUP_OTP_ERROR_DISPLAY,
} from "../../constants/errors";
import Spinner from "../global/spinner";
import PAGES from "src/constants/pages";
import { utils } from "src/utils/utils_general";
import { withTranslation } from "react-i18next";

// Based on ConfirmEmailAuto
class ConfirmEmailLogin extends React.Component {
  constructor() {
    super();
    this.state = {
      user: null,
      errors: {},
      confirmed: false,
      alreadyConfirmed: false,
      mounted: false,
    };
  }

  componentDidMount() {
    this.handleSubmit();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {};
  }

  handleSubmit() {
    const token = utils.get_url_param(this.props.location.search, "token");
    const otp_id = utils.get_url_param(this.props.location.search, "otp_id");

    if (otp_id && token) {
      this.props
        .login_skip_emailverif_post(otp_id, token, true)
        .then(() => {
          this.login(() => {
            this.setState({ confirmed: true });
            this.props.history.push(PAGES.DASHBOARD);
          });
        })
        .catch((error) => {
          const e =
            error && error.response
              ? error.response.data.msg
              : SIGNUP_OTP_ERROR_DISPLAY.GENERIC;
          if (
            error.response &&
            error.response.status === RESPONSE_CODE["412_token_expired"]
          ) {
            this.setState({ errors: { system: e } });
          } else if (
            error.response &&
            error.response.status ===
              RESPONSE_CODE["419_email_already_confirmed"] &&
            !this.state.alreadyConfirmed
          ) {
            this.setState({ errors: {}, alreadyConfirmed: true });
          } else {
            this.setState({ errors: { system: e } });
          }
        });
    }
  }

  login(onSuccess) {
    this.props
      .login_get()
      .then(() => {
        this.props.get_user_attributes_get().then(() => {
          onSuccess();
        });
      })
      .catch((error) => {
        const e =
          error && error.response && error.response.data.msg
            ? error.response.data.msg
            : SIGNUP_OTP_ERROR_DISPLAY.GENERIC;
        if (
          error.response &&
          (error.response.status === RESPONSE_CODE["413_already_active"] ||
            error.response.status === RESPONSE_CODE["412_token_expired"])
        ) {
          this.props.history.push(PAGES.LOGIN);
        }
        this.setState({ errors: { system: e || error } });
      });
  }

  render() {
    if (this.state.errors.system) {
      return <ConfirmEmailError error={this.state.errors.system} />;
    }
    return (
      <>
        <h1>{this.props.t("Verify Email")}</h1>
        <div
          className="h2 mt-5 mb-3"
          style={{ fontSize: "18px", fontWeight: "normal", lineHeight: "24px" }}
        >
          {this.props.t("Verifying your email...")}
          <Spinner error="verifying email" />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, {
    get_user_attributes_get,
    login_get,
    login_skip_emailverif_post,
  })(withTranslation()(ConfirmEmailLogin)),
);
