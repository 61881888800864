import { API_URL } from "../constants/api";
import { utils_api } from "../utils/utils_api";
// import { utils_registration_flow } from "../utils/utils_registration_flow";

export const api_workflow = {
  get_workflow_by_code(workflow_code, success, fail) {
    return utils_api.auth_get(
      `${API_URL.WORKFLOW_GET}?subworkflow_code=${workflow_code}`,
      success,
      fail,
    );
  },

  get_workflow_by_token(success, fail) {
    return utils_api.auth_get(API_URL.WORKFLOW_GET, success, fail);
  },
};
