import React from "react";
import { withTranslation } from "react-i18next";
import InfoPopup from "./info_popup";

const getInfoTitle = (title) => (
  <h2>
    <span className="info-popup-button static" />
    <span>{title}</span>
  </h2>
);

const getInfoBody = (desc) => <p>{desc}</p>;

const KnowledgeCheck = withTranslation()((props) => {
  const { t, questions } = props;
  const { text } = questions;

  const title = text.title || "What is a knowledge check?";

  return (
    <div className="knowledge-check">
      <h2 className="knowledge-check-title">
        {t("Knowledge Check")}
        <InfoPopup
          title={getInfoTitle(t(title))}
          body={getInfoBody(t("infoDescription"))}
        />
      </h2>
    </div>
  );
});

export default KnowledgeCheck;
