import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button } from "react-bootstrap";
import { useHistory, useLocation, Link } from "react-router-dom";

import PAGES from "src/constants/pages";
import { TOKEN_TYPE } from "src/constants/tokenType";
import { OTP_METHODS, OTP_LOGIN_FLOWS } from "src/constants/otp";
// import OTPInput from 'src/components/core/otp-input';
import SimpleOTPInput from "src/components/core/simple-otp-input";
import HtmlContentComponent from "src/components/core/HtmlContent/HtmlContentComponent";
import { login_mfa_otp_request_post } from "src/actions/loginAction";
import { login_mfa_otp_validation_post } from "src/actions/loginAction";
import { workflow_get } from "src/actions/workflowAction";
import {
  UNEXPECTED_ERROR,
  OTP_ERROR_DISPLAY,
  RESPONSE_CODE,
} from "src/constants/errors";
import { goToDestination } from "./utils";
import { utils_user } from "src/utils/utils_user.js";
import { opt_selected_method } from "src/utils/validationrules_api_app";
import { utils_validation } from "src/utils/utils_validation";
import { utils } from "src/utils/utils_general";
import { LOCAL_STORAGE } from "src/constants/localStorage";

function formatPhone(phone) {
  const lastDigits = phone.slice(-4);
  return `(***) ***-${lastDigits}`;
}

function MfaOtpValidation(props) {
  const { t } = useTranslation();
  const {
    otpId,
    error,
    setError,
    goToGetNewOTPCode,
    isMFAFlow,
    contactDetail,
  } = props;
  const isAppLoading = useSelector((state) => state.spinner);

  const initialOtpDigits = ["", "", "", "", "", ""];
  const [otpDigits, setOtpDigits] = useState(initialOtpDigits);
  const dispatch = useDispatch();

  const history = useHistory();
  const userAttribute = useSelector((state) => state.userAttribute);

  const submitMfaOtp = async (otp) => {
    const resultPromise = dispatch(login_mfa_otp_validation_post(otpId, otp));
    const goToApp = (user, flow) => {
      goToDestination(history, user, userAttribute, flow);
    };
    resultPromise
      .then((user) => {
        const valid_login_session = [
          TOKEN_TYPE.PASSWORD,
          TOKEN_TYPE.SESSION,
          TOKEN_TYPE.REGISTRATION_SELF,
          TOKEN_TYPE.REGISTRATION_SUBACCOUNT,
          TOKEN_TYPE.REGISTRATION_ADMIN,
          TOKEN_TYPE.ENROLLMENT,
        ];
        if (!valid_login_session.includes(user.token_type)) {
          // TODO: Show invalid token screen
          //setErrors({ system: t(LOGIN_ERROR_DISPLAY.SESSION_INVALID) });
        } else if (new Date(user.expiry) < new Date()) {
          // TODO: Show invalid token screen
          // setErrors({ system: t(LOGIN_ERROR_DISPLAY.SESSION_EXPIRED) });
        } else if (user.status === "pending") {
          // DONT GET THE WORKFLOW IF USER IS STILL IN REG MODE
          goToApp(user);
        } else {
          dispatch(workflow_get())
            .then((flow) => goToApp(user, flow))
            .catch(() => goToApp(user));
        }
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.msg;
        if (errorMessage === "incorrect otp code") {
          return setError(
            t("The code you entered is incorrect. Please try again."),
          );
        }
        if (errorMessage === "invalid magiclink token") {
          // TODO: Show invalid token screen
          return setError(
            t("The code you entered is incorrect. Please try again."),
          );
        } else {
          return setError(t(UNEXPECTED_ERROR));
        }
      });
  };

  const handleSubmitOtp = (e) => {
    e.preventDefault();
    setError("");
    const otp = otpDigits.join("");
    if (otp.length === 6) {
      submitMfaOtp(otp);
    }
  };

  const handleOtpPaste = (value) => {
    setOtpDigits(String(value).split(""));
    setError("");
  };

  const handleOtpChange = (value) => {
    setOtpDigits(value);
    setError("");
  };

  const getNewOTPCode = (requestOTPwithoutPopup) => {
    setOtpDigits(initialOtpDigits);
    setError("");
    goToGetNewOTPCode(requestOTPwithoutPopup);
  };

  const isSubmitEnabled = () => {
    const isOtpFilled = otpDigits.join("").length === 6;
    return !isAppLoading && isOtpFilled;
  };

  return (
    <section>
      <div className="login unauth-body-wrapper">
        <div>
          <h1>{t("mfaotp-popup-title")}</h1>
          <p className="mb-0">{t("mfaotp-popup-description")}</p>
          <HtmlContentComponent
            markup={t("mfaotp-poppup-advice").replace(
              "{contact_detail}",
              contactDetail,
            )}
          />
        </div>

        <div>
          <Form noValidate onSubmit={handleSubmitOtp}>
            <div className="py-3">
              <SimpleOTPInput
                handlePaste={handleOtpPaste}
                handleChange={handleOtpChange}
                token={otpDigits}
                inputType="number"
              />
            </div>
            <div className="text-right py-4">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  getNewOTPCode(true);
                }}
              >
                {t("Re-Send Code")}
              </a>
            </div>

            <div className="text-right">
              {isMFAFlow && (
                <Button
                  variant={"link"}
                  className="btn-back me-30"
                  onClick={() => getNewOTPCode(false)}
                >
                  {t("Back")}
                </Button>
              )}
              <Button
                variant={isSubmitEnabled() ? "primary" : "disabled"}
                type="submit"
              >
                {t("Continue")}
              </Button>
            </div>

            {error && (
              <div className="text-center py-2">
                <Form.Text
                  className="text-danger form-error"
                  aria-live="polite"
                >
                  {error}
                </Form.Text>
              </div>
            )}
          </Form>
        </div>
      </div>
    </section>
  );
}

function MfaOtpSelectMethod(props) {
  const { error, setError, from } = props;
  const { t } = useTranslation();
  const [method, setMethod] = useState("");

  const onMethodSelected = (event) => {
    setMethod(event.currentTarget.id);
  };

  const onSubmitSelectedMethod = (event) => {
    event.preventDefault();
    setError("");
    const errors = utils_validation.validate(opt_selected_method, { method });
    if (utils.is_obj_empty(errors)) {
      props.onSelectedMethod(method);
    } else {
      setError(t("opt-method-field-" + errors.method));
    }
  };

  return (
    <section>
      <div className="login unauth-body-wrapper">
        <div>
          <h1>{t("mfaotp-popup-title")}</h1>
          <p>{t("mfaotp-popup-description")}</p>
          <strong>{t("mfaotp-popup-request")}</strong>
        </div>

        <div>
          <Form noValidate onSubmit={onSubmitSelectedMethod}>
            <div className="py-3">
              <div className="radio-round">
                <input
                  type="radio"
                  id={OTP_METHODS.CALL}
                  name="method"
                  onChange={onMethodSelected}
                />
                <label htmlFor={OTP_METHODS.CALL}>{t("Call me")}</label>
              </div>
              <div className="radio-round">
                <input
                  type="radio"
                  id={OTP_METHODS.SMS}
                  name="method"
                  onChange={onMethodSelected}
                />
                <label htmlFor={OTP_METHODS.SMS}>{t("Text Me")}</label>
              </div>
              {from === OTP_LOGIN_FLOWS.PASSWORD && (
                <div className="radio-round">
                  <input
                    type="radio"
                    id={OTP_METHODS.EMAIL}
                    name="method"
                    onChange={onMethodSelected}
                  />
                  <label htmlFor={OTP_METHODS.EMAIL}>{t("email")}</label>
                </div>
              )}
            </div>

            <div className="text-right">
              <Button className="btn-full" variant={"primary"} type="submit">
                {t("Continue")}
              </Button>
            </div>

            {error && (
              <div className="text-center py-2">
                <Form.Text
                  className="text-danger form-error"
                  aria-live="polite"
                >
                  {error}
                </Form.Text>
              </div>
            )}
          </Form>
        </div>
      </div>
    </section>
  );
}

function LimitReached() {
  const { t } = useTranslation();
  return (
    <section>
      <div className="login unauth-body-wrapper">
        <div>
          <h1>{t("Limit Reached")}</h1>
          <p className=" mb-2 warning-text font-weight-bold">
            {t(
              "You have reached the maximum number of Re-Sends for this number.",
            )}
          </p>
          <p className=" mb-2 warning-text font-weight-bold">
            {t("Please try again in 30 minutes.")}
          </p>
          <div className="text-right py-4">
            <Link to={PAGES.LOGIN}>{t("Sign in")}</Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default function MfaOtp() {
  const user = utils_user.get_user() || {};
  const location = useLocation();
  const { from } = location.state;
  const token_type = user?.token_type;
  const isMFAFlow = token_type === TOKEN_TYPE.MFA_OTP;
  const [step, setStep] = useState("request"); // 'request', 'validation', 'limit-reached'
  const [methodSelected, setMethodSelected] = useState(
    isMFAFlow ? false : true,
  );
  const [otpId, setOtpId] = useState(null);
  const [error, setError] = useState("");
  const [contactDetail, setContactDetail] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const otpId = utils.get_local_storage(LOCAL_STORAGE.OTP_ID);
    if (otpId) {
      setStep("validation");
      setOtpId(otpId);
      setMethodSelected(true);
    } else if (!isMFAFlow) {
      sendOTPCode(OTP_METHODS.EMAIL);
    }
  }, []);

  const sendOTPCode = (method) => {
    const payload = {
      login_flow: from,
      method,
    };
    const contactDetail =
      method === OTP_METHODS.EMAIL ? user?.email : formatPhone(user?.ph_mobile);
    setContactDetail(contactDetail);
    const resultPromise = dispatch(login_mfa_otp_request_post(payload));
    resultPromise
      .then((res) => {
        setStep("validation");
        if (res.otp_id) {
          setOtpId(res.otp_id);
          utils.set_local_storage(LOCAL_STORAGE.OTP_ID, res.otp_id);
          setMethodSelected(true);
        } else {
          setError(UNEXPECTED_ERROR);
        }
      })
      .catch((error) => {
        if (error.response?.status === RESPONSE_CODE["405_data_invalid"]) {
          setStep("validation");
          setError(OTP_ERROR_DISPLAY.CODE_INCORRECT);
          return;
        }
        if (error.response?.status === RESPONSE_CODE["490_max_reached"]) {
          setStep("limit-reached");
          return;
        }
        setStep("validation");
        setError(UNEXPECTED_ERROR);
      });
  };

  const newOTPCode = (requestOTPwithoutPopup) => {
    if (!isMFAFlow || requestOTPwithoutPopup) {
      setStep("validation");
      setMethodSelected(true);
      sendOTPCode(OTP_METHODS.EMAIL);
    } else if (isMFAFlow) {
      setStep("request");
      setMethodSelected(false);
    }
    setOtpId(null);
    utils.remove_local_storage(LOCAL_STORAGE.OTP_ID);
  };

  const onSelectedMethod = (method) => {
    sendOTPCode(method);
  };

  if (isMFAFlow && !methodSelected) {
    return (
      <MfaOtpSelectMethod
        onSelectedMethod={onSelectedMethod}
        error={error}
        setError={setError}
        from={from}
      />
    );
  }

  if (methodSelected) {
    return (
      <>
        {step === "validation" ? (
          <MfaOtpValidation
            otpId={otpId}
            error={error}
            setError={setError}
            contactDetail={contactDetail}
            goToGetNewOTPCode={newOTPCode}
            isMFAFlow={isMFAFlow}
          />
        ) : null}
        {step === "limit-reached" ? <LimitReached /> : null}
      </>
    );
  }
}
