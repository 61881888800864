import freeFormReducer from "./FreeForm/freeFormReducer";
import consentReducer from "./Consent/ConsentReducer";
import workflowReducer from "./Workflow/workflowReducer";
import { WORKFLOW_REDUCER_NAMESPACE } from "./Workflow/constants";
import { DNA_KIT_REDUCER_NAMESPACE } from "./DnaKit/Constants";
import dnaKitReducer from "./DnaKit/DnaKitReducer";

const reducers = {
  freeFormReducer,
  consent: consentReducer,
  [WORKFLOW_REDUCER_NAMESPACE]: workflowReducer,
  [DNA_KIT_REDUCER_NAMESPACE]: dnaKitReducer,
};

export default reducers;
