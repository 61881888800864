import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Spinner from "src/components/global/spinner";
import { IDnaKitService } from "src/services/DnaKitService";
import { useInjection } from "src/services/ServiceProvider";
import { DnaKitOrderData } from "src/services/types/DnaKit";
import { OrderDnaKitInterface } from "src/services/types/validations/OrderDnaKit";
import { InnerWorkflowStepProps } from "src/services/types/WorkflowTypes";
import {
  selectDnaKitCreatedOrder,
  selectDnaKitOrderErrors,
  selectDnaKitOrderPayload,
  selectDnaKitOrderRequesting,
} from "src/store/DnaKit/DnaKitSelectors";
import InnerWorkflowActions from "../../InnerWorkflow/InnerWorkflowActions";
import OrderDisplay from "./OrderDisplay";
import OrderDnaKitLayout from "./OrderDnaKitLayout";
import utilsGA, { formNames } from "src/utils/utils_ga";

type OrderDnaKitConfirmStateProps = {
  dnaKitOrderPayload: OrderDnaKitInterface;
  dnaKitOrderRequesting: boolean;
  dnaKitOrderErrors: Error[];
  dnaKitCreatedOrder?: DnaKitOrderData;
};

export type OrderDnaKitConfirmProps = InnerWorkflowStepProps &
  OrderDnaKitConfirmStateProps;

function OrderDnaKitConfirm({
  dnaKitOrderPayload,
  dnaKitOrderRequesting,
  dnaKitOrderErrors,
  dnaKitCreatedOrder,
  onStepComplete,
  onStepBack,
}: OrderDnaKitConfirmProps) {
  const [isDataConfirmed, setIsDataConfirmed] = useState<boolean>(false);

  const dnaKitService = useInjection<IDnaKitService>("dnaKitService");

  const { t } = useTranslation();

  // Perform the order when user confirms the info.
  useEffect(() => {
    if (isDataConfirmed) {
      dnaKitService.orderDnaKit(dnaKitOrderPayload);
      utilsGA.sendFormSubmitToGA(formNames.orderDNAKit);
    }
  }, [isDataConfirmed]);

  // Go to the success page on success.
  useEffect(() => {
    if (
      isDataConfirmed &&
      !dnaKitOrderRequesting &&
      dnaKitCreatedOrder !== undefined &&
      dnaKitCreatedOrder.order_id.length > 0
    ) {
      onStepComplete();
    }
  }, [isDataConfirmed, dnaKitOrderRequesting, dnaKitCreatedOrder]);

  const handleStepComplete = () => {
    setIsDataConfirmed(true);
  };

  const handleErrorOkClick = () => {
    dnaKitService.clearErrors();
    onStepBack();
  };

  return (
    <>
      <OrderDnaKitLayout title={t("Order Confirmation")}>
        <p>
          <strong>{t("Please Confirm your information below")}:</strong>
        </p>

        <OrderDisplay dnaKitOrderInfo={dnaKitOrderPayload} />
      </OrderDnaKitLayout>

      <InnerWorkflowActions
        showStepBack
        canStepBack={!dnaKitOrderRequesting}
        onStepBack={onStepBack}
        canContinue={!dnaKitOrderRequesting && dnaKitOrderErrors.length === 0}
        onStepComplete={handleStepComplete}
      />

      {dnaKitOrderRequesting && <Spinner />}

      <Modal show={!dnaKitOrderRequesting && dnaKitOrderErrors.length > 0}>
        <Modal.Header>
          <h3>Error!</h3>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {dnaKitOrderErrors.map(({ message }, index) => (
              <li key={index}>{t(message)}</li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer className="text-right">
          <Button variant="primary" size="sm" onClick={handleErrorOkClick}>
            {t("Return to Form")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = createStructuredSelector<
  any,
  OrderDnaKitConfirmStateProps
>({
  dnaKitOrderPayload: selectDnaKitOrderPayload,
  dnaKitOrderRequesting: selectDnaKitOrderRequesting,
  dnaKitOrderErrors: selectDnaKitOrderErrors,
  dnaKitCreatedOrder: selectDnaKitCreatedOrder,
});

export default connect(mapStateToProps, {})(OrderDnaKitConfirm);
