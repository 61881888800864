import { REDUX_ACTION } from "../constants/reduxAction.js";
import { utils } from "../utils/utils_general";
import { api_password } from "../api/api_password";
import { api_user } from "../api/api_user.js";
import { utils_api } from "../utils/utils_api.js";

export function send_reset_password_email_post(data) {
  return (dispatch) => {
    utils.loadingSpinner(
      dispatch,
      REDUX_ACTION.SPINNER,
      "send_reset_password_email_post",
    );
    const success = (response) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_password.send_reset_password_email(success, fail, data);
  };
}

export function reset_password_put(password) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, "reset_password_put");
    const success = (response) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_password.reset_password(success, fail, password);
  };
}

export function update_password_put(password, new_password) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, "update_password_put");
    const success = (response) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_password.update_password(success, fail, password, new_password);
  };
}

export function set_first_time_password_put(password) {
  const data = {
    password,
  };
  return (dispatch) => {
    utils.loadingSpinner(
      dispatch,
      REDUX_ACTION.SPINNER,
      "set_first_time_password_put",
    );
    const success = (response) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_user.update_user(success, fail, data);
  };
}

export function verify_password_post(password, new_password) {
  return (dispatch) => {
    utils.loadingSpinner(
      dispatch,
      REDUX_ACTION.SPINNER,
      "set_first_time_password_put",
    );
    const success = (response) =>
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_password.verify_password(success, fail, password, new_password);
  };
}
