import { CONFIG } from "src/constants/config";

export default function CopyrightText() {
  const year = process.env.REACT_APP_CURRENT_YEAR;
  return (
    <span className="text-nowrap">
      &copy; {year} {CONFIG.PARTNER_NAME}
    </span>
  );
}
