import { utils } from "src/utils/utils_general";
import PAGES from "src/constants/pages";
import { LOCAL_STORAGE } from "src/constants/localStorage";
import { utils_workflow } from "src/utils/utils_workflow";

export function goToDestination(history, user, userAttribute, workflow = null) {
  const destination =
    utils.get_local_storage(LOCAL_STORAGE.INTENDED_DESTINATION) ||
    PAGES.DASHBOARD;
  utils.remove_local_storage(LOCAL_STORAGE.INTENDED_DESTINATION);
  if (user.status === "enrollment") {
    window.location = PAGES.ENROLLMENT;
  } else {
    const pathNameWithHash = window.location.href
      .replace(/https?:\/\//, "")
      .replace(window.location.host, "");
    if (pathNameWithHash.match(PAGES.EMR_CONNECT_WORKFLOW)) {
      return (window.location = pathNameWithHash);
    }
    if (workflow) {
      utils_workflow.checkDashboardWorkflow(
        workflow,
        userAttribute,
        history.push,
        destination,
      );
    } else {
      history.push(destination);
    }
  }
}
