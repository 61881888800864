import Joi, { ObjectSchema } from "joi";
import { checkXss, firstName, lastName, zip } from "./Common";

export interface VerificationInterface<
  TFirstName = string,
  TLastName = string,
  TAddress = string,
  TCity = string,
  TState = string,
  TZip = string,
> {
  firstname: TFirstName;
  lastname: TLastName;
  address: TAddress;
  city: TCity;
  state: TState;
  zip: TZip;
}

export type VerificationValidationJoi = VerificationInterface<
  Joi.StringSchema,
  Joi.StringSchema,
  Joi.StringSchema,
  Joi.StringSchema,
  Joi.StringSchema,
  Joi.StringSchema
>;

const xssMessages = {
  "any.xss": "error-potential-xss",
};

export const VERIFICATION_VALIDATION: ObjectSchema<VerificationValidationJoi> =
  Joi.object({
    firstname: firstName,
    lastname: lastName,
    address: Joi.string()
      .required()
      .custom(checkXss)
      .messages({
        ...xssMessages,
      }),
    city: Joi.string()
      .optional()
      .custom(checkXss)
      .messages({
        ...xssMessages,
      }),
    state: Joi.string()
      .optional()
      .custom(checkXss)
      .messages({
        ...xssMessages,
      }),
    zip: zip.required(),
  });
