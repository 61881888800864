import { REDUX_ACTION } from "../constants/reduxAction";

const DEFAULT_STATE = {
  workflow: null,
  pageIsLoading: false,
  workflowIsLoading: false,
  subworkflows: {},
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case REDUX_ACTION.SUBWORKFLOW:
      const subworkflowObj = state.subworkflows || {};
      subworkflowObj[action.data.code] = action.data.data;
      return {
        ...state,
        subworkflows: subworkflowObj,
      };
    case REDUX_ACTION.WORKFLOW:
      return {
        ...state,
        workflow: action.data,
      };
    case REDUX_ACTION.WORKFLOW_LOADING:
      return {
        ...state,
        workflowIsLoading: action.data,
      };
    default:
      return state;
  }
}
