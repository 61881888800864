import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { OTP_LIFETIME_SECS, REG_LINK_MIN } from "src/constants/api_config";
import { utils } from "src/utils/utils_general";

const LinkEmailSent = (props) => {
  const { t, isAdminInvite } = props;

  const title = t("We’ve sent you a new link");
  const message = isAdminInvite
    ? t(
        `A new email has been sent, please use the link provided to your email within ${REG_LINK_MIN} minutes to continue registration.`,
      )
    : t(
        `A new email has been sent, please use the verification code provided within ${utils.secondsToMinutes(OTP_LIFETIME_SECS)} minutes.`,
      );
  const returnLabel = isAdminInvite
    ? t("Return to verify email")
    : t("Return to home page");

  return (
    <>
      <Modal show={props.isOpen} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3">{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center mt-3">
            <Button variant="secondary" onClick={props.onHide}>
              {returnLabel}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default LinkEmailSent;
