import { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { createStructuredSelector } from "reselect";

import {
  GenericWorkflowStep,
  WorkflowCodeType,
} from "src/services/types/WorkflowTypes";
import {
  selectCurrentStep,
  selectCurrentStepErrors,
  selectCurrentStepIsLoading,
  selectWorkflowIsCompleted,
} from "src/store/Workflow/selector";
import { useInjection } from "src/services/ServiceProvider";
import {
  IWorkflowService,
} from "src/services/WorkflowService";
import { WORKFLOW_DISPLAY_ERRORS } from "src/constants/errors";
import PlaceholderComponent from "src/components/core/Placeholder/PlaceholderComponent";
import { Case, Fallback, When } from "src/components/core/PredicateRouter";
import WorkflowComponentRendererComponent from "./WorkflowComponentRenderer/WorkflowComponentRendererComponent";
import { WorkflowStepDetails } from "./WorkflowStepDetails";

type WorkflowStepsComponentStateProps = {
  currentStep: GenericWorkflowStep;
  isCurrentStepLoading?: boolean;
  isWorkflowCompleted: boolean;
  errors?: string[];
};

type WorkflowStepsComponentProps = {
  workflowCode: WorkflowCodeType | null;
  onExitWorkflow: () => Promise<any>;
  onSkipWorkflow: () => Promise<any>;
} & WorkflowStepsComponentStateProps;

function WorkflowStepsComponent({
  workflowCode,
  currentStep,
  isCurrentStepLoading = false,
  errors = [],
  isWorkflowCompleted,
  onExitWorkflow,
  onSkipWorkflow,
}: WorkflowStepsComponentProps) {
  const { t } = useTranslation();
  const workflowService = useInjection<IWorkflowService>("workflowService");

  // @ts-ignore
  const haveStepErrors = errors.length > 0;

  const loadNewComponent = async (workflowCode: WorkflowCodeType | null) => {
    const newComponent = await workflowService.getNextStep(workflowCode);
    return newComponent;
  };

  // Load the next step when needed.
  useEffect(() => {
    const isFirstLoad = workflowCode && !isCurrentStepLoading;

    if (isFirstLoad) {
      loadNewComponent(workflowCode);
    }
  }, [workflowCode, workflowService]);

  // Workflow has been completed.
  useEffect(() => {
    if (currentStep === null && !isCurrentStepLoading && workflowCode) {
      workflowService.completeWorkflow(workflowCode);
    }
  }, [workflowCode, currentStep, isCurrentStepLoading]);

  const handleComponentComplete = useCallback(async () => {
    const { id } = currentStep;
    if (!workflowCode || !id) {
      return;
    }

    const result = await workflowService.onComponentComplete(workflowCode, id);

    if (result !== null) {
      await loadNewComponent(workflowCode);
    }
  }, [workflowCode, currentStep]);

  if (haveStepErrors) {
    return (
      <div>
        <div>{t(WORKFLOW_DISPLAY_ERRORS.GENERIC)}</div>
        <ul>{errors?.map((error, index) => <li key={index}>{error}</li>)}</ul>
      </div>
    );
  }

  if (!workflowCode) {
    return <div>{t(WORKFLOW_DISPLAY_ERRORS.INCORRECT_WORKFLOW_CODE)}</div>;
  }

  const { details, component, name } = currentStep || {};

  return (
    <div className="row">
      <aside className="col-12 col-lg-3 col-xl-2 pt-4 pr-lg-0 pl-lg-0">
        <Case>
          <When predicate={!!isCurrentStepLoading}>
            <h2 className="mb-3 h3">
              <PlaceholderComponent />
            </h2>
            <PlaceholderComponent />
            <PlaceholderComponent />
          </When>

          <When predicate={details !== null && details !== undefined}>
            <WorkflowStepDetails
              name={name}
              onPauseClick={onSkipWorkflow}
              {...details}
            />
          </When>

          {/* No Details */}
          <Fallback />
        </Case>
      </aside>

      <div className="col-12 col-lg-9 col-xl-8 center-section">
        <WorkflowComponentRendererComponent
          isCurrentStepLoading={isCurrentStepLoading}
          isWorkflowCompleted={isWorkflowCompleted}
          component={component}
          currentStep={currentStep}
          onComponentComplete={handleComponentComplete}
          onSkipWorkflow={onSkipWorkflow}
          onExitWorkflow={onExitWorkflow}
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = createStructuredSelector<
  any,
  WorkflowStepsComponentStateProps
>({
  currentStep: selectCurrentStep,
  isCurrentStepLoading: selectCurrentStepIsLoading,
  isWorkflowCompleted: selectWorkflowIsCompleted,
  errors: selectCurrentStepErrors,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkflowStepsComponent);
