export const TRUNCATE = {
  QUICKPOLL_SURVEY_TITLE: 32,
  SUGGESTED_SURVEY_TITLE: 35,

  ACTIONCARD_SUBTITLE: 60,
  ACTIONCARD_DESCRIPTION: 80,

  ACTIONCARD_CODE: 12,

  SURVEYCARD_TITLE: 32,
  SURVEY_DESCRIPTION: 155,

  NAME: 24,
};
