import React from "react";
import { withTranslation } from "react-i18next";
import InputComponent from "src/components/core/Input/InputComponent";
import ConsentSignature from "src/components/consent/consent_signature";
import ConsentSubcompRenderer from "src/components/consent/consent_subcomp_renderer";
import { utils } from "src/utils/utils_general";
import { LOCAL_STORAGE } from "src/constants/localStorage";
import { CONSENT_COMPONENT } from "src/constants/consentComponents";
import signatureTypes from "./constants";
import { isPotentialXSS } from "src/utils/utils_validation";
import { withService } from "src/services/ServiceProvider";
import WorkflowNextButton from "src/components/workflow/WorkflowNextButton";

const validateNamePart = (namePart) => {
  if (!namePart) {
    return [false, "This field is required"];
  }

  if (isPotentialXSS(namePart)) {
    return [false, "error-potential-xss"];
  }

  return [true];
};

class ConsentCompEsign extends React.Component {
  constructor() {
    super();

    this.state = {
      signature: null,
      videoComplete: false,
      radioAnswers: [],
      loading: false,
      firstName: "",
      lastName: "",
      validationErrors: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { userService, signatureType } = props;

    const userData = userService.getCurrentUser();

    let firstName = "";
    let lastName = "";
    if (signatureType === signatureTypes.primary) {
      firstName = userData.first_name;
      lastName = userData.last_name;
    }

    if (state.firstName || state.lastName) {
      return null;
    }
    return {
      ...state,
      firstName,
      lastName,
    };
  }

  onVideoComplete() {
    this.setState({ videoComplete: true });
  }

  onRadioAnswered(qnAndAnswer) {
    const { radioAnswers } = this.state;
    // remove prev answer if qn has been answered already
    const index_exist = radioAnswers.findIndex(
      (r) => r.qn_code === qnAndAnswer.qn_code,
    );
    if (index_exist >= 0) {
      radioAnswers.splice(index_exist, 1);
    }
    radioAnswers.push(qnAndAnswer);

    this.setState({ radioAnswers });
  }

  onSignatureComplete(signature) {
    this.setState({ signature });
  }

  validateName() {
    const { firstName, lastName } = this.state;

    const { signatureCount, t } = this.props;

    if (!signatureCount || signatureCount <= 1) {
      return true;
    }

    const [firstNameIsValid, firstNameError] = validateNamePart(firstName);
    const [lastNameIsValid, lastNameError] = validateNamePart(lastName);

    const isValid = firstNameIsValid && lastNameIsValid;

    const validationErrors = isValid
      ? null
      : {
          ...(!firstNameIsValid && { firstName: t(firstNameError) }),
          ...(!lastNameIsValid && { lastName: t(lastNameError) }),
        };

    this.setState({ validationErrors });
    return isValid;
  }

  onSubmit() {
    const { firstName, lastName, radioAnswers, signature } = this.state;

    const isValid = this.validateName();
    if (!isValid) {
      return null;
    }
    const { onComponentComplete, index } = this.props;

    utils.set_local_storage(LOCAL_STORAGE.SIG, this.state.signature);
    this.setState({ loading: true });
    onComponentComplete({
      sig: signature,
      index,
      radio: radioAnswers,
      firstName,
      lastName,
    });
  }

  onNameChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  renderNameFields() {
    const { signatureType, t } = this.props;
    const { firstName, lastName, validationErrors } = this.state;

    const isDisabled = signatureType === signatureTypes.primary;

    return (
      <>
        <InputComponent
          name="firstName"
          label={t("First Name")}
          placeholder={t("Enter Your First Name")}
          onChange={this.onNameChange}
          value={firstName}
          required
          disabled={Boolean(isDisabled && firstName)}
          validationError={validationErrors?.firstName}
        />
        <InputComponent
          name="lastName"
          label={t("Last Name")}
          placeholder={t("Enter Your Last Name")}
          onChange={this.onNameChange}
          value={lastName}
          required
          disabled={Boolean(isDisabled && lastName)}
          validationError={validationErrors?.lastName}
        />
      </>
    );
  }

  renderSubHeader() {
    const { signatureType, t } = this.props;
    if (signatureType !== signatureTypes.primary) {
      return null;
    }

    return <h3>{t("Enter your primary account name")}</h3>;
  }

  render() {
    const { component, t } = this.props;

    const { title, body } = component;

    const { loading, signature, videoComplete, radioAnswers } = this.state;

    const signatureComplete = !!signature;

    const hasVideo = body.filter((c) => c.type === CONSENT_COMPONENT.VIDEO)[0];
    const noVidOrComplete = hasVideo ? !!videoComplete : true;

    const numOfQnRadio = body.filter(
      (c) => c.type === CONSENT_COMPONENT.QN_RADIO,
    ).length;
    const noQnRadioOrComplete =
      numOfQnRadio > 0 ? radioAnswers.length === numOfQnRadio : true;

    const enabled =
      signatureComplete && noVidOrComplete && noQnRadioOrComplete && !loading;

    return (
      <div className="consent-component consent-component-esign">
        <h2 className="h5 mb-3">{t(title)}</h2>
        <div className="consent-subcomponents mb-4">
          {body.map((c, key) => (
            <ConsentSubcompRenderer
              key={key}
              type={c.type}
              component={c}
              user_response={this.props.user_response}
              onVidComplete={() => this.onVideoComplete()}
              onRadioAnswered={(value) => this.onRadioAnswered(value)}
            />
          ))}
        </div>

        {this.renderSubHeader()}
        {this.renderNameFields()}

        <div className="unauth-body-footer">
          <ConsentSignature
            onSignatureDelete={() => {
              this.setState({ signature: null });
            }}
            onSignatureComplete={(sig) => this.onSignatureComplete(sig)}
          />
          <div className="text-center mt-3">
            <WorkflowNextButton
              disabled={!enabled}
              inline
              size="sm"
              type="submit"
              onClick={() => this.onSubmit()}
            >
              {t("I understand and agree to participate in this study")}
            </WorkflowNextButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(["fields", "texts"])(
  withService(["userService"])(ConsentCompEsign),
);
