import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { login_get } from "../../actions/loginAction";
import AppPolicies from "./app_policies";
import PAGES from "src/constants/pages";
import { UNEXPECTED_ERROR } from "../../constants/errors";

class AppPoliciesPage extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: null,
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {};
  }

  onFormComplete() {
    return this.props
      .login_get()
      .then((response) => {
        this.setState({ errors: null });
        this.props.history.push(PAGES.DASHBOARD);
      })
      .catch((error) => {
        if (error.response) {
          return this.props.history.push(PAGES.LOGIN);
        }
        return this.setState({ errors: { system: UNEXPECTED_ERROR } });
      });
  }

  render() {
    return (
      <section className="signup-component policies policies-auth">
        <div className="signup-body-wrapper">
          <AppPolicies
            onComplete={() => this.onFormComplete()}
            errors={this.state.errors}
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
});

export default withRouter(
  connect(mapStateToProps, {
    login_get,
  })(AppPoliciesPage),
);
