import React from "react";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import { utils } from "../../utils/utils_general";
import { TRUNCATE } from "../../constants/truncateString";
import { CONFIG } from "../../constants/config";
import { DEEPLINKS } from "../../constants/deeplinks";

const SurveyConsentText = (props) => {
  const { t } = useTranslation();
  const { survey } = props;
  const description = survey.description
    ? utils.remove_html(survey.description)
    : null;
  if (!survey) {
    return null;
  }
  const partner_name = CONFIG.PARTNER_NAME;
  const deeplinks_consent = DEEPLINKS.CONSENT;
  return (
    <>
      <div className="survey-consent-wrapper">
        {description ? (
          <p className="mb-3">
            {" "}
            {utils.truncate_string(
              String(description),
              TRUNCATE.SURVEY_DESCRIPTION,
            )}{" "}
          </p>
        ) : null}
        <p className="mb-3">
          <Trans i18nKey="content:survey_consent_text">
            All surveys are used for research purposes governed by the
            <a
              href={`/profile?${deeplinks_consent}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              informed consent
            </a>
            you signed. Your survey response data is de-identified and shared
            with Regeneron, as a collaboration with {{ partner_name }}. Your
            answers can expand our collective understanding of human genetics to
            potentially discover new medicines that can improve lives
          </Trans>
        </p>
      </div>

      <Link
        to={`/surveys/start/${survey.survey_id}`}
        className="btn primary-invert btn-block"
      >
        {t("Start Survey")}
      </Link>
    </>
  );
};

export default withTranslation("content")(SurveyConsentText);
