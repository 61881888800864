import React from "react";
import { Form } from "react-bootstrap";

type ErrorTextProps = {
  errorMsg?: string | string[] | null;
};

function ErrorText({ errorMsg }: ErrorTextProps) {
  if (!errorMsg) {
    return null;
  }

  const msgToRender = () => {
    if (!Array.isArray(errorMsg)) {
      return errorMsg;
    }
    return (
      <ul>
        {errorMsg.map((item: string) => (
          <li>{item}</li>
        ))}
      </ul>
    );
  };

  return (
    <Form.Text className="text-danger form-error" aria-live="polite">
      {msgToRender()}
    </Form.Text>
  );
}

export default ErrorText;
