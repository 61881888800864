import { API_URL } from "../constants/api";
import { utils_api } from "../utils/utils_api";

export const api_verification = {
  verify(success, fail, data, language) {
    const url = language
      ? `${API_URL.VERIFY_POST}?language=${language}`
      : API_URL.VERIFY_POST;
    return utils_api.auth_post(url, data, success, fail);
  },
  submit_verification_question(success, fail, data) {
    return utils_api.auth_post(API_URL.KBA_POST, data, success, fail);
  },
};
