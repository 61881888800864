import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LINKS } from "../../constants/links";
import { withTranslation } from "react-i18next";

const GlobalError = withTranslation()((props) => {
  const { t } = props;
  return (
    <Modal
      show={Boolean(props.show)}
      onHide={props.onHide}
      backdrop={false}
      aria-labelledby="contained-modal-title-vcenter"
      className="global-error-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("Oops!")}
          {/* {console.log('props',props)} */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t(
          "Something went wrong, try again later. If the problem persists, please",
        )}{" "}
        <Link to={LINKS.SUPPORT} target="_blank" rel="noopener noreferrer">
          {t("contact support")}
        </Link>
        .
      </Modal.Body>
    </Modal>
  );
});

export default GlobalError;
