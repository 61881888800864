export const LOCAL_STORAGE = {
  SIGNUP_DESTINATION: "rgc_dtp.signup_destination",
  USER: "rgc_dtp.user",
  EXPIRED_TOKEN: "rgc_dtp.expiredtoken",
  SURVEY: "rgc_dtp.survey",
  SURVEY_IFRAME: "rgc_dtp.surveyiframe",
  INTENDED_DESTINATION: "rgc_dtp.intendeddestination",
  FEATURE_FLAG: "rgc_dtp.feature",
  FIRST_TIME_REG: "rgc_dtp.firsttimereg",
  REGISTRATION_FLOW: "rgc_dtp.regflow",
  WORKFLOW_START: "rgc_dtp.workflow_start",
  WORKFLOW_STEPS_GA4: "rgc_dtp.workflow_steps_ga4",
  REGISTRATION_TYPE: "rgc_dtp.regtype",
  EMAIL_CONFIRMED: "rgc_dtp.emailconfirmed",
  SELECTED_LANGUAGE: "rgc_dtp.lang",
  WORKFLOW: "rgc_dtp.workflow",
  // initial otp token sent, so it doesnt send again on refresh
  TOKEN_SENT: "initialsent",
  OTP_ID: "otpid",
  MAX_REQUEST: "maxreq",
  SIG: "submittingsig",
  EMR_CONNECT_PREFIX: "rgc_dtp.emr_status",
  I18N_CHECK: "i18n-check",
  SKIP_VERIFY: "skip_verify",
  REFERRAL_BANNER_CLOSED: "referral_modal_closed",
  ANCESTRY_AGREEMENT_LATER: "ancestry_agreement_later",
  ANCESTRY_GEOMETRY_L1: "rgc_dtp.ancestry_geometry_l1",
};
