/**
 * @file components.scroll-to-top.js
 *
 * make react-router scroll to top on every page transition
 *
 */

import { useEffect } from "react";
import { useLocation } from "react-router";

export default function ScrollToTop({ children }) {
  const location = useLocation();
  useEffect(() => {
    document.getElementById("root").scrollIntoView();
  }, [location]);
  return children;
}
