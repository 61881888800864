import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ConfirmEmailSuccess from "./confirm_email_success";
import ConfirmEmailError from "./confirm_email_error";
import {
  login_post,
  login_skip_emailverif_post,
} from "../../../actions/loginAction";
import {
  RESPONSE_CODE,
  SIGNUP_OTP_ERROR_DISPLAY,
} from "../../../constants/errors";
import Spinner from "../../global/spinner";
import PAGES from "src/constants/pages";
import { withTranslation } from "react-i18next";

class ConfirmEmailAuto extends React.Component {
  constructor() {
    super();
    this.state = {
      user: null,
      errors: {},
      confirmed: false,
      alreadyConfirmed: false,
      mounted: false,
    };
  }

  componentDidMount() {
    this.handleSubmit();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {};
  }

  handleSubmit() {
    const { otp_id } = this.props;
    const { token } = this.props;
    if (otp_id && token) {
      this.props
        .login_skip_emailverif_post(otp_id, token, true)
        .then((resp) => {
          this.login(resp.token, () => this.setState({ confirmed: true }));
        })
        .catch((error) => {
          const e =
            error && error.response
              ? error.response.data.msg
              : SIGNUP_OTP_ERROR_DISPLAY.GENERIC;
          if (
            error.response &&
            error.response.status === RESPONSE_CODE["412_token_expired"]
          ) {
            this.setState({ errors: { system: e } });
            const reg_token = error.response.data.msg.token;
            this.props.history.push(
              `${PAGES.SIGNUP_LINK_EXPIRED}?token=${reg_token}`,
            );
          } else if (
            error.response &&
            error.response.status ===
              RESPONSE_CODE["419_email_already_confirmed"] &&
            !this.state.alreadyConfirmed
          ) {
            const reg_token = error.response.data.msg.token;
            this.login(reg_token, () =>
              this.setState({ errors: {}, alreadyConfirmed: true }),
            );
          } else {
            this.setState({ errors: { system: e } });
          }
        });
    }
  }

  login(token, onSuccess) {
    this.props
      .login_post({ token }, true)
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        const e =
          error && error.response && error.response.data.msg
            ? error.response.data.msg
            : SIGNUP_OTP_ERROR_DISPLAY.GENERIC;
        if (
          error.response &&
          (error.response.status === RESPONSE_CODE["413_already_active"] ||
            error.response.status === RESPONSE_CODE["412_token_expired"])
        ) {
          this.props.history.push(PAGES.LOGIN);
        }
        this.setState({ errors: { system: e || error } });
      });
  }

  render() {
    if (this.state.confirmed || this.state.alreadyConfirmed) {
      return (
        <ConfirmEmailSuccess
          errors={this.state.errors}
          onComplete={this.props.onComplete}
          alreadyConfirmed={this.state.alreadyConfirmed}
        />
      );
    }
    if (this.state.errors.system) {
      return <ConfirmEmailError error={this.state.errors.system} />;
    }
    return (
      <>
        <h1>{this.props.t("Verify Email")}</h1>
        <div
          className="h2 mt-5 mb-3"
          style={{ fontSize: "18px", fontWeight: "normal", lineHeight: "24px" }}
        >
          {this.props.t("Verifying your email...")}
          <Spinner error="verifying email" />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, { login_post, login_skip_emailverif_post })(
    withTranslation()(ConfirmEmailAuto),
  ),
);
