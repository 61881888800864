import React from "react";
import Emr from "src/components/workflow/emr";
import { WorkflowStepProps } from "src/services/types/WorkflowTypes";

function EmrWrapper({
  onComponentComplete,
  onSkipWorkflow,
  onExitWorkflow,
}: WorkflowStepProps) {
  return (
    <Emr
      onComplete={onComponentComplete}
      isWorkflowNext
      onWorkflowExit={onExitWorkflow}
      onWorkflowSkip={onSkipWorkflow}
    />
  );
}

export { EmrWrapper };
