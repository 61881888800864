import classNames from "classnames";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as QuickPollsBg1 } from "../../assets/images/quick-poll-bg1.svg";
import { ReactComponent as QuickPollsBg2 } from "../../assets/images/quick-poll-bg2.svg";
import styles from "./quick_polls_done_banner.module.scss";

export function QuickPollsDoneBanner({ onRequestClose }) {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        styles.banner,
        "text-white col-12 p-4 pt-5 p-md-5 position-relative rounded overflow-hidden",
      )}
    >
      <div className={styles.background1}>
        <QuickPollsBg1 />
      </div>
      <div className={styles.background2}>
        <QuickPollsBg2 />
      </div>
      <div className="position-relative">
        <p className={classNames(styles.title, "text-white pt-3")}>
          {t("All_Quick_Polls_Completed_Title")}
        </p>
        <p className={classNames(styles.description, "text-white pt-3")}>
          {t("All_Quick_Polls_Completed_Message")}
        </p>
        <Button
          variant="primary"
          className={styles.completedButton}
          onClick={() => {
            onRequestClose();
          }}
        >
          {t("All_Quick_Polls_Completed_Button")}
        </Button>
      </div>
    </div>
  );
}
