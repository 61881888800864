import React from "react";
import { Form, Button } from "react-bootstrap";
import { utils } from "../../../utils/utils_general";
import { withTranslation } from "react-i18next";

export const ConfirmEmailSuccess = (props) => (
  <>
    <h1>
      {" "}
      {props.alreadyConfirmed
        ? props.t("Already confirmed")
        : props.t("Success!")}
    </h1>
    <div
      className="h2 mt-5 mb-3"
      style={{ fontSize: "18px", fontWeight: "normal", lineHeight: "24px" }}
    >
      {props.t("Your email address {{email}} {{confirmed}}", {
        email: props.email,
        confirmed: props.alreadyConfirmed
          ? props.t("has already been verified")
          : props.t("has been verified"),
      })}
    </div>

    <div className="unauth-body-footer">
      <Button
        variant={utils.is_obj_empty(props.errors) ? "primary" : "disable"}
        type="submit"
        block
        onClick={(e) =>
          utils.is_obj_empty(props.errors)
            ? props.onComplete()
            : e.preventDefault()
        }
      >
        {props.t("Continue")}
      </Button>
      <Form.Text className="text-center text-danger form-error">
        {props.errors.system || <br />}
      </Form.Text>
    </div>
  </>
);

export default withTranslation()(ConfirmEmailSuccess);
