import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import PAGES from "src/constants/pages";
import { LOCAL_STORAGE } from "src/constants/localStorage";
import { SIGNUP_ERROR_DISPLAY, RESPONSE_CODE } from "src/constants/errors";
import { REGISTRATION_USER_STATUS } from "src/constants/registration";
import { WORKFLOW, WORKFLOW_VALUE } from "src/constants/workflow";
import { TOKEN_TYPE } from "src/constants/tokenType";
import { ATTRIBUTES } from "src/constants/attributes";

import { login_post } from "src/actions/loginAction";
import {
  registration_flow_get,
  update_registration_flow,
  reset_registration_flow,
  set_registration_type,
} from "src/actions/registrationFlowAction";
import {
  get_user_attributes_get,
  post_workflow_complete_post,
} from "src/actions/userAction";

import { utils } from "src/utils/utils_general";
import { utils_signup } from "src/utils/utils_signup";
import { utils_registration_flow } from "src/utils/utils_registration_flow";

import ConfirmEmailUrl from "src/components/signup/confirm-email/confirm_email_url";
import ConfirmEmailAuto from "src/components/signup/confirm-email/confirm_email_auto";
import ConfirmEmailToken from "src/components/signup/confirm-email/confirm_email_token";
import ConfirmEmailError from "src/components/signup/confirm-email/confirm_email_error";
import ConfirmEmailSuccess from "src/components/signup/confirm-email/confirm_email_success";

import SignupProgress from "src/components/signup/signup_progress";
import { withService } from "src/services/ServiceProvider";
import BlankComponent from "src/components/core/Blank/BlankComponent";

const CURRENT_REG_STEP = WORKFLOW.CONFIRM_EMAIL;

const CONFIRM_EMAIL_PATHS = {
  AUTO: "auto",
  VTOKEN: "vtoken",
  URL: "url",
  ERROR: "error",
  SUCCESS: "success",
};

class ConfirmEmail extends React.Component {
  constructor({ t }) {
    super();
    this.t = t;
    this.state = {
      errors: {},
      reg_flow: null,
      user: null,
      email: null,
      confirm_email_path: null,
      token: null,
      otp_id: null,
      otp_token: null,
      loaded: false,
      isProgressHidden: true,
    };
  }

  // TODO: revisit this component and remove redundant checks inside DidMount
  componentDidMount() {
    // on local it sometimes gets into a endless loop...
    if (this.state.loaded) {
      return;
    }
    this.setState({ loaded: true });

    const { userService } = this.props;

    const decoded_user = userService.getCurrentUser();

    const user = this.props.user || decoded_user;

    const token = utils.get_url_param(this.props.location.search, "token");
    const otp_id = utils.get_url_param(this.props.location.search, "otp_id");

    const isSelfVToken = user !== null && user !== undefined && !token;
    const isSelfEmail = token && token.length === 6 && otp_id;
    const isAdminEmail = token && token.length > 6;

    if (isAdminEmail) {
      // do nothing
    } else if (user && !utils.is_obj_empty(user)) {
      this.prepRegFlow(user.token_type);
    } else {
      this.prepRegFlow(TOKEN_TYPE.REGISTRATION_SELF);
    }

    if (isAdminEmail) {
      this.props
        .login_post({ token }, true)
        .then((response) => {
          if (!response.token_type.includes("registration")) {
            this.setState({
              errors: { system: this.t(SIGNUP_ERROR_DISPLAY.TOKEN_INVALID) },
            });
          } else if (new Date(response.expiry) < new Date()) {
            this.handleExpiredToken(token);
          } else {
            // we got the user, prep the flow
            this.prepRegFlow(TOKEN_TYPE.REGISTRATION_ADMIN);
            this.setState({ user: response, token });
            this.checkUserRegistrationToken();
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === RESPONSE_CODE["412_token_expired"]
          ) {
            this.handleExpiredToken(token);
          } else if (
            error.response &&
            error.response.status === RESPONSE_CODE["413_already_active"]
          ) {
            this.props.history.push(PAGES.LOGIN);
          } else {
            this.setState({
              errors: { system: this.t(SIGNUP_ERROR_DISPLAY.TOKEN_INVALID) },
              confirm_email_path: CONFIRM_EMAIL_PATHS.ERROR,
            });
          }
        });
    } else if (isSelfEmail) {
      this.setState({
        confirm_email_path: CONFIRM_EMAIL_PATHS.AUTO,
        otp_id,
        otp_token: token,
      });
    } else if (isSelfVToken) {
      this.checkUserRegistrationToken();
    } else {
      this.setState({
        errors: { system: this.t(SIGNUP_ERROR_DISPLAY.TOKEN_INVALID) },
        confirm_email_path: CONFIRM_EMAIL_PATHS.ERROR,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      utils.is_obj_empty(prevProps.loginUser) &&
      !utils.is_obj_empty(this.props.loginUser)
    ) {
      const flow =
        this.props.registrationFlow ||
        utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);
      this.setState({ user: this.props.loginUser, reg_flow: flow });
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {};
  }

  handleExpiredToken(token) {
    // this method is only used in admin invite
    // invite parameter is for the link expired component to differentiate from self registration
    this.props.history.push(
      `${PAGES.SIGNUP_LINK_EXPIRED}?token=${token}&invite=1`,
    );
  }

  checkUserRegistrationToken() {
    const { userService } = this.props;

    const user = userService.getCurrentUser();

    if (user && new Date(user.expiry) > new Date()) {
      this.checkIfAlreadyConfirmed(user);
    } else {
      this.props.history.push(PAGES.SIGNUP_LINK_EXPIRED);
    }
  }

  checkIfAlreadyConfirmed(user) {
    if (user) {
      this.props
        .get_user_attributes_get(true)
        .then((res) => {
          this.findConfirmEmailPath(user, res);
        })
        .catch((e) => {
          this.findConfirmEmailPath(user, null);
        });
    } else {
      this.findConfirmEmailPath(null, null);
    }
  }

  findConfirmEmailPath(user, attr) {
    const confirm_email_path = this.mapPath(user, attr);
    this.setState({ email: user.email, confirm_email_path });
    // this.prepRegFlow(user.token_type)
  }

  mapPath(user, attr) {
    if (
      user.token_type === TOKEN_TYPE.REGISTRATION_ADMIN &&
      !attr.password_set
    ) {
      return CONFIRM_EMAIL_PATHS.URL;
    }
    if (attr && attr[ATTRIBUTES.EMAIL_CONFIRMED]) {
      const flow = this.state.reg_flow || this.props.registrationFlow;

      if (
        user.token_type === TOKEN_TYPE.REGISTRATION_ADMIN &&
        attr.password_set
      ) {
        // Send to dashboard if user token is admin invite and has email confirmed
        // and password set. Let the dashboard redirect the user to the
        // appropriate place.
        this.props.history.push(PAGES.DASHBOARD);
        return;
      } else {
        return this.goToRegisteredNextStep(flow);
      }
    }
    const reg_channel = attr.registration_channel;
    if (!user) {
      return CONFIRM_EMAIL_PATHS.ERROR;
    }
    if (
      user.token_type === TOKEN_TYPE.REGISTRATION_ADMIN ||
      reg_channel === TOKEN_TYPE.REGISTRATION_ADMIN ||
      user.token_type === TOKEN_TYPE.REGISTRATION_SUBACCOUNT ||
      reg_channel === TOKEN_TYPE.REGISTRATION_SUBACCOUNT
    ) {
      return CONFIRM_EMAIL_PATHS.URL;
    }
    if (
      user.token_type === TOKEN_TYPE.REGISTRATION_SELF ||
      reg_channel === TOKEN_TYPE.REGISTRATION_SELF
    ) {
      return CONFIRM_EMAIL_PATHS.VTOKEN;
    }
    return CONFIRM_EMAIL_PATHS.ERROR;
  }

  prepRegFlow(token_type) {
    let type =
      token_type || utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_TYPE);
    let isFirstStep;
    if (!type) {
      type = TOKEN_TYPE.REGISTRATION_ADMIN;
      isFirstStep = true;
      utils.set_local_storage(
        LOCAL_STORAGE.REGISTRATION_TYPE,
        TOKEN_TYPE.REGISTRATION_ADMIN,
      );
    } else if (
      type === TOKEN_TYPE.REGISTRATION_ADMIN ||
      type === TOKEN_TYPE.REGISTRATION_SSO
    ) {
      isFirstStep = true;
      this.props.set_registration_type(token_type);
    }

    // if this is the first page, force call a restart!
    const reg_flow = isFirstStep
      ? null
      : this.props.registrationFlow ||
        utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);
    if (!reg_flow) {
      const onRegFlowSuccess = (flow) => {
        this.setSteps(flow);
        this.setState({ isProgressHidden: false });
      };
      this.getRegFlow(onRegFlowSuccess);
    } else {
      this.setSteps(reg_flow);
      this.setState({ isProgressHidden: false });
    }
  }

  getRegFlow(onRegFlowSuccess) {
    this.props.registration_flow_get(true).then((res) => {
      onRegFlowSuccess(res);
    });
  }

  setSteps(flow) {
    const correct_flow = flow.map((c) => {
      if (c.status === REGISTRATION_USER_STATUS.NON_REGISTER) {
        c.value = WORKFLOW_VALUE.COMPLETE;
      }
      return c;
    });
    this.props.update_registration_flow(flow);
    this.setState({
      reg_flow: correct_flow,
    });
  }

  onFormComplete() {
    utils_registration_flow.clearRelatedStorage();
    this.goToRegisteredNextStep(this.state.reg_flow);
  }

  goToRegisteredNextStep(reg_flow) {
    const flow = reg_flow || this.state.reg_flow || this.props.registrationFlow;
    const currentStep = utils_registration_flow.current_registration_component(
      flow,
      CURRENT_REG_STEP,
    );
    if (flow && currentStep) {
      this.props.get_user_attributes_get().then((res) => {
        const attr_errors = utils_registration_flow.checkAttrCompleted(
          currentStep.exit_attribute,
          res,
        );
        if (attr_errors.length > 0) {
          this.componentDidMount();
        } else {
          utils_signup.goToNextRegistrationStep(
            flow,
            currentStep,
            res,
            this.props.update_registration_flow,
            this.props.history.push,
            this.props.post_workflow_complete_post,
          );
        }
      });
    }
  }

  renderConfirmEmail() {
    // three states
    // 1. token - for admin invite
    // 2. nothing - input code
    // 3. token - auto  - for self reg
    // 4. failed token check yo email

    switch (this.state.confirm_email_path) {
      case CONFIRM_EMAIL_PATHS.URL:
        return (
          <ConfirmEmailUrl
            onComplete={() => this.onFormComplete()}
            email={this.state.email}
          />
        );
      case CONFIRM_EMAIL_PATHS.VTOKEN:
        return (
          <ConfirmEmailToken
            onComplete={() => this.onFormComplete()}
            email={this.state.email}
            user={this.state.user}
          />
        );
      case CONFIRM_EMAIL_PATHS.AUTO:
        return (
          <ConfirmEmailAuto
            onComplete={() => this.onFormComplete()}
            email={this.state.email}
            otp_id={this.state.otp_id}
            token={this.state.otp_token}
          />
        );
      case CONFIRM_EMAIL_PATHS.ERROR:
        return (
          <ConfirmEmailError
            onComplete={() => console.log("Error onComplete")}
          />
        );
      case CONFIRM_EMAIL_PATHS.SUCCESS:
        return (
          <ConfirmEmailSuccess
            onComplete={() => this.onFormComplete()}
            email={this.state.email}
            errors={{}}
          />
        );
      default:
        return <BlankComponent />;
    }
  }

  render() {
    const currentStep = utils_registration_flow.current_registration_component(
      this.state.reg_flow,
      CURRENT_REG_STEP,
    );
    const isAdminReg =
      this.state.confirm_email_path === CONFIRM_EMAIL_PATHS.URL;
    return (
      <section
        className={`signup-component confirm-email${isAdminReg ? " confirm-email-admin" : ""}`}
      >
        {!this.state.isProgressHidden &&
        this.state.confirm_email_path !== CONFIRM_EMAIL_PATHS.ERROR ? (
          <SignupProgress
            currentStep={currentStep}
            regFlow={this.state.reg_flow}
          />
        ) : null}
        <div className="signup-body-wrapper">{this.renderConfirmEmail()}</div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, {
    login_post,
    registration_flow_get,
    update_registration_flow,
    get_user_attributes_get,
    reset_registration_flow,
    set_registration_type,
    post_workflow_complete_post,
  })(withTranslation()(withService(["userService"])(ConfirmEmail))),
);
